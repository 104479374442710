import React from 'react';
import {ComposedChart, ResponsiveContainer, XAxis,
  YAxis, Tooltip, CartesianGrid, Line} from 'recharts';
import Charts from './Charts';

class DexLineChart extends React.Component {
  constructor(props) {
    super(props);
    let dataKeyProp;
    if (props.dataKey) {
      dataKeyProp = props.dataKey;
    } else {
      dataKeyProp = 'price';
    }
    this.state = {
      datakey: dataKeyProp
    }
  }

  render() {
    return (
      <ResponsiveContainer height={250} >
        <ComposedChart data={this.props.data}>
          <XAxis dataKey="date" tick={Charts.tickStyle} tickFormatter={Charts.shortDateFormatter} domain={[0, dataMax => (dataMax * 1.5)]}/>
          <YAxis tick={Charts.tickStyle} tickFormatter={Charts.numberFormatter} domain={[0, dataMax => (dataMax * 1.5)]}/>
          <Tooltip labelFormatter={Charts.longDateFormatter} formatter={Charts.numberFormatter} labelClassName="text-gray-600" />
          <CartesianGrid stroke={Charts.gridStrikeColor} />
          <Line type="monotone" dataKey={this.state.datakey} stroke={Charts.lineColor} strokeWidth={2} name={this.props.name} dot={false}/>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default DexLineChart;
