import React from 'react';
import DexLineChart from './DexLineChart';
import GenericSinceChart from './GenericSinceChart';

class PriceChart extends GenericSinceChart {

  getData() {
    this.api.priceData(this.props.chainId, this.props.tokenAddress, this.state.since.toISOString()).then((data) => {
      this.setState({
        data: data
      });
    });
  }

  graphName() {
    return 'Price';
  }

  chartComponent() {
    return(
      <DexLineChart data={this.state.data} name='Price'/>
    );
  }
}

export default PriceChart;
