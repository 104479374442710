class Deadline {
  static seconds(seconds) {
    return new Deadline(seconds);
  }

  static minutes(minutes) {
    return new Deadline(minutes * 60);
  }

  static default() {
    return new Deadline(5 * 60);
  }

  constructor(delta) {
    this.delta = delta;
  }
}

export default Deadline;

window.Deadline = Deadline;
