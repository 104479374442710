import Contracts from "../Blockchain/Contracts";

class Stake {
  constructor(data, wallet) {
    this.data = data;
    this.wallet = wallet;
  }

  async init() {
    return this.loadPool();
  }

  async loadPool() {
    return this.wallet.pools.find_or_add(this.address)
      .then((pool) => {
        this.pool = pool;
        return this;
      });
  }

  get index() {
    return this.data.index;
  }

  get amount() {
    return this.wallet.web3.utils.fromWei(this.data.amount);
  }

  get address() {
    return this.data.lpAddress;
  }

  get isActive() {
    return this.data.isActive;
  }

  get lastRewardClaimDate() {
    return parseInt(this.data.lastRewardClaimDate, 10);
  }

  get endDate() {
    return parseInt(this.data.endDate, 10);
  }

  get startDate() {
    return parseInt(this.data.startDate, 10);
  }

  get totalClaimed() {
    return this.wallet.web3.utils.fromWei(this.data.totalClaimed);
  }

  get token0Balance() {
    if (!this.isActive) return 0;
    return this.pool.reserve0 * this.amount / this.pool.supply;
  }

  get token1Balance() {
    if (!this.isActive) return 0;
    return this.pool.reserve1 * this.amount / this.pool.supply;
  }

  async computeReward() {
    let contract = Contracts.staking;
    let ts = this.isActive ? Math.floor(Date.now() / 1000) : this.endDate;
    console.log(this);
    console.log(ts - this.lastRewardClaimDate);
    let reward = await contract.methods.rewardsForLP(this.address).call();
    return reward / 10000.0 * this.amount * Math.floor((ts - this.lastRewardClaimDate) / (365 * 24 * 3600));
  }

  async collectReward() {
    return this.prepareCollectReward().execute();
  }

  prepareCollectReward() {
    let contract = Contracts.staking;
    return contract.methods
      .claimRewards(this.index)
      .prepare({ from: this.wallet.address });
  }

  async unstake() {
    return this.prepareUnstake().execute();
  }

  prepareUnstake() {
    let contract = Contracts.staking;
    return contract.methods
      .unStake(this.index)
      .prepare({ from: this.wallet.address });
  }
}

export default Stake;
