import * as Sentry from "@sentry/react";

const errors = {
  "Amount limit reached": "Amount limit reached", // Bootstrap: maximum staking amount reached
  "Amount must be greater than 0": "Amount must be greater than 0", // If you try to make a transaction from a zero amount (present in multiple contracts)
  "APPROVE_FAILED": "APPROVE_FAILED", // no used for now
  "can't add liquidity yet": "can't add liquidity yet", // not shown in UI
  "ds-math-add-overflow": "Invalid input amounts", // happens when operating with negative numbers
  "ds-math-mul-overflow": "Invalid input amounts", // happens when operating with negative numbers
  "ds-math-sub-underflow": "Invalid input amounts", // happens when operating with negative numbers
  "ETH_TRANSFER_FAILED": "Failed to transfer native token", // called when transfering ETH/MATIC/etc and fails
  "EXCESSIVE_INPUT_AMOUNT": "This transaction will not succeed either due to price movement or fee on transfer.", //
  "EXPIRED": "Transaction expired as it took too long to complete. Try increasing the allowed gas", //
  "FORBIDDEN": "FORBIDDEN", // not shown in UI
  "IDENTICAL_ADDRESSES": "You're are trying a transaction between the same token", //
  "INSUFFICIENT_A_AMOUNT": "Excessive first amount", //
  "INSUFFICIENT_AMOUNT": "INSUFFICIENT_AMOUNT", //
  "INSUFFICIENT_B_AMOUNT": "Excessive second amount", //
  "INSUFFICIENT_INPUT_AMOUNT": "Insufficient sell amount", //
  "INSUFFICIENT_LIQUIDITY": "Insufficient liquidity", //
  "INSUFFICIENT_LIQUIDITY_BURNED": "INSUFFICIENT_LIQUIDITY_BURNED", // not shown in UI
  "INSUFFICIENT_LIQUIDITY_MINTED": "INSUFFICIENT_LIQUIDITY_MINTED", // not shown in UI
  "INSUFFICIENT_OUTPUT_AMOUNT": "Insufficient buying amount", //
  "INSUFICIENT_AMOUNT_IN": "Insufficient input amount to cover for the fees", //
  "INVALID_PATH": "Swap for this pair is not available", //
  "INVALID_SIGNATURE": "Invalid signature", //
  "INVALID_TO": "Invalid request", // happens on swap when the to address is one of tokens involved in the swap
  "K": "Not enough liquidity to perform the swap", //
  "Liquidity already added": "Liquidity already added", // not shown in UI
  "LOCKED": "LOCKED", // not used
  "LP is not allowed for staking": "Staking is not available for this Pool", //
  "Must be at least 1%": "Must be at least 1%", // not shown in UI
  "Must be at most 99%": "Must be at most 99%", // not shown in UI
  "Must stake above minimum staking interval": "Minimum staking period did not pass to collect the reward", //
  "Only fee receiver can call this function.": "Only fee receiver can call this function.", //
  "OVERFLOW": "OVERFLOW", // not shown in UI
  "Pair does not exist": "Pair does not exist", // not shown in UI
  "Pair not found": "No Pool available to perform the transaction", //
  "PAIR_EXISTS": "PAIR_EXISTS", // not shown in UI
  "Reward can be claimed only if staked for 1 year": "Minimum staking period did not pass to collect the reward", //
  "Reward for this address not found!": "Staking Rewards are not enabled for this Pool", //
  "Rewards already claimed!": "You have already claimed the rewards", //
  "signature does not match request": "Invalid signature", //
  "Stake can be withdrawn only once": "You have already claimed the rewards", //
  "Stake for this address not found!": "Stake for this address not found!", //
  "Stake for this index not found!": "Stake for this index not found!", //
  "Stake is not active": "Stake is not active", // not shown in UI
  "Staking is not active": "Staking is not active yet", //
  "TRANSFER_FAILED": "Transfer failed", //
  "TRANSFER_FROM_FAILED": "Transfer from failed", //
  "ZERO_ADDRESS": "Failed to create the pair", //
}

const Errors =  {
  errors: errors,

  stripPrefix(message, prefix) {
    if (message.startsWith(prefix)) return message.substring(prefix.length);
    if (message.endsWith("'") || message.endsWith('"'))
      message = message.substring(0, message.length - 1);
    return message;
  },

  errorMessage(error) {
    let message = error.message;

    message = this.stripPrefix(message, "Internal JSON-RPC error.\n");
    if (message.startsWith("{")) {
      try {
        let data = JSON.parse(message);
        message = data.message;
      } catch (error) {}
    }

    message = this.stripPrefix(
      message,
      'VM Exception while processing transaction: revert with reason "'
    );
    message = this.stripPrefix(
      message,
      "Error: VM Exception while processing transaction: reverted with reason string '"
    );
    message = this.stripPrefix(message, "MetaMask Tx Signature: ");
    message = this.stripPrefix(message, "Error: Transaction reverted: ");

    if (message.includes(": ")) {
      message = message.replace(/.*?:\s/, "");
    }

    if (errors[message]) {
      message = errors[message];
    } else {
      Sentry.captureMessage("Error message not customized: <" + message + ">");
    }

    return message;
  }
}


export default Errors;
