import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ArrowDown, ExclamationCircle, Wallet2 } from 'react-bootstrap-icons';
import { Globals } from '../utils';

class Unstake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      agree: false,
      loading: false,
      error: undefined
    }
    this.close = this.close.bind(this);
    this.unstake = this.unstake.bind(this);
    this.agreementCheckChanged = this.agreementCheckChanged.bind(this);
  }

  close() {
    this.setState({ show: false });
    if(this.props.closeCallback)
      this.props.closeCallback();
  }

  unstake() {
    this.setState({ loading: true });
    let contract = this.props.stake.prepareUnstake();
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute();
    this.close();
  }

  agreementCheckChanged(event) {
    this.setState({ agree: event.target.checked });
  }


  render() {
    return(
      <>
        <Modal centered show={this.state.show} onHide={this.close}>
          <Modal.Header closeButton>Danger!</Modal.Header>
          <Modal.Body className=''>
            <div className="border border-1 border-danger rounded-3 p-3">
              <div className="d-flex justify-content-center align-items-center">
                <div className="rounded rounded-circle bg-danger-light p-2 lh-1">
                  <ExclamationCircle size={16} className="text-danger" />
                </div>
                <div className="text-danger lh-sm ms-3">
                  You are about to exit your<br/>
                  position early!
                </div>
              </div>
              <hr />
              <div className='mb-3'>
                Your stake is in a time-based contract.
                Unstaking now will result in total loss
                of your DXVS rewards.
              </div>
              <div className="mb-3 bg-gray-700 rounded-3 p-3 small">
                <div className="d-flex">
                  <div className="text-gray-200">Reward to collect</div>
                  <div className="text-gray-200 fw-medium ms-auto">REWARD VALUE DXVS</div>
                </div>
                <div className="d-flex mt-3">
                  <div className="text-gray-200">Reward Impact</div>
                  <div className="fw-medium ms-auto text-danger">
                    <ArrowDown />
                    100%
                  </div>
                </div>
              </div>
              <Form.Check type='checkbox' id='agreement'>
                <Form.Check.Input type='checkbox' onChange={this.agreementCheckChanged} />
                <Form.Check.Label className='fw-medium'>I understand, and Agree</Form.Check.Label>
              </Form.Check>

              <Button variant='primary' disabled={!this.state.agree} className="mt-3 w-100" onClick={this.unstake}>
                <Wallet2 size={16} className="me-2" />
                Exit Position &amp; Collect Reward
              </Button>

              <Button variant='link' className="mt-3 w-100" onClick={this.close}>
                Back to Safety
              </Button>

            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Unstake;
