import React from 'react';
import Connect from './Connect';
import { Col, Row } from 'react-bootstrap';
import NoBalance from './NoBalance';
import Home from './Home';
import Boost from './Boost';
import Loading from '../Components/Loading';
import BoostModel from '../Stores/Boost';
import If from '../Components/If';
import Contracts from '../Blockchain/Contracts';

class Index extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      usdcBalance: undefined,
      pair: undefined,
      stakes: [],
      page: 'connect',
      loading: true
    }

    this.setPage = this.setPage.bind(this);
    this.updateLoadingState = this.updateLoadingState.bind(this);
    this.prepare = this.prepare.bind(this);
    this.updateStakes = this.updateStakes.bind(this);
  }

  async setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve));
  }

  componentDidMount() {
    this.prepare();
    this.loadStakes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.prepare();
      this.loadStakes();
    }
  }

  async prepare() {
    if(this.props.walletStatus !== 'connected') {
      this.setState({ loading: false, page: 'connect' });
      return Promise.resolve();
    }
    return Promise.all([
      this.getUSDCBalance(),
      this.getPair()
    ]);
  }

  getUSDCBalance() {
    if(this.props.address === '') return Promise.resolve();

    let contract = Contracts.usdc;
    return contract.methods.balanceOf(this.props.address).call()
      .then((balance) => {
        return parseFloat(this.props.wallet.web3.utils.fromWei(balance, this.props.wallet.usdcToken().unit));
      })
      .then((balance) => {
        return this.setStateAsync({ usdcBalance: balance, page: balance > 0 ? 'home' : 'no-balance' });
      })
      .then(() => {
        this.updateLoadingState();
        return true;
      });
  }

  async getPair() {
    if(this.props.address === '') return Promise.resolve();

    const pairAddress = await this.props.wallet.getPair(this.props.wallet.usdcTokenAddress, this.props.wallet.dxvsTokenAddress);
    const pair = await this.props.wallet.getPairInfo(pairAddress);
    const model = new BoostModel(pair, this.props.wallet);
    return this.setStateAsync({ pair: model })
      .then(() => {
        this.updateLoadingState();
        return true;
      });
  }

  loadStakes() {
    if(this.props.address === '') return;
    this.updateStakes();
    this.props.wallet.liquidity_boosts
      .on("change", this.updateStakes)
      .load();
  }


  updateStakes() {
    this.setState({
      stakes: this.props.wallet.liquidity_boosts.list.filter(s => s.isClaimable || s.isUnstakeable)
    });
  }

  updateLoadingState() {
    this.setState({
      loading: this.state.usdcBalance === undefined && this.state.pair === undefined
    });
  }

  setPage(page) {
    this.setState({ page: page });
  }

  render() {
    if (this.state.loading)
      return (<Loading className="w-50 mx-auto mt-5"/>);

    return (
      <>
        <Row className="justify-content-center mt-4">
          <If condition={this.state.page === 'connect'}>
            <Col className='col-12'>
              <Connect
                actionCallback={this.props.actionCallback}
              />
            </Col>
          </If>

          <If condition={this.state.page === 'no-balance'}>
            <Col className='col-12 col-lg-6'>
              <NoBalance
                refresh={this.prepare}
              />
            </Col>
          </If>

          <If condition={this.state.page === 'home'}>
            <Col className='col-12 col-lg-6'>
              <Home
                usdcBalance={this.state.usdcBalance}
                pair={this.state.pair}
                setPage={this.setPage}
                stakes={this.state.stakes}
                refresh={this.prepare}
              />
            </Col>
          </If>

          <If condition={this.state.page === 'boost'}>
            <Col className='col-12 col-lg-6'>
              <Boost
                usdcBalance={this.state.usdcBalance}
                pair={this.state.pair}
                setPage={this.setPage}
                wallet={this.props.wallet}
                refresh={this.prepare}
              />
            </Col>
          </If>
        </Row>
      </>
    );
  }
}

export default Index;
