import config from "../Config";
import Contracts from "../Blockchain/Contracts";

class LiquidityBoost {
  constructor(data, investFromToken, investToToken, rewardToken, wallet) {
    this.data = data;
    this.investFromToken = investFromToken;
    this.investToToken = investToToken;
    this.rewardToken = rewardToken;
    this.wallet = wallet;
    this.minimumStakePeriod = config.env === 'production' ? 3600 * 24 * 365 : 60
  }

  async init() {
    return Promise.resolve();
  }

  get index() {
    return this.data.index;
  }

  get investAmount() {
    return parseInt(this.wallet.web3.utils.fromWei(this.data.amountInitialInvestFromToken, this.investFromToken.unit), 10);
  }

  get stakeFromAmount() {
    return this.investAmount / 2;
  }

  get stakeToAmount() {
    return this.investAmount / 2 * 5;
  }

  get rewardAmount() {
    return this.investAmount * 5;
  }

  get isActive() {
    return this.data.isActive;
  }

  get isClaimed() {
    return this.data.isClaimed;
  }

  get liquidityAdded() {
    return this.data.liquidityAdded;
  }

  get startDate() {
    return this.data.startDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get isClaimable() {
    return !this.isClaimed && this.stakedSeconds > (this.minimumStakePeriod);
  }

  get isUnstakeable() {
    return this.isActive;
  }

  get unclaimableReason() {
    if (this.isClaimed) {
      return "Already claimed";
    } else {
      return "Not enough time has passed";
    }
  }

  get stakedSeconds() {
    let stakedUntilTs = this.isActive ? +new Date() / 1000 : this.endDate;
    return stakedUntilTs - this.startDate;
  }

  get timeLeft() {
    let startDateTs = +this.startDate;
    let claimDateTs = startDateTs + this.minimumStakePeriod;
    let nowTs = +new Date() / 1000;
    if (nowTs >= claimDateTs)
      return { days: 0, hours: 0 }
    let seconds = claimDateTs - nowTs;
    let days = Math.floor(seconds / (3600 * 24));
    let hours = Math.floor((seconds - days * 3600 * 24) / 3600);
    return { days: days, hours: hours };
  }

  prepareCollectReward() {
    return Contracts.bootstrap.methods
      .claimRewards(this.index)
      .prepare({ from: this.wallet.address });
  }

  async collectReward() {
    return this.prepareCollectReward().execute();
  }

  prepareUnstake() {
    return Contracts.bootstrap.methods
      .unStake(this.index)
      .add(
        Contracts.bootstrap.methods.claimRewards(this.index),
        { from: this.wallet.address }
      )
      .prepare({ from: this.wallet.address });
  }

  async unstake() {
    return this.prepareUnstake().execute();
  }

  async refresh() {
    let contract = Contracts.bootstrap;
    return contract.methods.stakes(this.wallet.address, this.index).call()
      .then((stake) => {
        let savedIndex = this.index;
        this.data = stake;
        this.data.index = savedIndex;
      })
      .catch((err) => { });
  }
}

export default LiquidityBoost;
