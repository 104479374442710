import React from 'react';
import { Button } from 'react-bootstrap';
import { BigNumber } from "bignumber.js/bignumber.js";
import { fromContractDecimals, Globals } from '../../utils';
import AddLiquidityInfo from './AddLiquidityInfo.js';
import TokenSelectForSwap from '../../TokenSelectForSwap';
import AddLiquidityConfirmation from './AddLiquidityConfirmation';
import { Plus } from 'react-bootstrap-icons';
import If from '../../Components/If';
import Pool from '../../Stores/Pool';
import Liquidator from '../../Wallet/Liquidator';

class AddLiquidity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      canChangeTokens: props.fromToken === undefined && props.toToken === undefined,
      fromToken: props.fromToken || props.balances[0],
      toToken: props.toToken || props.balances[1],
      poolFromAmount: 0,
      poolToAmount: 0,
      newPool: true,
      poolPrice: 0,
      pair: {},
      step: 'data',
      error: undefined,
      token0InputValid: true,
      token1InputValid: true,
    }
    this.steps = ['data', 'confirmation', 'executing'];

    this.setFromToken = this.setFromToken.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.setFromAmount = this.setFromAmount.bind(this);
    this.setToAmount = this.setToAmount.bind(this);
    this.addLiquidity = this.addLiquidity.bind(this);
    this.cancelConfirmation = this.cancelConfirmation.bind(this);
    this.token0ValidityChanged = this.token0ValidityChanged.bind(this);
    this.token1ValidityChanged = this.token1ValidityChanged.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.getPair();
    this.setFromToken(this.state.fromToken.address);
    this.setToToken(this.state.toToken.address);
  }

  cancelConfirmation() {
    this.setState({ step: "data" });
  }

  // direction 1 - from->to, * - to->from
  computePrice(fromAmount, toAmount, direction = 1) {
    if (!this.state.pair) return;

    if (this.state.newPool) {
      let price = fromAmount > 0 && toAmount > 0 ? (toAmount / fromAmount) : 0;
      this.setState({
        poolPrice: price,
        share: price > 0 ? 100 : "",
        valid: price > 0 && this.state.token0InputValid && this.state.token1InputValid
      });
      return;
    }

    const reserve0 = new BigNumber(fromContractDecimals(this.state.pair.reserve0, this.state.pair.token0.decimals));
    const reserve1 = new BigNumber(fromContractDecimals(this.state.pair.reserve1, this.state.pair.token1.decimals));
    let price = 0;
    if (reserve0.isPositive() && reserve1.isPositive()) {
      if(this.state.pair.token0.address === this.state.fromToken.address) {
        price = reserve1.div(reserve0).toNumber();
      } else {
        price = reserve0.div(reserve1).toNumber();
      }

      this.setState({ poolPrice: price });
    }

    if (direction === 1) {
      toAmount = fromAmount === 0 ? 0 : (fromAmount * (price))
      this.setState({ poolToAmount: toAmount });
    } else {
      fromAmount = toAmount === 0 ? 0 : (toAmount * (1 / price))
      this.setState({ poolFromAmount: fromAmount });
    }

    if (fromAmount > 0) {
      let fromAmountBN = new BigNumber(fromAmount);
      let share = fromAmountBN.div(reserve0.plus(fromAmountBN)).times(100).toFixed(2);
      this.setState({ share: share, valid: this.state.token0InputValid && this.state.token1InputValid })
    } else {
      this.setState({ share: "", valid: false })
    }

  }

  async getPair() {
    if (this.state.fromToken.address === this.state.toToken.address) {
      this.setState({ pair: {}, valid: false });
    }

    Pool.getByPair(this.state.fromToken.address, this.state.toToken.address, this.props.wallet).then((pool) => {
      let pair = this.invertTokensIfNeeded(pool.data);
      this.setState({
        pair: pair,
        newPool: pair.supply === "0"
      }, ()=> this.computePrice(this.state.poolFromAmount, this.state.poolToAmount));
    }).catch((e) => {
      this.setState({
        pair: {},
        newPool: true
      }, ()=> this.computePrice(this.state.poolFromAmount, this.state.poolToAmount));
    });
  }

  invertTokensIfNeeded(pair) {
    if (pair.token0.address !== this.state.fromToken.address) {
      pair = {
        address: pair.address,
        token0: pair.token1,
        token1: pair.token0,
        reserve0: pair.reserve1,
        reserve1: pair.reserve0,
        balance: pair.balance,
        supply: pair.supply
      }
    }
    return pair;
  }

  setFromAmount(amount) {
    this.setState({ poolFromAmount: amount });
    this.computePrice(amount, this.state.poolToAmount, 1);
  }

  setToAmount(amount) {
    this.setState({ poolToAmount: amount });
    this.computePrice(this.state.poolFromAmount, amount, 2);
  }

  setFromToken(address) {
    let fToken = this.props.balances.filter((x)=>{ return (x.address === address); })[0];
    this.setState({ fromToken: fToken }, ()=> this.getPair());
  }

  setToToken(address) {
    let tToken = this.props.balances.filter((x)=>{ return (x.address === address); })[0];
    this.setState({ toToken: tToken }, ()=> this.getPair());
  }

  token0ValidityChanged(valid) {
    this.setState({
      token0InputValid: valid
    }, ()=> this.computePrice(this.state.poolFromAmount, this.state.poolToAmount));
  }

  token1ValidityChanged(valid) {
    this.setState({
      token1InputValid: valid
    }, ()=> this.computePrice(this.state.poolFromAmount, this.state.poolToAmount));
  }

  addLiquidity() {
    this.setState({ step: "executing" });
    let minFromAmount = new BigNumber(this.state.poolFromAmount);
    minFromAmount = minFromAmount.multipliedBy(0.95);
    let minToAmount = new BigNumber(this.state.poolToAmount);
    minToAmount = minToAmount.multipliedBy(0.95);
    let liquidator = new Liquidator(
      this.props.wallet,
      this.state.fromToken.address,
      this.state.toToken.address,
      this.state.poolFromAmount,
      this.state.poolToAmount,
      minFromAmount,
      minToAmount
    );

    liquidator.prepare();
    Globals.pendingTransactions.add(liquidator.transaction);
    liquidator.execute();
    if (this.props.onLiquidityAdded)
      this.props.onLiquidityAdded();
  }

  reset() {
    if (this.state.transaction.status === "success") {
      this.setState({
        step: "data",
        poolFromAmount: 0,
        poolToAmount: 0,
        transaction: undefined,
        error: undefined
      });
    } else {
      this.setState({ step: "data" });
    }
  }

  render() {
    return(
      <>
        <If condition={this.state.step === "confirmation"}>
          <AddLiquidityConfirmation
            closeCallback={this.cancelConfirmation}
            confirmCallback={this.addLiquidity}
            fromToken={this.state.fromToken}
            toToken={this.state.toToken}
            fromAmount={this.state.poolFromAmount}
            toAmount={this.state.poolToAmount}
            price={this.state.poolPrice}
            share={this.state.share}
          />
        </If>

        <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
          <TokenSelectForSwap
              key="token0"
              balances={this.props.balances}
              token={this.state.fromToken}
              value={this.state.poolFromAmount}
              setTokenCallback={this.setFromToken}
              setValueCallback={this.setFromAmount}
              canChangeTokens={this.state.canChangeTokens}
              validate={true}
              onValidityChanged={this.token0ValidityChanged}
            />
        </div>

        <div className="d-flex flex-grow my-2">
          <hr className='m-0 flex-grow-1 my-auto' />
          <div className='text-gray-400'>
            <Plus size="32" color="#9AA4B2" />
          </div>
          <hr className='m-0 flex-grow-1 my-auto' />
        </div>

        <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
          <TokenSelectForSwap
              key="token1"
              balances={this.props.balances}
              token={this.state.toToken}
              value={this.state.poolToAmount}
              setTokenCallback={this.setToToken}
              setValueCallback={this.setToAmount}
              canChangeTokens={this.state.canChangeTokens}
              validate={true}
              onValidityChanged={this.token0ValidityChanged}
            />
        </div>


        <AddLiquidityInfo
          fromToken={this.state.fromToken}
          toToken={this.state.toToken}
          fromTokenAmount={this.state.poolFromAmount}
          toTokenAmount={this.state.poolToAmount}
          price={this.state.poolPrice}
          share={this.state.share}
        />

        <Button size='lg' className='btn btn-primary w-100 mt-3' disabled={!this.state.valid} onClick={()=> this.setState({ step: "confirmation" })}>
          Add Liquidity
        </Button>
      </>
    );
  }
}

export default AddLiquidity;
