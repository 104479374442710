import DexversIcon from "../../../Assets/dexvers-token.svg";
import { Spinner } from "react-bootstrap";

const PendingDefault = function({ transaction }) {
  return (
    <>
      <div className="d-flex py-3">
        <div className="m-auto position-relative">
          <div className="d-flex position-absolute" style={{ width: "3rem", height: "3rem" }}>
            <img src={DexversIcon} className="m-auto" alt="dexvers" />
          </div>
          <Spinner animation="border" variant="secondary" style={{ width: "3rem", height: "3rem" }} />
        </div>
      </div>
      <div className="">
          <h5 className="text-center">Transaction In Progress ...</h5>
          <div className="text-gray-400 text-center">Get ready to touch the moon!</div>
      </div>
    </>
  );
};

export default PendingDefault;
