import { toContractDecimals } from "../utils";
import Contracts from "../Blockchain/Contracts";

class LiqudityBooster {
  constructor(wallet, amount) {
    this.wallet = wallet;
    this.usdc = this.wallet.usdcToken();
    this.amount = amount;
    this.amountWei = toContractDecimals(amount, this.usdc.decimals);
  }

  prepare() {
    this.contract = this.build();
  }

  async execute() {
    return this.contract.execute();
  }

  build() {
    return Contracts.bootstrap.methods
     .stake(this.amountWei)
      .requiresApproval(this.usdc.address, this.amountWei)
      .prepare({ from: this.wallet.address });
  }

  get transaction() {
    return this.contract.transaction;
  }
}

export default LiqudityBooster;
