import React from 'react';

class Unless extends React.Component {
  render() {
    if (this.props.condition) {
      return (<></>);
    } else {
      return (<>{this.props.children}</>)
    }
  }
}

export default Unless;
