import React from 'react';
import { Button, Col, Row, InputGroup, Form } from 'react-bootstrap';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import AmountInput from '../Components/AmountInput';
import TokenIcon from '../Components/TokenIcon';
import TokenBalance from '../Components/TokenBalance';
import { fromContractDecimals } from '../utils';

class SwapWizSelectAmount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.amount,
      inputValue: props.amount,
      percentValue: props.amount / props.fromToken.balance * 100 || 0,
      balance: fromContractDecimals(props.fromToken.balance, props.fromToken.decimals)
    };
    this.valueUpdate = this.valueUpdate.bind(this);
    this.percentUpdate = this.percentUpdate.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  valueUpdate(value) {
    if (value > this.state.balance) {
      value = this.state.balance;
    }
    let percent = Math.round(value / this.state.balance * 100);
    this.setState({
      value: value,
      percentValue: percent
    });
  }

  percentUpdate(event) {
    let percentage =  parseInt(event.target.value);
    let value = this.state.balance * percentage / 100;
    this.setState({
      inputValue: value,
      percentValue: percentage
    })
  }

  goBack() {
    this.props.goToStep('src');
  }

  render() {
    return (
      <div className='px-4 py-3'>
        <h2 className='text-center mb-5'>How much are you planning to swap?</h2>

        <p className="small mb-2">Your Balance</p>
        <div className="bg-gray-700 rounded-3 p-3">
            <div className="d-flex align-items-center mt-3 mb-4">
              <TokenIcon token={this.props.fromToken} size={32} />
              <div className="ms-3">
                <span className="text-gray-200">{this.props.fromToken.name}</span>&nbsp;
                <span className="text-gray-400">({this.props.fromToken.symbol})</span>
              </div>
            </div>
            <hr className="text-gray-300" />
            <div className="d-flex mt-2 align-items-baseline">
              <div className="text-gray-100">Balance</div>
              <div className="text-gray-100 ms-auto fs-2 fw-bold">
                <TokenBalance token={this.props.fromToken}></TokenBalance>
              </div>
            </div>
        </div>

        <p className="small mb-2 mt-4">Your Balance</p>
        <div className="bg-gray-700 rounded-3 p-4">
          <div className="text-gray-100">{this.props.fromToken.name} ({this.props.fromToken.symbol})</div>
          <div className='mt-4'>
            <Form.Range min='0' max='100' step='1' value={this.state.percentValue} onChange={this.percentUpdate} />
            <div className='g-0 range-ruler'>
              <div className='step'><div className="vr"></div><div className="percent">0%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">10%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">20%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">30%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">40%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">50%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">60%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">70%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">80%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">90%</div></div>
              <div className='step'><div className="vr"></div><div className="percent">100%</div></div>
            </div>
          </div>
          <div className="d-flex flex-grow mt-5 mb-3">
            <hr className='m-0 flex-grow-1 my-auto' />
            <div className="text-gray-400">👆 SLIDE OR TYPE AMOUNT 👇</div>
            <hr className='m-0 flex-grow-1 my-auto' />
          </div>
          <Row className="justify-content-center">
            <Col className="col-12 col-lg-6">
              <InputGroup>
                <InputGroup.Text className='text-gray-500 bg-white align-items-center'>
                  <TokenIcon className="me-2 lh-1" token={this.props.fromToken} size={20} />
                  <span>{this.props.fromToken.symbol}</span>
                </InputGroup.Text>
                <AmountInput
                  className="text-gray-500 border-start-0"
                  value={this.state.inputValue}
                  max={this.state.balance}
                  onChange={this.valueUpdate}
                />
              </InputGroup>
              </Col>
          </Row>

        </div>


        <Button className="w-100 mt-4" size='lg' variant="primary" onClick={(e) => this.props.amountCallback(this.state.value)}>
          <ArrowLeftRight size={22} />
          &nbsp;
          Next: Choose token to swap to
        </Button>

        <Button className="w-100 mt-3" variant="link" onClick={(e) => this.props.goToStep('src')}>
          Back
        </Button>
      </div>
    );
  }
}
export default SwapWizSelectAmount;
