import BigNumber from "bignumber.js";
import { EventEmitter } from "events";
import InsufficientBalanceForGasError from "../Utils/Errors/InsufficientBalanceForGasError";
import Deadline from "./Deadline";

class ContractCall extends EventEmitter {
  constructor (method, params) {
    super();
    this.method = method;
    this.params = params;
    this.method.meta.methodParams = params;
    this.status = "pending"; // pending, executing, success, error
  }

  set status(status) {
    this._status = status;
    this.emit("change", this, status);
  }

  get status() {
    return this._status;
  }

  async call(state) {
    let ts = Math.max(state.block.timestamp, Math.floor(Date.now() / 1000));
    for (let index = 0; index < this.method.meta.methodArgs.length; index++) {
      const element = this.method.meta.methodArgs[index];
      if (element instanceof Deadline) {
        this.method.arguments[index] = ts + element.delta;
      }
    }
    this.method.state = state;
    return this.estimate(state)
      .then((state) => { return this.execute(state); });
  }

  async estimate(state) {
    console.log("Estimating gas for: ", this.method.inspect() + JSON.stringify(this.params));
    return this.method.estimateGas(this.params)
      .then((gas) => {
        state.gas = new BigNumber(gas);
        console.log("Estimated gas: ", gas);
        console.log("State: ", state);
        let txPrice = state.gas.multipliedBy(state.gasPrice);
        if (txPrice.isGreaterThan(state.balance)) {
          throw  new InsufficientBalanceForGasError(txPrice, state.balance);
        }
        return state;
      })
      .catch((error) => {
        this.error = error;
        this.status = "failed";
        return Promise.reject(error);
      });
  }

  async execute(state) {
    let params = Object.assign({}, this.params, { gas: state.gas, gasPrice: state.gasPrice });
    console.log("Executing: ", this.method.inspect() + JSON.stringify(params));
    return this.method.send(params)
      .once("sending", () => { this.status = "executing"; })
      .once("sent", () =>    { this.status = "executing"; })
      .once("transactionHash", (hash) => { this.txHash = hash; })
      .once("receipt", (receipt) =>      { this.receipt = receipt; })
      .once("error", (error) => {
        this.error = error;
        this.status = "failed";
      })
      .then((result) => {
        this.result = result;
        this.status = "success";
        state.results = state.results || [];
        state.results.push(result);
        return state;
      })
      .catch((error) => {
        this.error = error;
        this.status = "failed";
        return Promise.reject(error);
      });
  }

  reset() {
    this.status = "pending";
    this.error = null;
    this.result = null;
    this.receipt = null;
    this.txHash = null;
  }

  description () {
    return this.method.description();
  }
}

export default ContractCall;
