import React from 'react';
import { Button } from 'react-bootstrap';
import { formattedAmount, Globals } from '../../utils';

class StakeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "view",
      error: undefined
    }
    this.unstake = this.unstake.bind(this);
  }

  unstake() {
    this.setState({ state: "unstaking" });
    let contract = this.props.stake.prepareUnstake();
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute()
      .then(() => {
        this.setState({ state: "view" });
      })
      .catch(error => {
        this.setState({ error: error, state: "view" });
      });
  }

  render() {
    return(
      <>
        <p>Your Position</p>

        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3">
          <div className="d-flex bg-opacity-100">
            <div className="px-3 my-auto">{this.props.stake.pool.token0.symbol} - {this.props.stake.pool.token1.symbol}</div>
            <div className="text-end flex-fill me-5">
              <strong>{formattedAmount(this.props.stake.token0Balance)}</strong>&nbsp;
              <span className="text-gray-400">{this.props.stake.pool.token0.symbol}</span>
              <br/>
              <strong>{formattedAmount(this.props.stake.token1Balance)}</strong>&nbsp;
              <span className="text-gray-400">{this.props.stake.pool.token1.symbol}</span>
            </div>
          </div>
        </div>

        <div className="bg-gray-700 bg-opacity-35 rounded-3 px-3 py-2 mt-3 text-center">
          <div className="bg-opacity-100">
            {formattedAmount(this.props.stake.isActive ? this.props.stake.amount : 0)} &nbsp; LP Tokens &nbsp;
            <span className="text-gray-500">
              ({this.props.stake.pool.token0.symbol}-{this.props.stake.pool.token1.symbol}-LP)
            </span>
          </div>
        </div>

        <Button size='lg' className='btn btn-primary w-100 mt-3' onClick={this.unstake} disabled={!this.props.stake.isActive}>
          Withdraw Stake
        </Button>
      </>
    );
  }
}

export default StakeInfo;
