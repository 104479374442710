import React from 'react';
import SwapWizPickFromToken from './SwapWizPickFromToken';
import SwapWizPickToToken from './SwapWizPickToToken';
import SwapWizSelectAmount from './SwapWizSelectAmount';
import SwapWizConfirm from './SwapWizConfirm';
import { Col, Row } from 'react-bootstrap';
import { Globals } from '../utils';
import Swapper from '../Wallet/Swapper';

class SwapWiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: Globals.address,
      fromToken: undefined,
      swapAmount: 0,
      toToken: undefined,
      step: 'src'
    }
    this.steps = ['src', 'amount', 'dst', 'confirm'];

    this.setFromToken = this.setFromToken.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.doSwap = this.doSwap.bind(this);
  }

  goToStep(step) {
    this.setState({ step: step });
  }

  updateNextStep() {
    let index = this.steps.indexOf(this.state.step);
    this.setState({ step: this.steps[index + 1] });
    if (index === 2)
      this.estimateAmountOut();
  }

  setFromToken(token) {
    this.setState({ fromToken: token }, this.updateNextStep);
  }

  setAmount(amount) {
    this.setState({ swapAmount: amount }, this.updateNextStep)
  }

  setToToken(token) {
    this.setState({ toToken: token }, this.updateNextStep);
  }

  estimateAmountOut() {
    this.props.wallet.estimateAmountOut(this.state.swapAmount, this.state.fromToken.address, this.state.toToken.address).then((result) => {
      this.setState({
        amountOut: result.amount,
        priceImpact: result.impact,
        amountOutAfterSlippage: result.slippage,
        exchangePath: result.path,
        routerAddress: result.routerAddress
      })
    });
  }

  doSwap() {
    this.swapper = new Swapper(
      this.props.wallet,
      this.state.swapAmount,
      this.state.amountOut,
      this.state.fromToken.address,
      this.state.toToken.address,
      this.state.exchangePath,
      this.state.routerAddress
    );
    this.swapper.prepare();
    Globals.pendingTransactions.add(this.swapper.transaction);
    this.swapper.execute();
    this.reset();
  }

  swapCallback(success, result) {
    console.log('swapCallback', success, result);
    if (success) {
      this.setState({ swapResult: result });
    } else {
      this.setState({ error: result });
    }
  }

  reset() {
  this.setState({
    step: 'src',
    fromToken: undefined,
    swapAmount: 0,
    toToken: undefined,
    error: undefined,
    swapResult: undefined,
    transaction: undefined
    });
  }

  render() {
    return(
      <Row className='justify-content-center'>
        <Col className='col-12 col-md-10 col-lg-8 align-self-center'>
          <div className=''>
            {this.state.step === 'src' &&
              <SwapWizPickFromToken
                key='fromToken'
                tokens={this.props.tokens}
                setFromTokenCallback={this.setFromToken}
              />
            }
            {this.state.step === 'amount' &&
              <SwapWizSelectAmount
                key='amount'
                amount = {this.state.swapAmount}
                amountCallback={this.setAmount}
                fromToken={this.state.fromToken}
                goToStep={this.goToStep}
                wallet={this.props.wallet}
              />
            }
            {this.state.step === 'dst' &&
              <SwapWizPickToToken
                tokens={this.props.tokens}
                setToTokenCallback={this.setToToken}
                fromToken={this.state.fromToken}
                goToStep={this.goToStep}
              />
            }
            {(this.state.step === 'confirm') &&
              <SwapWizConfirm
                fromToken={this.state.fromToken}
                toToken={this.state.toToken}
                amountOut={this.state.amountOut}
                amountIn={this.state.swapAmount}
                priceImpact={this.state.priceImpact}
                amountOutAfterSlippage={this.state.amountOutAfterSlippage}
                goToStep={this.goToStep}
                doSwap={this.doSwap}
              />
            }
          </div>
        </Col>
      </Row>
    );
  }
}
export default SwapWiz;
