import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class SimpleTooltip extends Component {
  render() {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip className={this.props.tooltipClassName}>
            {this.props.tooltip}
          </Tooltip>
        }
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }
}

export default SimpleTooltip;
