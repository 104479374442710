import React from 'react';
import { formattedAmount, fromContractDecimals, Globals } from '../utils';
import SimpleTooltip from './SimpleTooltip';

class TokenBalance extends React.Component {
  static defaultProps = {
    className: 'd-inline',
    token: undefined
  }

  constructor(props) {
    super(props);
    this.state = { balance: this.balance };
    this.balanceChanged = this.balanceChanged.bind(this);
  }

  componentDidMount() {
    console.log("TokenBalance mounted", this.props.token);
    if (this.props.token !== undefined)
      this.subscription = Globals.balancePoller.subscribe(this.props.token.address, this.balanceChanged);
  }

  get balance() {
    return fromContractDecimals(this.props.token?.balance, this.props.token?.decimals);
  }

  balanceChanged(balance) {
    this.props.token.balance = balance;
    this.setState({ balance: this.balance });
    if (this.props.onBalanceChanged)
      this.props.onBalanceChanged();
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== undefined && prevProps.token?.address !== this.props.token.address) {
      Globals.balancePoller.unsubscribe(this.subscription);
      this.subscription = Globals.balancePoller.subscribe(this.props.token.address, this.balanceChanged);
      this.setState({ balance: this.balance });
      if (this.props.onBalanceChanged)
        this.props.onBalanceChanged();
    }
  }

  componentWillUnmount() {
    Globals.balancePoller.unsubscribe(this.subscription);
  }

  render() {
    if (this.props.token === undefined)
      return "n/a";
    return (
      <SimpleTooltip tooltip={this.state.balance}>
        <div className={this.props.className}>
          {formattedAmount(this.state.balance)}
        </div>
      </SimpleTooltip>
    );
  }
}

export default TokenBalance;
