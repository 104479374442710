import { Check2Circle } from "react-bootstrap-icons";
import { formattedAmount, fromContractDecimals, Globals } from "../../../utils";

const BootstrapStakeSuccess = function({ transaction }) {
  let contract = transaction.calls.at(-1).method;
  let token0 = Globals.wallet.usdcToken();
  let token1 = Globals.wallet.dxvsToken();
  let value = fromContractDecimals(contract.meta.methodArgs[0], token0.decimals);

  return (
    <>
      <div className="text-center">
        <div className="d-inline-block rounded rounded-circle bg-success-light p-2 lh-1 my-3">
          <Check2Circle size={24} className="text-success m-1" />
        </div>
        <div className="text-white fw-medium fs-5">
        Stake Successful
        </div>
      </div>
    </>
  );
};

export default BootstrapStakeSuccess;
