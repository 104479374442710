import { Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { Globals } from "../../../utils";

const SwapSuccess = function({ transaction }) {
  let meta = transaction.calls.at(-1).method.meta;
  let token;
  if (meta.contractType === 'router' && meta.methodName === 'swapExactTokensForTokens') {
    token = Globals.wallet.token(meta.methodArgs[2].at(-1));
  } else if (meta.contractType === 'router' && meta.methodName === 'swapExactETHForTokens') {
    token = Globals.wallet.token(meta.methodArgs[1].at(-1));
  } else if (meta.contractType === 'router' && meta.methodName === 'swapExactTokensForETH') {
    token = Globals.wallet.nativeToken();
  } else if (meta.contractType === 'WETH' && meta.methodName === 'withdraw') {
    token = Globals.wallet.nativeToken();
  } else if (meta.contractType === 'WETH' && meta.methodName === 'deposit') {
    token = Globals.wallet.wrappedToken();
  }

  function getExplorerUrl() {
    let txHash = transaction.calls.at(-1).txHash;
    return Globals.wallet.getExplorerUrl(txHash);
  }

  function addToWallet() {
    Globals.wallet.addTokenToWallet(token);
  }

  return (
    <div>
      <div className="d-flex py-3">
        <div className="m-auto position-relative">
          <CheckCircleFill size="40" className="rounded-circle overflow-hidden text-success-light bg-success" />
        </div>
      </div>
      <div className="text-center mb-4">
        <h5 className="text-center">Transaction completed!</h5>
        <Button href={getExplorerUrl()} target="_blank" variant="primary" size="sm" className="w-50 mt-3">
          View in Explorer
        </Button>
        {token.address !== Globals.wallet.nativeToken().address &&
          <>
            <br />
            <Button onClick={addToWallet} variant="primary" size="sm" className="w-50 mt-3">
              Add {token?.name} to your Wallet
            </Button>
          </>
        }

      </div>
    </div>

  );
};

export default SwapSuccess;
