import BigNumber from "bignumber.js";
import { EventEmitter } from "events";
import { Globals } from "../utils";
import ContractCall from "./ContractCall";

class Transaction extends EventEmitter {
  static start() {
    return new Transaction();
  }

  constructor() {
    super();
    this.calls = [];
    this._status = "pending"; // pending, executing, success, failed
  }

  set status(status) {
    this._status = status;
    this.emit("change", this, status);
  }

  get status() {
    return this._status;
  }

  add(method, params = {}) {
    const contractCall = new ContractCall(method, params);
    contractCall.on("change", (call, status) => {
      this.emit("change", this, status);
      this.updateStatus();
    });
    this.calls.push(contractCall);
    return this;
  }

  merge(transaction) {
    transaction.calls.forEach((call) => {
      this.add(call.method, call.params);
    });
    return this;
  }

  updateStatus() {
    for (let index = 0; index < this.calls.length; index++) {
      const call = this.calls[index];
      if (call.status === "failed") { // if any call failes then the transaction fails
        this.error = call.error;
        this.status = "failed";
        return;
      }
      if (call.status !== "success") { // if there's a call that's not finished then the transaction is still executing
        return;
      }
    }
    this.status = "success";
  }

  async execute() {
    this.status = "executing";
    this.callIndex = 0;
    console.log("Executing transaction with calls:", "\n" + this.calls.map((call) => call.description()).join("\n"));
    return this.getBlock({})
      .then((state) => { return this.getGasPrice(state); })
      .then((state) => { return this.getBalance(state); })
      .then((state) => { return this.executeNext(state); });
  }

  async retry() {
    this.calls.forEach((call) => {
      call.reset();
    });
    return this.execute();
  }

  async executeNext(state) {
    const call = this.calls[this.callIndex];
    if (!call) {
      return Promise.resolve(state);
    }
    return call.call(state).then((state) => {
      this.callIndex++;
      return this.executeNext(state);
    });
  }

  async getBlock(state) {
    state.blockNumber = await Globals.web3.eth.getBlockNumber();
    return Globals.web3.eth.getBlock(state.blockNumber).then((block) => {
      state.block = { timestamp: block !== null ? block.timestamp : new Date().getTime() };
      return state;
    });
  }

  async getGasPrice(state) {
    return Globals.web3.eth.getGasPrice()
      .then((gasPrice) => {
        state.gasPrice = new BigNumber(gasPrice);
        return state;
      });
  }

  async getBalance(state) {
    return Globals.web3.eth.getBalance(Globals.address)
      .then((balance) => {
        state.balance = new BigNumber(balance);
        return state;
      });
  }
}

export default Transaction;
