import Contracts from "../Blockchain/Contracts";
import Web3 from "web3";
import { Globals, toContractDecimals } from "../utils";

class BalancePoller {
  constructor(app) {
    this.app = app;
    this.interval = 5000;
    this.pollingInterval = null;
    this.startPolling = this.startPolling.bind(this);

    this.subscriptions = {};
    this.index = 0;
  }

  get web3() {
    if (this._web3 === undefined) {
      let provider = new Web3.providers.WebsocketProvider(
        Globals.blockchain.wssUrl,
        {}
      );
      this._web3 = new Web3(provider);
    }
    return this._web3;
  }

  startPolling() {
    this.watch();
    this.pool();
  }

  pool() {
    this.pollingInterval = setInterval(() => {
      this.app.state.wallet.getBalance()
        .then(balance => {
          balance = toContractDecimals(balance, 18);
          Globals.currentBlockchain.tokens.native.balance = balance;
          this.notify(Globals.native_currency_address, balance);
        });
    }, this.interval);
  }

  watch() {
    let signature = this.web3.utils.sha3('Transfer(address,address,uint256)');
    let address = this.web3.utils.padLeft(this.web3.utils.numberToHex(Globals.address), 64);
    this.watchTopic([signature, address, null]);
    this.watchTopic([signature, null, address]);
  }

  watchTopic(topics) {
    let subscription = this.web3.eth.subscribe('logs', { topics: topics });
    subscription.on("data", event => {
      console.log("Subscription data", event);
      this.update(event);
    });
    subscription.on('error', err => console.log("Subscription error:", err));
    subscription.on('connected', nr => console.log('Subscription on ERC-20 started with ID %s', nr));
  }

  update(event) {
    let token = this.app.token(event.address);
    this.updateToken(token, event);
    this.updatePool(token, event);
    this.updateStake(token, event);
    this.updateLiquidityBoosts(token, event);

    token = Globals.currentBlockchain.token(event.address);
    this.updateToken(token, event);
  }

  updateToken(token) {
    if (token === undefined) return;
    Contracts.erc20(token.address).methods.balanceOf(Globals.wallet.address).call().then(balance => {
      token.balance = balance;
      console.log("BalancePoller update", token);
      this.notify(token.address, balance);
    });
  }

  updatePool(token, event) {
    if (token !== undefined) return;
    console.log("BalancePoller update pool", event.address);
    setTimeout(() => {
      Globals.wallet.pools.refresh(event.address);
    }, 500);
  }

  updateStake(token, event) {
    if (token !== undefined) return;
    console.log("BalancePoller update stake", event.address);
    setTimeout(() => {
      Globals.wallet.stakes.refresh();
    }, 500);
  }

  updateLiquidityBoosts(token, event) {
    let sender = event.topics[1].toLowerCase().replace('0x000000000000000000000000', '0x');
    let receiver = event.topics[2].toLowerCase().replace('0x000000000000000000000000', '0x');
    let bootstrap = Globals.blockchain.bootstrapAddress.toLowerCase();

    if (token === undefined || (sender !== bootstrap && receiver !== bootstrap)) return;
    console.log("BalancePoller update liquidity boosts");
    setTimeout(() => {
      Globals.wallet.liquidity_boosts.refresh();
    }, 500);
  }

  subscribe(address, callback) {
    console.log("BalancePoller subscribe", address);
    this.index = this.index + 1;
    let key = `subscription-${this.index}`;
    this.subscriptions[key] = { address: address, callback: callback };
    this.ensureBalance(address);
    return key;
  }

  unsubscribe(key) {
    delete this.subscriptions[key];
  }

  ensureBalance(address) {
    let token = this.app.token(address);
    if (token !== undefined && token.balance === undefined) {
      this.updateToken(token);
    }

    token = Globals.currentBlockchain.token(address);
    if (token !== undefined && token.balance === undefined) {
      this.updateToken(token);
    }
  }

  notify(address, balance) {
    Object.values(this.subscriptions).forEach(subscription => {
      if (subscription.address === address) {
        subscription.callback(balance);
      }
    });
  }

  stopPolling() {
    clearInterval(this.pollingInterval);
    this.subscription.unsubscribe();
  }
}

export default BalancePoller;
