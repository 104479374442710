import App from "./App";
import './styles/index.scss';
import {Container} from 'react-bootstrap';
import React from 'react';
import DexMenu from './DexMenu';
import { formattedAmount } from "./utils";
import { ChevronRight, HouseDoor } from 'react-bootstrap-icons';
import Index from "./LiquidityBoost/Index";
import Loading from "./Components/Loading";
import PendingTransactions from "./Components/PendingTransactions";

class App2 extends App {
  constructor(props) {
    super(props);
    this.state.showIndex = true;
  }

  performAction(key) {
    if (key === "home") {
      this.setState({ showIndex: false }, () => { this.setState({ showIndex: true })});
    }
    super.performAction(key);
  }

  render() {
    if (this.state.walletStatus === undefined)
      return (<Loading className="w-50 mx-auto mt-5"/>);

    return (
      <>
        <DexMenu
          menuItems={[]}
          menuCallback={this.setSelectedMenuItem}
          actionCallback={this.performAction}
          address={this.state.address}
          balance={formattedAmount(this.state.balance)}
          currency={this.state.native_currency}
          wallet={this.state.wallet}
        />
        <Container className="">
          <div className="py-4 d-none">
            <HouseDoor size={16} className="me-2" />
            <ChevronRight size={16} className="me-2 text-muted" />
            <div className="text-primary d-inline-block lh-lg">Liquidity Boost</div>
          </div>
          {this.state.showIndex &&
            <Index
              actionCallback={this.performAction}
              balances={this.state.balances}
              wallet={this.state.wallet}
              walletStatus={this.state.walletStatus}
              address={this.state.address}
            />
          }
        </Container>
        <PendingTransactions />
      </>
    );
  }
}

export default App2;
