import React, { Component } from 'react';
import UnifiedSwap from '../Swap/UnifiedSwap';
import { Globals } from '../utils';

class Swap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  defaults() {
    let defaults = {};
    if (this.props.params === undefined)
      return defaults;

    if (this.props.params["initial-amount"] !== undefined)
      defaults.amount = parseInt(this.props.params["initial-amount"], 10);

    if (this.props.params["from-blockchain"] !== undefined) {
      let blockchain = Globals.blockchains.find(b => b.id === parseInt(this.props.params["from-blockchain"]));
      if (blockchain !== undefined)
        defaults.sourceBlockchain = blockchain;
    }

    if (defaults.sourceBlockchain !== undefined && this.props.params["from-token"] !== undefined) {
      let token = defaults.sourceBlockchain.token(this.props.params["from-token"]);
      if (token !== undefined)
        defaults.sourceToken = token;
    }

    if (this.props.params["to-blockchain"] !== undefined) {
      let blockchain = Globals.blockchains.find(b => b.id === parseInt(this.props.params["to-blockchain"]));
      if (blockchain !== undefined)
        defaults.targetBlockchain = blockchain;
    }

    if (defaults.targetBlockchain !== undefined && this.props.params["to-token"] !== undefined) {
      let token = defaults.targetBlockchain.token(this.props.params["to-token"]);
      if (token !== undefined)
        defaults.targetToken = token;
    }

    return defaults;
  }

  render() {
    return (
      <div>
        <UnifiedSwap
          wallet={this.props.wallet}
          tokens={this.props.token}
          defaults={this.defaults()}
        />
      </div>
    );
  }
}

export default Swap;
