import React from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { filterTokens } from '../utils';
import WizTokenSelectItem from './WizTokenSelectItem';

class SwapWizPickToToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      fromToken: this.props.fromToken,
      toToken: '',
      tokens: []
    };
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.setState({
      tokens: this.props.tokens.slice(0,5)
    });
  }

  setSearchTerm(event) {
    let search_term = event.target.value;
    this.setState({
      searchTerm: search_term,
      tokens: filterTokens(this.props.tokens, search_term).slice(0,5)
    });
  }

  goBack() {
    this.props.goToStep('amount');
  }

  render() {
    return (
      <div className='px-4 py-3'>
        <h2 className='text-center'>
          Great!<br/>
          What token are you swapping to?
        </h2>
        <InputGroup className='mt-4'>
          <InputGroup.Text className='bg-gray-600 text-gray-400 border-dark'>
            <Search size={16}/>
          </InputGroup.Text>
          <FormControl
            placeholder="Search for a token in your wallet"
            aria-label="Search"
            className='ps-0  bg-gray-600 text-gray-400 border-dark border-start-0'
            onChange={this.setSearchTerm}
          />
        </InputGroup>
        <div className='mt-3'>
          <WizTokenSelectItem tokens={this.state.tokens}  setTokenCallback={this.props.setToTokenCallback}/>
        </div>
        <div className='mt-4'>
          <Button className="w-100 mt-3" variant="link" onClick={this.goBack}>
            Back
          </Button>
          <br/>
        </div>
      </div>
    );
  }
}

export default SwapWizPickToToken;
