import React from 'react';
import { Table, Card, Spinner } from 'react-bootstrap';
import GraphData from '../Apis/GraphData';
import If from '../Components/If';
import Unless from '../Components/Unless';
import { formattedAmount } from '../utils.js';
import BalanceChangeBadge from './BalanceChangeBadge';

class TopWallets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true
    }
    this.api = new GraphData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokenAddress !== this.props.tokenAddress) {
      this.setState({
        loading: true
      });
      this.getData();
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.api.whaleData(this.props.chainId, this.props.tokenAddress).then((data) => {
      this.setState({
        data: data,
        loading: false
      });
    }).catch(() => { this.setState({data: []})});
  }

  percentBadgeType(percent) {
    if (percent > 5) {
      return "text-danger";
    }
    if (percent > 1) {
      return "text-warning";
    }
    return "text-yarn startsuccess";
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Top Wallets</Card.Title>
        </Card.Header>
        <Card.Body className="p-0">
          <If condition={this.state.loading}>
            <div className="text-center p-3">
              <Spinner
                animation="border"
                variant="secondary"
                style={{ width: "3rem", height: "3rem" }} />
            </div>
          </If>
          <Unless condition={this.state.loading}>
            <Table className="small mb-0" variant='dark' responsive>
              <thead>
                <tr>
                  <th className='fw-normal'>Address</th>
                  <th className='fw-normal'>Balance</th>
                  <th className='fw-normal'>Percent</th>
                  <th className='fw-normal text-end'>Change</th>
                </tr>
              </thead>
              <tbody>
                { this.state.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td onClick={(e) => this.props.setWalletCallback(item.address)} style={{cursor: 'pointer'}}>
                        <span className='text-primary'>{item.address}</span></td>
                      <td align="right">{formattedAmount(item.balance)}</td>
                      <td align="right">
                        {/* <Badge bg={this.percentBadgeType(item.percent)}>{item.percent}%</Badge> */}
                        <p className={this.percentBadgeType(item.percent)}>{item.percent}</p>
                      </td>
                      <td className='text-end'>
                        <BalanceChangeBadge change={item.last_change} changePercentage={item.balance > 0 ? item.last_change / item.balance : 0}/>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Unless>
        </Card.Body>
      </Card>);
  }
}

export default TopWallets;
