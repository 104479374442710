import React from "react";

class ChainChangeNetworkLabel extends React.Component {
  static defaultIconUrl ='https://icons.dexvers.cbix.io/default.png';
  static defaultProps = {
    className: '',
    network: undefined
  }

  networkIcon() {
    if (this.props.network.iconUrl) {
      return this.props.network.iconUrl;
    } else {
      return ChainChangeNetworkLabel.defaultIconUrl;
    }
  }

  render() {
    return (
      <div className={`small ${this.props.className}`}>
        <div className="d-flex align-items-center w-100">
          <img src={this.networkIcon()} height={18} className={this.props.expanded ? "me-2" : undefined} alt={this.props.network.name} />
          <div>{this.props.expanded && this.props.network.name}</div>
        </div>
      </div>
    );
  }
}

export default ChainChangeNetworkLabel;
