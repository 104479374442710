import { EventEmitter } from "events";
import Pool from "./Pool";
import { AnkrProvider } from "@ankr.com/ankr.js";
import { Globals } from "../utils";

class Pools extends EventEmitter {
  constructor(wallet) {
    super();
    this.wallet = wallet;
    this.list = [];
    this.map = {};
    this.loaded = false;
  }

  async load(force = false) {
    if(this.loaded && !force) return Promise.resolve();
    this.loadFromAPI();
    this.list = []
    let addresses = JSON.parse(window.localStorage.getItem(`pools-list-${Globals.address}`) || "[]");
    addresses = Array.from(new Set(addresses));
    return Promise.all(addresses.map((address) => this.loadPair(address)))
      .then(() => {
        this.emit("change");
        this.loaded = true;
        return this.list;
      });
  }

  async loadPair(address) {
    return this.wallet.getPairInfo(address)
      .then((pair_data) => {
        let pair = this.addPair(pair_data);
        return pair;
      });
  }

  save() {
    window.localStorage.setItem(`pools-list-${Globals.address}`, JSON.stringify(this.list.map((x) => x.address.toLowerCase())));
  }

  async add(address) {
    if(this.isAdded(address))
      return Promise.resolve(this.find(address));
    return this.wallet.getPairInfo(address)
      .then((pair_data) => {
        let pair = this.addPair(pair_data);
        this.emit("change");
        return pair;
      })
      .catch((e) => {});
  }

  async refresh(address, attempt = 0) {
    console.log("Pools - refreshing", address);
    return this.wallet.getPairInfo(address)
      .then((pair_data) => {
        console.log("Pools - refreshed", address, pair_data);
        this.update(pair_data);
        this.emit("change");
      })
      .catch((e) => {
        if (attempt > 5) return;
        setTimeout(() => this.refresh(address, attempt + 1), 5000);
      });
  }

  update(data) {
    if (this.isAdded(data.address)) {
      let pair = this.find(data.address);
      pair.data = data;
    } else {
      this.addPair(data);
    }
  }

  addPair(pair_data) {
    if(this.isAdded(pair_data.address))
      return this.find(pair_data.address);
    let pair = new Pool(pair_data, this.wallet);
    this.list.push(pair);
    this.save();
    return pair;
  }

  find(address) {
    return this.list.find((x) => x.address?.toLowerCase() === address?.toLowerCase());
  }

  async find_or_add(address) {
    let pool = this.find(address);
    if (pool) return Promise.resolve(pool);
    return this.add(address);
  }

  isAdded(address) {
    return this.list.filter((e) => e.address?.toLowerCase() === address?.toLowerCase()).length > 0;
  }

  remove(address) {
    this.list = this.list.filter((e) => e.address !== address);
  }

  loadFromAPI() {
    if (Globals.currentBlockchain.config.ankrName === undefined) return;
    if (window.sessionStorage.getItem(`pools-list-loaded-${Globals.address}`)) return;
    window.sessionStorage.setItem(`pools-list-loaded-${Globals.address}`, '1');
    let api = new AnkrProvider();
    api.getAccountBalance({
      blockchain:  Globals.currentBlockchain.config.ankrName,
      walletAddress: Globals.address,
      onlyWhitelisted: false,
      pageSize: 100
    }).then((data) => {
      data.assets.forEach((asset) => {
        this.add(asset.contractAddress);
      });
    });
  }
}

export default Pools;
