import React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";


class PeriodButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '1w'
    }
    this.select = this.select.bind(this);
  }

  select(event) {
    this.setState({
      selected: event.target.id
    });
    this.props.sinceCallback(event);
  }

  render () {
    return (
      <ButtonToolbar className="">
        <ButtonGroup className="">
          <Button onClick={this.select} className={"ms-2 " + (this.state.selected === '1w' ? 'active': '')} variant="outline-secondary" size="sm" id='1w'>1w</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={this.select} className={"ms-2 " + (this.state.selected === '1m' ? 'active': '')} variant="outline-secondary" size="sm" id='1m'>1m</Button>
          </ButtonGroup>
        <ButtonGroup>
          <Button onClick={this.select} className={"ms-2 " + (this.state.selected === '1y' ? 'active': '')} variant="outline-secondary" size="sm" id='1y'>1y</Button>
        </ButtonGroup>
      </ButtonToolbar>
    );
  }
}

export default PeriodButton;
