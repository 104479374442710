import { Globals, fromContractDecimals } from "../../utils";
import { formattedAmount } from "../../utils";

class InsufficientBalanceForGasError extends Error {
  constructor (cost, balance) {
    let nativeToken = Globals.currentBlockchain.config.nativeToken
    let balanceFormatted = formattedAmount(fromContractDecimals(balance, 18));
    let costFormatted = formattedAmount(fromContractDecimals(cost, 18));
    super(`Insufficient balance for gas. Account balance ${balanceFormatted} ${nativeToken}, transaction cost ${costFormatted} ${nativeToken}`)
    this.name = 'InsufficientBalanceForGasError'
    this.cost = cost;
    this.balance = balance;
    this.recovery = {
      label: `Get ${nativeToken}`,
      path: "/gassless"
    }
  }

  get insufficientBalanceError () {
    return true
  }
}

export default InsufficientBalanceForGasError;
