import React from 'react';
import { Card, CloseButton } from 'react-bootstrap';
import GraphData from '../Apis/GraphData';
import { formattedAmount } from '../utils';
import LiquidityPoolTokenInfo from './LiquidityPoolTokenInfo';

class LiquidityPoolInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    }
    this.api = new GraphData();

    this.doClose = this.doClose.bind(this);
  }

  getData() {
    this.api.liquidityPoolInfoData(this.props.lpAddress).then((data) => {
      this.setState({data: data});
    });
  }

  doClose() {
    this.props.setLPCallback('');
  }

  componentDidMount() {
    this.getData();
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Card>
        <Card.Header>
          <div className="d-flex">
            <Card.Title className='m-0'>
              Liquidity Pool Info
            </Card.Title>
            <CloseButton className='ms-auto' onClick={this.doClose} />
          </div>
        </Card.Header>
        <Card.Body>
          <Card id='name' title={this.state.data.address}>
            <Card.Header>
              <Card.Title>
                Name
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {this.state.data.name} ({this.state.data.address})
            </Card.Body>
          </Card>
          <Card id='tvl'>
            <Card.Header>
              <Card.Title>
                TVL
              </Card.Title>
            </Card.Header>
            <Card.Body>
              $ {formattedAmount(this.state.data.tvl_in_usd)}
            </Card.Body>
          </Card>
          <LiquidityPoolTokenInfo token={this.state.data.token0} balance={this.state.data.balance0} title='Token0' setTokenCallback={this.props.setTokenCallback} setLPCallback={this.props.setLPCallback}/>
          <LiquidityPoolTokenInfo token={this.state.data.token1} balance={this.state.data.balance1} title='Token1' setTokenCallback={this.props.setTokenCallback} setLPCallback={this.props.setLPCallback}/>
        </Card.Body>
      </Card>
    );
  }

}

export default LiquidityPoolInfo;
