import React from 'react';
import { Col, Card, FormControl } from 'react-bootstrap';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import ChartData from './Apis/ChartData';
import TopWallets from './Chart/TopWallets.jsx';
import TokenTransactionHistory from './Graphs/TokenTransactionHistory';
import GraphData from './Apis/GraphData';
import If from './Components/If';
import WalletInfo from './WalletInfo/WalletInfo';
import TopChartInfo from './Chart/TopChartInfo';
import LiquidityPoolList from './Chart/LiquidityPoolList';
import LiquidityPoolInfo from './Chart/LiquidityPoolInfo';
import TokenIcon from './Components/TokenIcon';
import DexversTokens from './DexversTokens';

class Charts extends React.Component {
  constructor(props) {
    super(props);
    let defaultToken = props.wallet.token(props.wallet.defaultChartsTokenAddress);
    this.state  ={
      chainId: 137,
      priceData: [],
      tvlData: [],
      pvData: [],
      tokenList: [],
      tokenOptions: [],
      showWalletAddress: '',
      showLPAddress: '',
      showTransactionHistory: false,
      selectedGraphId: 1,
      tokenAddress: defaultToken.address,
      tokenName: defaultToken.name,
      tokenSymbol: defaultToken.symbol,
      tokenPriceChangePercentage: 0,
      tokenWalletCountChangePercentage: 0,
      tokenTvlChangePercentage: 0,
      token: defaultToken
    }

    this.tokenSearch = React.createRef();

    this.api = new ChartData();
    this.graphApi = new GraphData();
    this.tokensApi = new DexversTokens();

    this.tokenSelectionChanged = this.tokenSelectionChanged.bind(this);
    this.chainChanged = this.chainChanged.bind(this);
    this.handleTokenSearch = this.handleTokenSearch.bind(this);
    this.setShowWalletAddress = this.setShowWalletAddress.bind(this);
    this.setTokenAddress = this.setTokenAddress.bind(this);
    this.setSelectedGraph = this.setSelectedGraph.bind(this);
    this.setLPAddress = this.setLPAddress.bind(this);
  }

  scannerURL() {
    let networks = this.props.wallet.crypto_networks.filter((net) => net.id === this.state.chainId);
    if (networks.length === 0) {
      return "";
    }
    let network = networks[0];
    if (network.blockExplorerUrls.length > 0) {
      return network.blockExplorerUrls[0];
    } else {
      return "";
    }
  }

  setSelectedGraph(id) {
    this.setState({
      selectedGraphId: id
    });
  }

  setShowWalletAddress(address) {
    this.setState({
      showWalletAddress: address,
    })
  }

  tokenSelectionChanged(event) {
    if (event.lentgh === 0) {
      return
    }
    let e = event[0];
    if (typeof e === 'undefined') {
      return [];
    }
    this.tokenSearch.current?.clear();
    this.setTokenAddress(e.address);
  }

  setTokenAddress(address) {
    this.fetchToken(address).then((token) => {
      this.setState({
        tokenAddress: address,
        token: token
      }, this.tokenChangeAction);
    });
  }

  async fetchToken(address) {
    let token = this.props.wallet.token(address);
    if (token)
      return Promise.resolve(token);
    return this.tokensApi.load(this.state.chainId, address)
      .then((token) => {
        console.log("Fetched token", token);
        return token;
      });
  }

  tokenChangeAction() {
    this.getTokenInfo();
  }

  setLPAddress(address) {
    this.setState({
      showLPAddress: address
    });
  }

  chainChanged(event) {
    this.setState({
      chainId: event.target.value
    }, this.updateTokens(event.target.value));
  }

  updateTokens(chainId, search='') {
    this.api.getTokens(chainId, search).then((data) => {
      this.setState({
        tokenList: data,
      })
    }).catch((error) => {
      console.log(error);
    });
  }

  tokenName(token) {
    return `${token.name} (${token.symbol})`;
  }

  handleTokenSearch(q) {
    this.updateTokens(this.state.chainId, q);
  }

  displayOverview() {
    return (this.state.showWalletAddress === '') && (this.state.showLPAddress === '');
  }

  displayWalletInfo() {
    return this.state.showWalletAddress !== '';
  }

  displayLPInfo() {
    return this.state.showLPAddress !== '';
  }

  getTokenInfo() {
    this.graphApi.tokenInfoData(0,this.state.tokenAddress).then(res => {
      this.setState({
        tokenName: res.name,
        tokenSymbol: res.symbol,
        tokenTvlChangePercentage: res.last_tvl_change_percent,
        tokenPriceChangePercentage: res.last_quote_change_percent,
        tokenWalletCountChangePercentage: res.last_wallet_count_change_percent
      })
    });
  }

  render() {
    return(
      <>
        <div className="d-md-flex align-items-center flex-column flex-md-row rounded-3 bg-secondary bg-opacity-35 px-3 py-2 p-lg-3 mb-5">
          <div className='d-flex'>
            <TokenIcon token={this.state.token} size={56} />
            <div className='ms-3 mt-1'>
              <div className="text-gray-100">{this.state.token.name}</div>
              <div className="text-gray-400 small">{this.state.token.symbol}</div>
            </div>
          </div>

          <div className="ms-auto mt-3 mt-md-0">
            <Typeahead
              id="tokenSearch"
              ref={this.tokenSearch}
              onChange={this.tokenSelectionChanged}
              options={this.props.tokens}
              placeholder="Search"
              labelKey="name"
              filterBy={['name', 'symbol', 'address']}
              maxResults={10}
              paginate={true}
              paginationText="Load more ..."
              clearButton={true}
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <FormControl
                  aria-label="Search"
                  {...inputProps}
                  className='bg-gray-600 text-gray-400 border-dark'
                  ref={(input) => {
                    inputRef(input);
                    referenceElementRef(input);
                  }}
                />
              )}
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps} className="dropdown-menu bg-gray-600 px-0">
                  {results.map((result, index) => (
                    <>
                      <MenuItem option={result} position={index} className="small">
                        <div className="d-flex">
                          {result.paginationOption &&
                            <div className="text-gray-100">{menuProps.paginationText}</div>
                          }
                          {!result.paginationOption &&
                            <>
                              <TokenIcon token={result} size={16} className="me-2 my-auto" />
                              <div className="text-gray-100">{result.name}</div>
                              &nbsp;
                              <div className="text-gray-400">({result.symbol})</div>
                            </>
                          }
                          </div>
                        </MenuItem>
                    </>
                  ))}
                </Menu>
              )}
            />
          </div>
        </div>

        <If condition={this.displayWalletInfo()}>
          <WalletInfo
            chainId={this.state.chainId}
            walletAddress={this.state.showWalletAddress}
            setWalletCallback={this.setShowWalletAddress}
            setTokenCallback={this.setTokenAddress}
            explorerUrl={this.scannerURL()}
            />
        </If>
        <If condition={this.displayLPInfo()}>
          <LiquidityPoolInfo chainId={this.state.chainId} lpAddress={this.state.showLPAddress} setLPCallback={this.setLPAddress} setTokenCallback={this.setTokenAddress}/>
        </If>

        <div className="mb-4" style={{visibility: this.displayOverview() ? 'visible' : 'hidden'}}>
          <TopChartInfo
            chainId={this.state.chainId}
            tokenAddress={this.state.tokenAddress}
            priceChange={this.state.tokenPriceChangePercentage}
            walletChange={this.state.tokenWalletCountChangePercentage}
            tvlChange={this.state.tokenTvlChangePercentage}
            />
        </div>

        <div className="mb-4" style={{visibility: this.displayOverview() ? 'visible' : 'hidden'}}>
          <LiquidityPoolList chainId={this.state.chainId} tokenAddress={this.state.tokenAddress} setLPCallback={this.setLPAddress}/>
        </div>

        <div className="mb-4" style={{visibility: this.displayOverview() ? 'visible' : 'hidden'}}>
          <Col className='col-12'>
            <TopWallets chainId={this.state.chainId} tokenAddress={this.state.tokenAddress} setWalletCallback={this.setShowWalletAddress}/>
          </Col>
        </div>

        <div style={{visibility: this.displayOverview() ? 'visible' : 'hidden'}}>
          <Col className='col-12'>
            <Card>
              <Card.Header>
                <Card.Title>Recent Transactions</Card.Title>
              </Card.Header>
              <Card.Body className='p-0'>
                <TokenTransactionHistory
                  chainId={this.state.chainId}
                  tokenAddress={this.state.tokenAddress}
                  wallet={this.props.wallet}
                  setWalletCallback={this.setShowWalletAddress}
                  explorerUrl={this.scannerURL()}
                  />
              </Card.Body>
            </Card>
          </Col>
        </div>
      </>

    );
  }
}
export default Charts;
