import { BigNumber } from "bignumber.js/bignumber.js";
import { Globals } from "../utils";
import { create } from '@connext/sdk';

class XEstimator {
  constructor(value, sourceBlockchain, sourceToken, proxySourceToken, destinationBlockchain, destinationToken, proxyDestinationToken) {
    this.inputAmount = value;
    this.crosschainAmount = value;
    this.sourceBlockchain = sourceBlockchain;
    this.sourceToken = sourceToken;
    this.proxySourceToken = proxySourceToken;
    this.destinationBlockchain = destinationBlockchain;
    this.destinationToken = destinationToken;
    this.proxyDestinationToken = proxyDestinationToken;
  }

  static async estimateAmountOut(value, sourceBlockchain, sourceToken, proxySourceToken, destinationBlockchain, destinationToken, proxyDestinationToken) {
    let estimator = new XEstimator(value, sourceBlockchain, sourceToken, proxySourceToken, destinationBlockchain, destinationToken, proxyDestinationToken);
    return estimator.estimateAmountOut();
  }

  async getGasPrice() {
    return this.sourceBlockchain.web3.eth.getGasPrice()
      .then((gasPrice) => {
        this.gasPrice = gasPrice;
      });
  }

  computeGasCost() {
    let crosschainGas = this.sourceBlockchain.costs.crosschain;
    let gasCost = new BigNumber(crosschainGas).times(this.gasPrice);
    let divisor = BigNumber(Math.pow(10, 18));
    return gasCost.dividedBy(divisor).toNumber();
  }

  async estimateAmountOut() {
    return this.getGasPrice()
      .then(() => this.estimateSourceChainSwap({}))
      .then((estimate) => this.estimateCrossChainSwap(estimate))
      .then((estimate) => this.estimateDestinationChainSwap(estimate))
      .then((estimate) => this.resolve(estimate));
  }

  async estimateSourceChainSwap(estimate) {
    console.log("estimateSourceChainSwap", estimate);
    if (this.proxySourceToken === undefined) {
      estimate.src = {
        skip: true,
        amountIn: this.inputAmount,
        amount: this.inputAmount,
        slippage: this.inputAmount,
        gas: 0
      };
      return estimate;
    }

    return this.sourceBlockchain.estimateAmountOut(this.inputAmount, this.sourceToken.address, this.proxySourceToken.address)
      .then((est) => {
        est.amountIn = this.inputAmount;
        if  (est.amount === undefined) {
          est.amount = 0;
          est.failed = true;
        }
        estimate.src = est;
        return estimate;
      });
  }

  async estimateCrossChainSwap(estimate) {
    let relayConfig = {
      originDomain: this.sourceBlockchain.config.connextDomainID,
      destinationDomain: this.destinationBlockchain.config.connextDomainID,
      isHighPriority: true
    }
    let sdk = await this.connextSdk();
    return sdk.sdkBase.estimateRelayerFee(relayConfig).then((fee) => {
      let feeBN = new BigNumber(fee._hex, 16);
      let divisor = BigNumber(Math.pow(10, 18));
      let relayerFee = feeBN.dividedBy(divisor).toNumber();
      estimate.cross = {
        amountIn: estimate.src.amount,
        dexversFee: this.computeDexversFee(estimate.src.amount),
        routerFee: this.computeRouterFee(estimate.src.amount),
        relayerFee: relayerFee,
        gas: this.computeGasCost()
      }
      estimate.cross.amount = estimate.cross.amountIn - estimate.cross.dexversFee - estimate.cross.routerFee;
      estimate.cross.slippage = estimate.cross.amount;
      return estimate;
    });
  }

  async estimateDestinationChainSwap(estimate) {
    if (this.proxyDestinationToken === undefined) {
      estimate.dst = {
        skip: true,
        amountIn: estimate.cross.amount,
        amount: estimate.cross.amount,
        slippage: estimate.cross.amount,
        gas: 0
      };
      return estimate;
    }

    return this.destinationBlockchain.estimateAmountOut(estimate.cross.amount, this.proxyDestinationToken.address, this.destinationToken.address)
      .then((est) => {
        est.amountIn = estimate.cross.amount;
        if  (est.amount === undefined) {
          est.amount = 0;
          est.failed = true;
        }
        estimate.dst = est;
        return estimate;
      });
  }

  async resolve(estimate) {
    return estimate;
  }

  connextSdk() {
    let chains = {};
    for (let i in Globals.blockchains) {
      let chain = Globals.blockchains[i];
      if (chain.config.connextDomainID)
        chains[chain.config.connextDomainID] = { providers: [chain.config.urls.rpc] }
    }
    let config = {
      signerAddress: Globals.address,
      network: 'mainnet',
      chains: chains,
      loglevel: 'debug',
    }
    return create(config);
  }

  computeDexversFee(amount) {
    return amount * 0.0005;
  }

  computeRouterFee(amount) {
    return amount * 0.0005;
  }

}

export default XEstimator;
