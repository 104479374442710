import { Button, Card } from 'react-bootstrap';
import React from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';

class NoBalance extends React.Component {
  render() {
    return (
      <>
        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-4 border border-secondary mt-3">
          <div className="bg-opacity-100 px-3">
            <div className='text-center fs-5 text-gray-100 fw-bolder mb-4'>
              Currently Dexvers only accepts USDC token for our Liquidity Bootstrapping program.
            </div>
            <p className="text-center text-gray-400 small mb-4">
              Add some USDC tokens to your wallet and refresh. Alternatively, if your USDC is in
              a different wallet, connect your wallet below.
            </p>
            <Button onClick={this.props.refresh} className="w-100">
              <ArrowClockwise size={16} className="me-2" />
              Refresh Your Connected Wallet
            </Button>
          </div>
        </div>

        <Card className="mt-4">
        <Card.Header className='border-bottom border-secondary'>
          <Card.Title>Staked Positions</Card.Title>
          <Card.Subtitle className="text-gray-400">Track and manage your farm stakes</Card.Subtitle>
        </Card.Header>
        <Card.Body className="py-0">
          <div className="py-5 text-center">
          No staked positions yet
          </div>
        </Card.Body>
      </Card>

      </>
    );
  }
}

export default NoBalance;
