import React from 'react';
import {Button, Spinner, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import BlockchainSelector from '../Components/BlockchainSelector';
import TokenSelectForSwap from '../TokenSelectForSwap';
import { Globals, fromContractDecimalsToNumber } from '../utils';
import XSwapper from '../Wallet/XSwapper';
import SwapFees from './SwapFees';
import Estimator from '../Wallet/Estimator';
import XEstimator from '../Wallet/XEstimator';
import Swapper from '../Wallet/Swapper';
import { ChevronExpand, PlusCircle, DashCircle } from 'react-bootstrap-icons';
import crosschain from '../Config/crosschain';


class UnifiedSwap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceBlockchains: Globals.blockchains,
      destinationBlockchains: [],
      sourceBlockchain: Globals.currentBlockchain,
      destinationBlockchain: Globals.currentBlockchain,
      sourceTokens: [],
      destinationTokens: [],
      sourceToken: undefined,
      sourceToken2: undefined,
      sourceToken3: undefined,
      proxySourceToken: undefined,
      proxySourceToken2: undefined,
      proxySourceToken3: undefined,
      destinationToken: undefined,
      proxyDestinationToken: undefined,
      crosschain: false,
      inValue: 0,
      inValue2: 0,
      inValue3: 0,
      outValue: 0,
      estimate: {},
      estimates: [],
      fees: {},
      estimating: false,
      inputValid: true,
      canTrade: false,
      payFeesWith: 'native',
      payFeesToken: undefined,
      payFeesAmount: 0,
      canPayFees: false,
      multiToOne: false,
      showFromTokensNo: 1,
      showPrice: false,
      price1: 0,
      price2: 0,
      price3: 0
    }

    this.inputsValidity = { 1: true, 2: true, 3: true };

    this.setSourceBlockchain = this.setSourceBlockchain.bind(this);
    this.setDestinationBlockchain = this.setDestinationBlockchain.bind(this);
    this.setFromToken = this.setFromToken.bind(this);
    this.setFromToken2 = this.setFromToken2.bind(this);
    this.setFromToken3 = this.setFromToken3.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.setInValue = this.setInValue.bind(this);
    this.setInValue2 = this.setInValue2.bind(this);
    this.setInValue3 = this.setInValue3.bind(this);
    this.setOutValue = this.setOutValue.bind(this);
    this.switchDirection = this.switchDirection.bind(this);
    this.doSwap = this.doSwap.bind(this);
    this.setPayFeesWith = this.setPayFeesWith.bind(this);
    this.setPayFeesToken = this.setPayFeesToken.bind(this);
    this.addFromToken = this.addFromToken.bind(this);
    this.removeFromToken = this.removeFromToken.bind(this);
  }

  async setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve))
      // .then(() => console.log("New State: ", this.state));
  }

  get destinationBlockchains() {
    return Globals.blockchains.filter((blockchain) => {
      return this.state.sourceBlockchain.id === blockchain.id
             || (
              this.state.sourceBlockchain.config.contracts.crosschain !== undefined &&
              blockchain.config.connextDomainID !== undefined
             );
    });
  }

  get sourceTokens() {
    let tokens = this.state.sourceBlockchain.tokens.items;
    if (this.state.crosschain) {
      tokens = tokens.filter((token) => {
        return !token.native;
      });
    }
    return tokens;
  }

  get destinationTokens() {
    let tokens = this.state.destinationBlockchain.tokens.items;
    if (this.state.crosschain) {
      tokens = tokens.filter((token) => {
        return !token.native;
      });
    }
    return tokens;
  }


  componentDidMount() {
    let promise = this.setStateAsync({ sourceTokens: this.sourceTokens })
      .then(() => this.setStateAsync({ destinationBlockchains: this.destinationBlockchains }))
      .then(() => this.setStateAsync({ destinationTokens: this.destinationTokens }))
      .then(() => this.setFromToken(this.state.sourceTokens[0].address))
      // devmode: inchain swap default token to TetherUSD
      // .then(() => this.setToToken("0xc2132d05d31c914a87c6611c10748aeb04b58e8f"))

      // devmode: crosschain swap miMatic(polygon) to TetherUSD(bsc)
      // .then(() => this.setFromToken("0x2791bca1f2de4661ed88a30c99a7a9449aa84174"))
      // .then(() => this.addFromToken())
      // .then(() => this.setFromToken2("0xc2132d05d31c914a87c6611c10748aeb04b58e8f"))
      // .then(() => this.setInValue(1.5))
      // .then(() => this.setInValue2(1.3))
      // .then(() => this.setDestinationBlockchain(this.destinationBlockchains[1]))
      // .then(() => this.setToToken("0xe9e7cea3dedca5984780bafc599bd69add087d56"))

    let defaults = this.props.defaults || {};

    // TODO: handle default source chain

    if (defaults.sourceToken)
      promise = promise.then(() => this.setFromToken(defaults.sourceToken.address));

    if (defaults.targetBlockchain)
      promise = promise.then(() => this.setDestinationBlockchain(defaults.targetBlockchain));

    if (defaults.targetToken)
      promise = promise.then(() => this.setToToken(defaults.targetToken.address));

    if (defaults.amount)
      promise = promise.then(() => this.setInValue(defaults.amount));
    return promise;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.sourceToken !== this.state.sourceToken && this.props.setFromTokenCallback !== undefined)
      this.props.setFromTokenCallback(this.state.sourceToken);
    if(prevState.destinationToken !== this.state.destinationToken && this.props.setToTokenCallback !== undefined)
      this.props.setToTokenCallback(this.state.destinationToken);
  }

  setSourceBlockchain(blockchain) {
    Globals.wallet.changeNetwork(blockchain.id);
  }

  async setDestinationBlockchain(blockchain) {
    return this.setStateAsync({
      destinationBlockchain: blockchain,
      crosschain: this.state.sourceBlockchain.id !== blockchain.id,
      outValue: 0
    })
      .then(() => {
        this.setStateAsync({
          destinationTokens: this.destinationTokens,
          destinationToken: undefined
        })
      })
      .then(() => this.setFromToken(this.state.sourceToken.address));
  }

  async setFromToken(address) {
    let token = this.state.sourceBlockchain.token(address);
    let proxyToken = undefined;
    if (this.state.crosschain && !token.crosschainable) {
      let crosschainToken = crosschain.tokens.find((map) => {
        return map.hasOwnProperty(this.state.sourceBlockchain.label) && map.hasOwnProperty(this.state.destinationBlockchain.label);
      });
      proxyToken = this.state.sourceBlockchain.token(crosschainToken[this.state.sourceBlockchain.label]);
    }

    return this.setStateAsync({ sourceToken: token, proxySourceToken: proxyToken })
      .then(() => this.estimateAmountOut())
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
  }

  async setFromToken2(address) {
    console.log("setFromToken2", address);
    let token = this.state.sourceBlockchain.token(address);
    let proxyToken = undefined;
    if (this.state.crosschain && !token.crosschainable) {
      let crosschainToken = crosschain.tokens.find((map) => {
        return map.hasOwnProperty(this.state.sourceBlockchain.label) && map.hasOwnProperty(this.state.destinationBlockchain.label);
      });
      proxyToken = this.state.sourceBlockchain.token(crosschainToken[this.state.sourceBlockchain.label]);
    }

    return this.setStateAsync({ sourceToken2: token, proxySourceToken2: proxyToken })
      .then(() => this.estimateAmountOut())
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
  }

  async setFromToken3(address) {
    console.log("setFromToken2", address);
    let token = this.state.sourceBlockchain.token(address);
    let proxyToken = undefined;
    if (this.state.crosschain && !token.crosschainable) {
      let crosschainToken = crosschain.tokens.find((map) => {
        return map.hasOwnProperty(this.state.sourceBlockchain.label) && map.hasOwnProperty(this.state.destinationBlockchain.label);
      });
      proxyToken = this.state.sourceBlockchain.token(crosschainToken[this.state.sourceBlockchain.label]);
    }

    return this.setStateAsync({ sourceToken3: token, proxySourceToken3: proxyToken })
      .then(() => this.estimateAmountOut())
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
  }


  async setToToken(address) {
    let token = this.state.destinationBlockchain.token(address);
    let proxyToken = undefined;
    if (this.state.crosschain && !token.crosschainable) {
      let crosschainToken = crosschain.tokens.find((map) => {
        return map.hasOwnProperty(this.state.sourceBlockchain.label) && map.hasOwnProperty(this.state.destinationBlockchain.label);
      });
      proxyToken = this.state.destinationBlockchain.token(crosschainToken[this.state.destinationBlockchain.label]);
    }

    return this.setStateAsync({ destinationToken: token, proxyDestinationToken: proxyToken })
      .then(() => this.estimateAmountOut())
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }));
  }

  setInValue(val) {
    this.setStateAsync({ inValue: val })
      .then(() => this.estimateAmountOut())
      .then(() => this.setState({ canTrade: this.canTrade() }));
  }

  setInValue2(val) {
    this.setStateAsync({ inValue2: val })
      .then(() => this.estimateAmountOut())
      .then(() => this.setState({ canTrade: this.canTrade() }));
  }

  setInValue3(val) {
    this.setStateAsync({ inValue3: val })
      .then(() => this.estimateAmountOut())
      .then(() => this.setState({ canTrade: this.canTrade() }));
  }

  setOutValue(val) {
    this.setStateAsync({ outValue: val })
      .then(() => this.estimateAmountIn())
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }));
  }

  inputValidityChanged(index) {
    return function(valid) {
      this.inputsValidity[index] = valid;
      let isValid = this.inputsValidity[1] && (this.inputsValidity[2] || this.state.showFromTokensNo < 2) && (this.inputsValidity[3] || this.state.showFromTokensNo < 3);
      console.log("Inputs Validity: ", isValid, this.inputsValidity, this.state.showFromTokensNo);
      this.setState({ inputValid: isValid });
    }.bind(this);
  }

  switchDirection() {
    if (this.state.sourceBlockchain.id !== this.state.destinationBlockchain.id) {
      Globals.wallet.changeNetwork(this.state.destinationBlockchain.id);
    } else {
      this.setStateAsync({
        sourceToken: this.state.destinationToken,
        destinationToken: this.state.sourceToken,
        outValue: 0
      })
        .then(() => this.estimateAmountOut())
        .then(() => this.setState({ canTrade: this.canTrade() }))
    }
  }

  get canEstimate() {
    return this.state.sourceToken !== undefined &&
      this.state.destinationToken !== undefined &&
      (this.state.showFromTokensNo < 2 || this.state.sourceToken2 !== undefined) &&
      (this.state.showFromTokensNo < 3 || this.state.sourceToken3 !== undefined) &&
      (this.state.inValue > 0 || this.state.outValue > 0) &&
      !this.sameTokenSwap();
  }

  sameTokenSwap() {
    if (this.state.destinationToken === undefined) return false;

    return (
        this.state.sourceToken !== undefined &&
        this.state.sourceToken.address.toLowerCase() === this.state.destinationToken.address.toLowerCase()
      ) || (
        this.state.showFromTokensNo >= 2 &&
        this.state.sourceToken2 !== undefined &&
        this.state.sourceToken2.address.toLowerCase() === this.state.destinationToken.address.toLowerCase()
      ) || (
        this.state.showFromTokensNo >= 3 &&
        this.state.sourceToken3 !== undefined &&
        this.state.sourceToken3.address.toLowerCase() === this.state.destinationToken.address.toLowerCase()
      );
  }

  canTrade() {
    return this.state.sourceToken !== undefined &&
      this.state.destinationToken !== undefined &&
      this.state.inValue > 0 &&
      this.state.outValue > 0 &&
      this.state.inputValid;
  }

  async updatePrices() {
    this.setStateAsync({ price1: 0, price2: 0, price3: 0 });
    this.state.estimates.forEach((estimate, index) => {
      let price = estimate.amountOut / estimate.amountIn;
      price = isNaN(price) ? 0 : price;
      if (index === 0) {
        this.setStateAsync({ price1: price });
      } else if (index === 1) {
        this.setStateAsync({ price2: price });
      } else if (index === 2) {
        this.setStateAsync({ price3: price });
      }
    });
  }

  async estimateAmountOut() {
    await this.setStateAsync({
      estimate: {},
      estimates: [],
      fees: {},
      estimating: true,
      canTrade: false,
      outValue: 0
    });
    if (!this.canEstimate) {
      return this.setStateAsync({ estimating: false });
    }

    let promise =  this.state.crosschain ? this.crosschainEstimateAmountOut() : this.inchainEstimateAmountOut();
    return promise
      .then(() => this.updatePrices())
      .then(() => this.updateCanPayFees() );
  }

  async crosschainEstimateAmountOut() {
    let promises = [];
    promises.push(XEstimator.estimateAmountOut(
      this.state.inValue,
      this.state.sourceBlockchain, this.state.sourceToken, this.state.proxySourceToken,
      this.state.destinationBlockchain, this.state.destinationToken, this.state.proxyDestinationToken
    ));

    if (this.state.sourceToken2) {
      promises.push(XEstimator.estimateAmountOut(
        this.state.inValue2,
        this.state.sourceBlockchain, this.state.sourceToken2, this.state.proxySourceToken2,
        this.state.destinationBlockchain, this.state.destinationToken, this.state.proxyDestinationToken
      ));
    }

    if (this.state.sourceToken3) {
      promises.push(XEstimator.estimateAmountOut(
        this.state.inValue3,
        this.state.sourceBlockchain, this.state.sourceToken3, this.state.proxySourceToken3,
        this.state.destinationBlockchain, this.state.destinationToken, this.state.proxyDestinationToken
      ));
    }

    return Promise.allSettled(promises).then((estimates) => {
      let final_estimate = {
        cross: {
          amount: 0,
          amountIn: 0,
          dexversFee: 0,
          gas: 0,
          relayerFee: 0,
          routerFee: 0,
          slippage: 0,
        },
        dst: {
          amount: 0,
          amountIn: 0,
          gas: 0,
          impact: 0,
          slippage: 0,
        },
        src: {
          amount: 0,
          amountIn: 0,
          gas: 0,
          slippage: 0,
        },
        amountOut: 0,
        amountOutAfterSlippage: 0,
      }
      let all_estimates = [];
      console.log("Crosschain estimates:", estimates);

      estimates.forEach((ret_estimate, index) => {
        let estimate = ret_estimate.value;
        // normalize values
        estimate.amountOut = estimate.dst.amount;
        estimate.amountOutAfterSlippage = estimate.dst.slippage;

        // accumulate amount out
        final_estimate.amountOut += estimate.dst.amount;
        final_estimate.amountOutAfterSlippage += estimate.dst.slippage;

        // accumulate src
        final_estimate.src.gas += estimate.src.gas || 0;

        // accumulate cross
        final_estimate.cross.relayerFee += estimate.cross.relayerFee || 0;
        final_estimate.cross.gas += estimate.cross.gas || 0;

        // accumulate dst
        final_estimate.dst.amount += estimate.dst.amount || 0;
        final_estimate.dst.slippage += estimate.dst.slippage || 0;
        final_estimate.dst.gas += estimate.dst.gas || 0;

        if (index === 0) {
          final_estimate.cross.amountIn = estimate.cross.amountIn;
          final_estimate.cross.amount = estimate.cross.amount;
          final_estimate.cross.dexversFee = estimate.cross.dexversFee;
          final_estimate.cross.routerFee = estimate.cross.routerFee;
          final_estimate.cross.slippage = estimate.cross.slippage;
        }

        // store estimate
        all_estimates.push(estimate);
      });
      return this.setStateAsync({ estimate: final_estimate, estimates: all_estimates, fees: this.fees(final_estimate, all_estimates), estimating: false, outValue: final_estimate.dst.amount })
    });
  }

  async inchainEstimateAmountOut() {
    let promises = [];
    promises.push(Estimator.estimateAmountOut(
      this.state.inValue,
      this.state.sourceToken.address,
      this.state.destinationToken.address
    ));
    if (this.state.sourceToken2 !== undefined) {
      let second = Estimator.estimateAmountOut(
        this.state.inValue2,
        this.state.sourceToken2.address,
        this.state.destinationToken.address
      );
      promises.push(second);
    }
    if (this.state.sourceToken3 !== undefined) {
      let third = Estimator.estimateAmountOut(
        this.state.inValue3,
        this.state.sourceToken3.address,
        this.state.destinationToken.address
      );
      promises.push(third);
    }

    return Promise.allSettled(promises)
      .then((estimates) => {
        let final_estimate = {
          amountIn: this.state.inValue,
          amountOut: 0,
          amountOutAfterSlippage: 0,
          gas: 0
        };
        let all_estimates = [];
        console.log("Inchain estimates:", estimates);
        estimates.forEach((ret_estimate, index) => {
          let estimate = ret_estimate.value;
          estimate.amountOut = estimate.amount;
          estimate.amountOutAfterSlippage = estimate.slippage;
          if (estimate.hasOwnProperty('amount') && estimate.amount) {
            final_estimate.amountOut += estimate.amount;
            final_estimate.amountOutAfterSlippage += estimate.slippage;
            final_estimate.gas += estimate.gas;
            if (index === 0) {
              final_estimate.impact = estimate.impact;
              estimate.amountIn = this.state.inValue;
            } else if (index === 1) {
              estimate.amountIn = this.state.inValue2;
              final_estimate.impact = undefined;
            } else if (index === 2) {
              estimate.amountIn = this.state.inValue3;
            }
          }
          all_estimates.push(estimate);
        });
        console.log("Inchain final estimate:", final_estimate);
        return this.setStateAsync({ estimate: final_estimate, estimates: all_estimates, fees: this.fees(final_estimate, all_estimates), estimating: false, outValue: final_estimate.amountOut })
    });
  }

  async estimateAmountIn() {
    await this.setStateAsync({
      estimate: {},
      estimates: [],
      fees: {},
      estimating: true,
      canTrade: false,
      inValue: 0
    });
    if (!this.canEstimate) return;

    return Estimator.estimateAmountIn(
      this.state.outValue,
      this.state.sourceToken.address,
      this.state.destinationToken.address
    )
      .then((estimate) => {
        estimate.amountIn = estimate.amount;
        estimate.amountOut = this.state.outValue;
        estimate.amountOutAfterSlippage = estimate.slippage;
        delete estimate.amount;
        return this.setStateAsync({ estimate: estimate, estimates: [estimate], fees: this.fees(estimate, []), estimating: false, inValue: estimate.amountIn })
      })
      .then(() => this.updateCanPayFees() )
   }

  doSwap() {
    // let swapper = this.state.crosschain
    //               ? this.crosschainSwapper()
    //               : this.inchainSwapper();
    // swapper.prepare();
    // Globals.pendingTransactions.add(swapper.transaction);
    // swapper.execute();

    let swappers = [];
    if (this.state.crosschain) {
      swappers.push(this.crosschainSwapper(this.state.sourceToken, this.state.proxySourceToken, this.state.estimates[0], this.feesPayment));
      if (this.state.sourceToken2) {
        swappers.push(this.crosschainSwapper(this.state.sourceToken2, this.state.proxySourceToken2, this.state.estimates[1], { type: 'native' }));
      }
      if (this.state.sourceToken3) {
        swappers.push(this.crosschainSwapper(this.state.sourceToken3, this.state.proxySourceToken3, this.state.estimates[2], { type: 'native' }));
      }
    } else {
      swappers.push(this.inchainSwapper(this.state.sourceToken, this.state.estimates[0], this.feesPayment));
      if (this.state.sourceToken2) {
        swappers.push(this.inchainSwapper(this.state.sourceToken2, this.state.estimates[1], { type: 'native' }));
      }
      if (this.state.sourceToken3) {
        swappers.push(this.inchainSwapper(this.state.sourceToken3, this.state.estimates[2], { type: 'native' }));
      }
    }
    console.log("Swappers: ", swappers);
    swappers.forEach((swapper) => {
      swapper.prepare();
      Globals.pendingTransactions.add(swapper.transaction);
      swapper.execute();
    });
  }

  crosschainSwapper(token, proxySourceToken, estimate, feesPayment) {
    return new XSwapper(
      estimate.src.amountIn,
      this.state.sourceBlockchain,
      token,
      proxySourceToken,
      this.state.destinationBlockchain,
      this.state.destinationToken,
      this.state.proxyDestinationToken,
      estimate,
      this.feesPayment
    );
  }

  inchainSwapper(token, estimate, feesPayment) {
    return new Swapper(
      Globals.wallet,
      estimate.amountIn,
      estimate.amountOutAfterSlippage,
      token.address,
      this.state.destinationToken.address,
      estimate.path,
      estimate.routerAddress,
      feesPayment
    );
  }

  fees(estimate, all_estimates) {
    return this.state.crosschain ? this.crosschainFees(estimate, all_estimates) : this.inchainFees(estimate, all_estimates);
  }

  crosschainFees(estimate, all_estimates) {
    console.log("Crosschain: ",estimate);
    let fees = {
      output: {
        amount: estimate.amountOut,
        token: this.state.destinationToken
      },
      outputAfterSlippage: {
        amount: estimate.amountOutAfterSlippage,
        token: this.state.destinationToken
      },
      impact: undefined,
      transaction: {
        amount: estimate.cross.dexversFee || 0 + estimate.cross.routerFee || 0,
        token: this.state.proxySourceToken || this.state.sourceToken
      },
      network: {
        amount: estimate.src.gas + estimate.cross.relayerFee + estimate.cross.gas + estimate.dst.gas,
        token: { symbol: this.state.sourceBlockchain.config.nativeToken }
      }
    }
    if (this.state.showFromTokensNo >= 2) {
      let token = this.state.proxySourceToken2 || this.state.sourceToken2;
      let estimate2 = all_estimates[1];
      let amount = estimate2.cross.dexversFee || 0 + estimate2.cross.routerFee || 0;
      fees.transaction2 = {
        amount: amount,
        token: token
      }
    }
    if (this.state.showFromTokensNo >= 3) {
      let token = this.state.proxySourceToken3 || this.state.sourceToken3;
      let estimate3 = all_estimates[2];
      let amount = estimate3.cross.dexversFee || 0 + estimate3.cross.routerFee || 0;
      fees.transaction3 = {
        amount: amount,
        token: token
      }
    }
    return fees;
  }

  inchainFees(estimate, all_estimates) {
    let fees = {
      output: {
        amount: estimate.amountOut,
        token: this.state.destinationToken
      },
      outputAfterSlippage: {
        amount: estimate.amountOutAfterSlippage,
        token: this.state.destinationToken
      },
      impact: estimate.impact,
      transaction: {
        amount: 0,
        token: undefined
      },
      network: {
        amount: estimate.gas,
        token: { symbol: this.state.sourceBlockchain.config.nativeToken }
      }
    };
    return fees;
  }

  updateCanPayFees() {
    if (this.state.payFeesWith === "native") {
      if (Globals.currentBlockchain.tokens.native?.balance !== undefined) {
        let balance = fromContractDecimalsToNumber(Globals.currentBlockchain.tokens.native.balance);
        console.log("CanPay: ", this.state);
        if (balance < this.state.fees.network.amount) {
          this.setStateAsync({ payFeesWith: 'other' })
            .then(() => this.updateCanPayFees());
          return;
        }
      }
      this.setState({ canPayFees: this.canPayFees() });
    } else {
      this.setPayFeesToken(this.state.payFeesToken?.address);
    }
  }

  async setPayFeesWith(val) {
    await this.setStateAsync({ payFeesWith: val, canPayFees: false });
    this.updateCanPayFees();
  }

  async setPayFeesToken(address) {
    if (address === undefined)
      return;
    let token = this.state.sourceBlockchain.token(address);
    this.setState({ payFeesToken: token, payFeesAmount: 0, canPayFees: false });
    return Estimator.dexvers(this.state.sourceBlockchain)
      .set(0, this.state.fees.network.amount * 1.25, token.address, this.state.sourceBlockchain.tokens.native.address)
      .estimateAmountIn()
      .then((estimate) => this.setStateAsync({ payFeesAmount: estimate.amount }))
      .then(() => this.setStateAsync({ canPayFees: this.canPayFees() }) )
      .catch((error) => console.log("Error estimating input amount for fees", error));
  }

  canPayFees() {
    if (this.state.payFeesWith === "native") {
      let balance = fromContractDecimalsToNumber(this.state.sourceBlockchain.tokens.native.balance, this.state.sourceBlockchain.tokens.native.decimals);
      return balance >= this.state.fees.network.amount;
    } else {
      let balance = fromContractDecimalsToNumber(this.state.payFeesToken.balance, this.state.payFeesToken.decimals);
      return balance >= this.state.payFeesAmount;
    }
  }

  get feesPayment() {
    if (this.state.payFeesWith === "native") {
      return { type: 'native' };
    } else {
      return { type: 'token', token: this.state.payFeesToken, amount: this.state.payFeesAmount };
    }
  }

  addFromToken() {
    console.log("addFromToken");
    if (this.state.showFromTokensNo > 2)
      return;

    this.setState({
      showFromTokensNo: this.state.showFromTokensNo + 1,
      multiToOne: true
    })
  }

  removeFromToken() {
    if (this.state.showFromTokensNo === 1)
      return;

    this.setState({
      showFromTokensNo: this.state.showFromTokensNo - 1,
      multiToOne: (this.state.showFromTokensNo - 1) > 1
    });
    this.estimateAmountOut();
  }

  render() {
    return(
      <>
        <div>
          <div className="d-flex">
            <BlockchainSelector
              className="flex-fill"
              blockchains={this.state.sourceBlockchains}
              selected={this.state.sourceBlockchain}
              onSelect={this.setSourceBlockchain}
            />
            {/* {this.props.toggleShowCharts !== undefined &&
              <>
                <Button variant="secondary" className="ms-2 rounded-3 bg-gray-700 text-white" onClick={this.props.toggleShowCharts}>
                  <GraphUpArrow />
                </Button>
              </>
            } */}
          </div>

          <div className={"position-relative " + this.props.className}>
            <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch mt-2">
              <div className="rounded-3 border border-3 border-fuchsia me-3"></div>
              <TokenSelectForSwap
                key="input"
                className="flex-fill"
                balances={this.state.sourceTokens}
                token={this.state.sourceToken}
                value={this.state.inValue}
                setTokenCallback={this.setFromToken}
                setValueCallback={this.setInValue}
                validate={true}
                onValidityChanged={this.inputValidityChanged(1)}
                showPrice={this.state.showPrice}
                price={this.state.price1}
              />
            </div>
            {(this.state.showFromTokensNo > 1) &&
              <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch mt-2">
                <div className="rounded-3 border border-3 border-fuchsia me-3"></div>
                <TokenSelectForSwap
                  key="input2"
                  className="flex-fill"
                  balances={this.state.sourceTokens}
                  token={this.state.sourceToken2}
                  value={this.state.inValue2}
                  setTokenCallback={this.setFromToken2}
                  setValueCallback={this.setInValue2}
                  validate={true}
                  onValidityChanged={this.inputValidityChanged(2)}
                  showPrice={this.state.showPrice}
                  price={this.state.price2}
                />
              </div>
            }
            {(this.state.showFromTokensNo > 2) &&
              <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch mt-2">
                <div className="rounded-3 border border-3 border-fuchsia me-3"></div>
                <TokenSelectForSwap
                  key="input"
                  className="flex-fill"
                  balances={this.state.sourceTokens}
                  token={this.state.sourceToken3}
                  value={this.state.inValue3}
                  setTokenCallback={this.setFromToken3}
                  setValueCallback={this.setInValue3}
                  validate={true}
                  onValidityChanged={this.inputValidityChanged(3)}
                  showPrice={this.state.showPrice}
                  price={this.state.price3}
                />
              </div>
            }
            <div className="d-flex my-3 text-end">
              {this.state.showFromTokensNo < 3 &&
                <PlusCircle className="text-gray-400 mx-2" role="button" onClick={this.addFromToken}/>
              }
              {this.state.showFromTokensNo > 1 &&
                <DashCircle className="text-gray-400 mx-2" role="button" onClick={this.removeFromToken}/>
              }
            </div>

            <div className="d-flex my-3">
              <hr className="m-0 flex-grow-1 my-auto" />
              {!this.state.multiToOne &&
                <div className="text-gray-400 mx-2" role="button">
                  <ChevronExpand onClick={this.switchDirection}/>
                </div>
              }
              <hr className="m-0 flex-grow-1 my-auto" />
            </div>


            <BlockchainSelector
              className="mt-3 w-100 ms-auto"
              blockchains={this.state.destinationBlockchains}
              selected={this.state.destinationBlockchain}
              onSelect={this.setDestinationBlockchain}
            />

            <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch mt-2">
              <div className="rounded-3 border border-3 border-primary me-3"></div>
              <TokenSelectForSwap
                key="output"
                className="flex-fill"
                balances={this.state.destinationTokens}
                token={this.state.destinationToken}
                value={this.state.outValue}
                setTokenCallback={this.setToToken}
                setValueCallback={this.setOutValue}
                readOnly={this.state.crosschain || this.state.multiToOne}
                canChangeTokens={true}
                validate={false}
                showBalance={!this.state.crosschain}
                showPrice={false}
              />
            </div>

            <SwapFees
              fees={this.state.fees}
            />

            {this.state.canTrade &&
              <div className={`mt-3 p-3 rounded-3 bg-gray-800`}>
                <div className="d-flex align-items-center">
                  <div>Pay for network fee with</div>
                  <ToggleButtonGroup type="radio" name="tab" className='ms-auto' onChange={this.setPayFeesWith} defaultValue={this.state.payFeesWith}>
                    <ToggleButton id={'native'} key={'native'} value={'native'} size="sm" variant="outline-secondary">
                        {this.state.sourceBlockchain.config.nativeToken}
                      </ToggleButton>
                      <ToggleButton id={'other'} key={'other'} value={'other'} size="sm" variant="outline-secondary">
                        My Tokens
                      </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                {this.state.payFeesWith === 'other' &&
                  <TokenSelectForSwap
                    key="gaspayer"
                    className="flex-fill mt-3"
                    balances={this.state.sourceBlockchain.tokens.permit}
                    token={this.state.payFeesToken}
                    value={this.state.payFeesAmount}
                    setTokenCallback={this.setPayFeesToken}
                    validate={true}
                    readOnly={true}
                    onValidityChanged={this.payFeesValidityChanged}
                  />
                }
              </div>
            }

            <Button variant={this.state.canTrade && this.state.canPayFees ? 'primary' : 'secondary'}
                    className='border-0 w-100 mt-4 p-3' onClick={this.doSwap}
                    disabled={!this.state.canTrade || !this.state.canPayFees}>
              SWAP
              {this.state.estimating &&
                <Spinner className='ms-2' animation="border" variant="white" style={{ width: "1rem", height: "1rem" }} />
              }
            </Button>
          </div>
        </div>
    </>
    );
  }
}
export default UnifiedSwap;
