import React from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { formattedAmount, Globals } from "../utils";
import AmountInput from '../Components/AmountInput';
import { Check } from 'react-bootstrap-icons';
import LiqudityBooster from '../Wallet/LiquidityBooster';

class Boost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      percentValue: 0,
      usdc: props.wallet.usdcToken(),
      dxvs: props.wallet.dxvsToken(),
      step: "data",
      error: undefined
    }
    this.setValueByPercentage = this.setValueByPercentage.bind(this);
    this.setValue = this.setValue.bind(this);
    this.boost = this.boost.bind(this);
  }

  setValueByPercentage(e) {
    this.setState({
      percentValue: parseInt(e.target.value),
      value: this.props.usdcBalance * (e.target.value / 100)
    });
  }

  setValue(value) {
    if (value > this.state.balance) {
      value = this.state.balance;
    }
    let percentage = Math.round(value / this.props.usdcBalance * 100);
    this.setState({
      percentValue: percentage,
      value: value
    });
  }

  boost() {
    this.setState({ step: "boosting" });
    let booster = new LiqudityBooster(this.props.wallet, this.state.value);
    booster.prepare();
    Globals.pendingTransactions.add(booster.transaction);
    booster.execute();
    this.props.setPage("home");
  }

  render() {
    return (
      <>
        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3 border border-secondary mt-3">
          <div className="me-auto fw-medium mb-3">Available to Boost</div>
          <div>
            <span className="text-gray-400">USD Coin</span> &nbsp;
            <span className="text-gray-500">(USDC)</span>
          </div>
          <hr className="text-gray-300" />
          <div className="align-baseline mb-3">
            <div className="d-inline-block mt-auto mb-1 fw-medium">Balance</div>
            <div className="d-inline-block fs-2 fw-bold ms-3">{formattedAmount(this.props.usdcBalance)}</div>
          </div>
        </div>
        <h5 className='my-3'>Amount to Invest</h5>
        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3 border border-secondary mt-3">
          <div className="me-auto fw-medium mb-3">Adjust Investment Amount</div>

          <div className='bg-opacity-100'>
              <Form.Range min='0' max='100' step='1' value={this.state.percentValue} onChange={this.setValueByPercentage} />
              <div className='g-0 range-ruler text-gray-400 mb-4'>
                <div className='step'><div className="vr"></div><div className="percent">0%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">10%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">20%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">30%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">40%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">50%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">60%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">70%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">80%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">90%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">100%</div></div>
              </div>
            </div>
            <div className="d-flex flex-grow mt-5 mb-3">
              <hr className='m-0 flex-grow-1 my-auto' />
              <div className="text-gray-400">👆 SLIDE OR TYPE AMOUNT 👇</div>
              <hr className='m-0 flex-grow-1 my-auto' />
            </div>
            <div>
            <InputGroup>
              <InputGroup.Text className='text-gray-500 bg-white'>
                {this.state.usdc.symbol}
              </InputGroup.Text>
              <AmountInput
                className="form-control text-gray-500 bg-white border-start-0"
                value={this.state.value}
                max={this.props.usdcBalance}
                onChange={this.setValue}
              />
            </InputGroup>
            </div>
        </div>
        <h5 className='my-3'>Liquidity Pools you are investing in</h5>
        <div className="rounded rounded-3 border border-secondary overflow-hidden">
          <Table variant="dark" className='small align-middle mb-0'>
            <thead>
              <tr>
                <th width="8%" className='fw-normal'></th>
                <th width="22%" className='fw-normal'>Pairing</th>
                <th width="35%" className='fw-normal'>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Check type="radio" aria-label="usdc-dxvs" checked={true} />
                </td>
                <td>
                  {this.state.dxvs.symbol}-{this.state.usdc.symbol}
                </td>
                <td>
                  <span className="text-success">APY: 200%</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="my-4">
          <Button variant="primary" className="w-100" disabled={this.state.value <= 0} onClick={this.boost}>
            <Check size={22} className="mr-2" />
            Approve Liquidity Boost
          </Button>

          <Button variant="link" className="w-100 mt-3" onClick={() => this.props.setPage("home")}>
            Cancel &amp; Go Back
          </Button>
        </div>
      </>
    );
  }
}

export default Boost;
