import { Globals } from '../utils';
import { ABI } from './ABI/index';
import ContractMethodExtension from './ContractMethodExtension';

export class ContractsFactory {
  constructor(blockchain = undefined) {
    this._blockchain = blockchain || Globals.currentBlockchain;
  }

  get blockchain() {
    if (this._blockchain === undefined)
      this._blockchain = Globals.currentBlockchain;
    return this._blockchain;
  }

  get router() {
    return this.build('router');
  }

  get factory() {
    return this.build('factory');
  }

  get externalRouter() {
    return this.build('externalRouter');
  }

  get externalFactory() {
    return this.build('externalFactory');
  }

  get gassless() {
    return this.build('gassless');
  }

  get bootstrap() {
    return this.build('bootstrap');
  }

  get staking() {
    return this.build('staking');
  }

  get stakingLocked() {
    return this.build('stakingLocked');
  }

  get crosschain() {
    return this.build('crosschain');
  }

  get weth() {
    return this.build('WETH', this.blockchain.config.wrappedNativeTokenAddress);
  }

  get usdc() {
    return this.erc20(this.blockchain.config.tokens.usdc);
  }

  pair(token) {
    return this.build('pair', token);
  }

  erc20(address) {
    return this.build('ERC20', address);
  }

  findDefinition(name) {
    let definition = ABI[name] || ABI[name.replace(/^external/, '').toLowerCase()];
    if (definition === undefined)
      throw new Error(`Contract "${name}" ABI  not found`);
    return definition;
  }

  findAddress(name) {
    let address = this.blockchain.config.contracts[name] || this.blockchain.config.tokens[name];
    if (address === undefined)
      throw new Error(`Contract "${name}" address not found`);
    return address;
  }

  build(name, address = undefined) {
    let definition = this.findDefinition(name);
    address ||= this.findAddress(name);
    let contract = new this.blockchain.web3.eth.Contract(definition.abi, address);
    contract.methods = new Proxy(contract.methods, {
      get: (target, func) => {
        return function (...args) {
          let method = target[func](...args);
          ContractMethodExtension.extend(method);
          method.meta.contract = contract;
          method.meta.contractType = name.replace(/^external/, '');
          method.meta.contractAddress = address;
          method.meta.methodName = func;
          method.meta.methodArgs = args;
          return method;
        };
      }
    });
    return contract;
  }
}
