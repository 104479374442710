import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowDown, ArrowUp, Check, Pencil, QuestionCircle } from 'react-bootstrap-icons';
import TokenBalance from '../Components/TokenBalance';
import TokenIcon from '../Components/TokenIcon';
import TrendingValue from '../Components/TrendingValue';
import { formattedAmount } from '../utils';

class SwapWizConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.doSwap = this.doSwap.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToSrc = this.goToSrc.bind(this);
    this.goToDst = this.goToDst.bind(this);
  }

  doSwap() {
    this.props.doSwap();
  }

  goBack() {
    this.props.goToStep('dst');
  }

  goToSrc() {
    this.props.goToStep('src');
  }

  goToDst() {
    this.props.goToStep('dst');
  }

  render() {
    return(
      <div className=''>

        <h2 className='text-center'>
          Almost There!<br/>
          Let's review this swap...
        </h2>
        <div className='mt-3 mb-2'>You are swapping:</div>
        <div className="bg-gray-700 rounded p-3">
          <div className="d-flex  d-flex align-items-stretch">
            <div>
              <div>Amount</div>
              <div className="fs-2 fw-bold text-gray-400">{formattedAmount(this.props.amountIn)}</div>
            </div>
            <div className='py-2 ms-auto my-auto'>
              <div className='d-inline-block'><TokenIcon token={this.props.fromToken} size={32} /></div>
            </div>
            <div className="ms-1 my-auto">
              <div className='ms-1'>{this.props.fromToken.name}</div>
              <div className='text-muted ms-1'>({this.props.fromToken.symbol})</div>
            </div>
            <div className="ms-3 my-auto">
              <Button variant='link text-gray-500' onClick={this.goToSrc}>
                <Pencil size={24}/>
              </Button>
            </div>
          </div>
          <hr className="my-3" />
          <div className="text-end">
            <span className="text-gray-400">Balance: </span>
            <span className="text-gray-200">
              <TokenBalance token={this.props.fromToken} />
              {this.props.fromToken.symbol}
            </span>
          </div>
        </div>
        <div className='mt-3 mb-2'>To:</div>
        <div className="bg-gray-700 rounded p-3">
          <div className="d-flex  d-flex align-items-stretch">
            <div>
              <div>Amount</div>
              <div className="fs-2 fw-bold text-gray-400">{formattedAmount(this.props.amountOut)}</div>
            </div>
            <div className='py-2 ms-auto my-auto'>
              <div className='d-inline-block'><TokenIcon token={this.props.toToken} size={32} /></div>
            </div>
            <div className="ms-1 my-auto">
              <div className='ms-1'>{this.props.toToken.name}</div>
              <div className='text-muted ms-1'>({this.props.toToken.symbol})</div>
            </div>
            <div className="ms-3 my-auto">
              <Button variant='link text-gray-500' onClick={this.goToDst}>
                <Pencil size={24}/>
              </Button>
            </div>
          </div>
          <hr className="my-3" />
          <div className="text-end">
            <span className="text-gray-400">Balance: </span>
            <span className="text-gray-200">
            <TokenBalance token={this.props.toToken} />
              {this.props.toToken.symbol}
            </span>
          </div>
        </div>

        <div className={`mt-4 p-3 rounded-3 bg-gray-800`}>
          <div className="mb-3">Swap Details</div>
          <div className="d-flex">
            <div className="small text-gray-400 me-2">Output</div>
            <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
          </div>
          <div className="d-flex mt-2 small">
            <div className="flex-fill">Expected Output <QuestionCircle title="Expected output"/></div>
            <div className="ms-3 text-end">
              {formattedAmount(this.props.amountOut)} {this.props.toToken.symbol}
            </div>
          </div>
          <div className="d-flex mt-2 small">
            <div className="flex-fill">Minimum after slippage (0.50%) <QuestionCircle/></div>
            <div className="ms-3 text-end">{formattedAmount(this.props.amountOutAfterSlippage)}</div>
          </div>

          <div className="d-flex mt-3">
            <div className="small text-gray-400 me-2">Impact</div>
            <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
          </div>
          <div className="d-flex mt-2 small">
            <div className="flex-fill">Price Impact <QuestionCircle/></div>
            <div className="ms-auto text-end"><TrendingValue value={this.props.priceImpact} suffix="%" /></div>
          </div>

          <div className="d-flex mt-3">
            <div className="small text-gray-400 me-2">Transaction Fees</div>
            <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
          </div>
          <div className="d-flex mt-2 small">
            <div className="flex-fill">Network fee <QuestionCircle/> </div>
            <div className="ms-auto text-end">0.30%</div>
          </div>
        </div>
        <Button variant='primary' size='lg' className='mt-4 w-100 py-2' onClick={this.doSwap}>
          <Check size={35}/>
          Approve Swap
        </Button>
        <Button className="w-100 mt-4" variant="link" onClick={this.goBack}>
          Back
        </Button>
      </div>
    );
  }
}

export default SwapWizConfirm;
