let config = {
  backendUrl: "https://backend.dexvers.cbix.io",
  blockchains: ["matic", "bsc", "avalanche", "ethereum"],
  wallet: {
    futureNetworks: [
    ],
    networks: [
      {
        id: 137,
        name: "Polygon",
        native_token: "MATIC",
        factoryAddress: "0x8Df775934b923C969d4c782c596e7a23257A0F8A",
        routerAddress: "0x5488A6Db5E6DE0ffAF58588213580220bD197115",
        // Using QuickSwap contract as external
        // https://docs.quickswap.exchange/reference/smart-contracts/01-factory
        // https://docs.quickswap.exchange/reference/smart-contracts/router02
        externalFactoryAddress: "0x5757371414417b8c6caad45baef941abc7d3ab32",
        externalRouterAddress: "0x71fb78196FA0891B7a12EF2352162949f4D2725c", // forwarder
        stakingContractAddress: "0xad6FED17D6458AE0Ab1c97e4A1df1A633A3E49EA",
        rewardTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
        bootstrapAddress: "0x12B990a0D4b262D464acCbE3AA10e965E4350C4C",
        stakingLockedContractAddress: "0x9da18a2e7ea8889a62474E526479e9A7890CFa40",
        gasslessAddress: "0x47522579126863Eee4Eba10Adb221c7AA1dd76C9",
        usdcTokenAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        dxvsTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
        nativeCurrencyAddress: "0x0000000000000000000000000000000000001010",
        crosschainAddress: "0x43dD307B915c72cA04C1B060e165925D2B3ba534",
        connextDomainID: "1886350457",
        pools: [],
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://polygonscan.com"],
        wssUrl: "wss://rpc.ankr.com/polygon/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
        iconUrl: "/chains/matic.svg"
      },
      {
        id: 56,
        name: "Binance Smart Chain",
        native_token: "BNB",
        factoryAddress: "0xc4F01e89e5C13AeDBB786ed7BE56B56ad7b2fD98",
        routerAddress: "0x9EE942d5EC400ee0145D43b18a65CDF1C0220550",
        // Using Sushiswap contract as external (UniswapV2 contracts)
        // Factory: 0xc35DADB65012eC5796536bD9864eD8773aBc74C4
        // Router: 0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506
        externalFactoryAddress: "0xc35dadb65012ec5796536bd9864ed8773abc74c4",
        externalRouterAddress: "0x92B26Ef5998C73236d8157A437CA09ABE6df77f4", // forwarder
        stakingContractAddress: "",
        rewardTokenAddress: "",
        bootstrapAddress: "",
        stakingLockedContractAddress: "",
        gasslessAddress: "0xe229DB0c94409007A587Caa982453724246aB4a3",
        usdcTokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        dxvsTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
        nativeCurrencyAddress: "0x1234123412341234123412341234123412341234",
        crosschainAddress: "0x0888F19c815C4c5B25f227E064BdE28bc738F0A7",
        connextDomainID: "6450786",
        pools: [],
        rpcUrls: ["https://bsc-dataseed1.binance.org"],
        blockExplorerUrls: ["https://bscscan.com"],
        wssUrl: "wss://rpc.ankr.com/bsc/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
        defaultChartsTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        iconUrl: "/chains/bsc.svg"
      },
      {
        id: 43114,
        name: "Avalanche",
        native_token: "AVAX",
        factoryAddress: "0xc4F01e89e5C13AeDBB786ed7BE56B56ad7b2fD98",
        routerAddress: "0x9EE942d5EC400ee0145D43b18a65CDF1C0220550",
        // Using Pangolin contract as external (UniswapV2 contracts)
        // Factory: 0xefa94DE7a4656D787667C749f7E1223D71E9FD88
        // Router: 0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106
        externalFactoryAddress: "0xefa94DE7a4656D787667C749f7E1223D71E9FD88",
        externalRouterAddress: "0x61b1af88f303511578f812ac66af2a697Fa4CDD9", // forwarder
        stakingContractAddress: "",
        rewardTokenAddress: "",
        bootstrapAddress: "",
        stakingLockedContractAddress: "",
        gasslessAddress: "0x92B26Ef5998C73236d8157A437CA09ABE6df77f4",
        usdcTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        dxvsTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
        nativeCurrencyAddress: "0x1234123412341234123412341234123412341234",
        pools: [],
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc", "https://avalanche-mainnet.infura.io"],
        blockExplorerUrls: ["https://snowtrace.io/"],
        wssUrl: "wss://rpc.ankr.com/avalanche/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
        defaultChartsTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        iconUrl: "/chains/avax.svg"
      },
      {
        id: 1,
        name: "Ethereum",
        iconUrl: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
        native_token: 'ETH',
        factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
        routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        usdcTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        dxvsTokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        pools: [],
        rpcUrls: ['https://eth.llamarpc.com'],
        blockExplorerUrls: ["https://etherscan.io"],
        wssUrl: 'wss://rpc.ankr.com/eth/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61',
        nativeCurrencyAddress: '0x1234123412341234123412341234123412341234',
        defaultChartsTokenAddress: '0x1234123412341234123412341234123412341234'
      }
    ]
  }
};

export default config;
