import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import TokenBalance from '../Components/TokenBalance';
import TokenIcon from '../Components/TokenIcon';

class WizTokenSelectItem extends React.Component {
  render() {
    return (
      <div>
        <ListGroup className='list-group-flush rounded-3'>
          {this.props.tokens.map((t) =>
            <ListGroup.Item key={t.address} className='p-3 bg-gray-700 bg-opacity-35 border-secondary' role="button" onClick={(e) => this.props.setTokenCallback(t)}>
              <div className="d-flex align-items-center">
                <TokenIcon token={t} size={32} />
                <div className="ms-2 small">
                  <div className="text-gray-100">{t.name}</div>
                  <div className="text-gray-400">{t.symbol}</div>
                </div>
                <div className="bg-gray-700 rounded-3 p-2 text-end ms-auto small">
                  <div className="text-gray-300">Balance</div>
                  <div className="text-gray-200"><TokenBalance token={t}></TokenBalance></div>
                </div>
                <ChevronRight size={20} className="ms-4 text-gray-500" />
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
    );
  }

}

export default WizTokenSelectItem;
