let config = {
  blockchains: ["local", "testnet"],
  backendUrl: "https://dexbackend.combo.ro",
  wallet: {
    networks: [
      {
        id: 31337,
        name: "Local TestNet",
        native_token: "ETH",
        factoryAddress: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        routerAddress: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
        externalFactoryAddress: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
        externalRouterAddress: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
        stakingContractAddress: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
        rewardTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        bootstrapAddress: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        stakingLockedContractAddress: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
        gasslessAddress: '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE',
        usdcTokenAddress: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
        dxvsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        nativeCurrencyAddress: "0x1234123412341234123412341234123412341234",
        pools: [],
        rpcUrls: ["http://localhost:8545"],
        blockExplorerUrls: ["https://etherscan.io"],
        wssUrl: "ws://localhost:8545"
      },
      {
        id: 31338,
        name: "Dexvers TestNet",
        native_token: "ETH",
        factoryAddress: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        routerAddress: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
        externalFactoryAddress: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
        externalRouterAddress: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
        stakingContractAddress: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
        rewardTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        bootstrapAddress: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        stakingLockedContractAddress: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
        gasslessAddress: '0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE',
        usdcTokenAddress: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
        dxvsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        nativeCurrencyAddress: "0x1234123412341234123412341234123412341234",
        pools: [],
        rpcUrls: ["https://localtestnet.dexvers.cbix.io"],
        blockExplorerUrls: ["https://etherscan.io"],
        wssUrl: "wss://localtestnet.dexvers.cbix.io"
      }
    ]
  }
};

export default config;
