import './styles/index.scss';
import { Container} from 'react-bootstrap';
import Web3 from 'web3';
import React from 'react';
import DexMenu from './DexMenu';
import Swap from './Swap';
import Charts from './Charts';
import Exchanger from './Exchanger';
import TokenList from './TokenList';
import Wallet from './Wallet/Wallet.js';
import { formattedAmount, Globals } from "./utils";
import Dashboard from './Dashboard';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import Earn from './Earn';
import Loading from './Components/Loading';
import Contracts from './Blockchain/Contracts';
import BalancePoller from './Wallet/BalancePoller';
import PendingTransactions from './Components/PendingTransactions';
import Blockchain from './Blockchain/Blockchain';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Embeds from './Embeds/Embeds';

class App extends React.Component {
  constructor(props) {
    super(props);
    Globals.blockchains = Blockchain.loadAll();

    this.baseUrl = 'http://localhost:4000/api/v1/s/'
    this.token_list = new TokenList();
    let wallet = new Wallet(this);
    window.$wallet = wallet;
    this.state = {
      wallet: wallet,
      walletStatus: undefined,
      exchanger: new Exchanger(),
      address: '',
      native_currency: 'ETH',
      tokens: [],
      menuItems: [
        {
          id: 2,
          name: 'Swap',
          selected: true
        },
        {
          id: 3,
          name: 'Earn',
          selected: false
        },
        // {
        //   id: 4,
        //   name: 'Charts',
        //   selected: false
        // },
        // {
        //   id: 1,
        //   name: 'Dashboard',
        //   selected: false
        // }
      ],
      balances: [],
      availableTokensToSwap: this.token_list.tokenList,
      fromTokenAddress: '',
      toTokenAddress: '',
      chainId: '0x89',
      // Exchange values
      fromToken: {
        address: '',
        balance: 0,
        name: '',
        symbol: ''
      },
      toToken: {
        address: '',
        balance: 0,
        name: '',
        symbol: ''
      },
      noToken: {
        address: '',
        balance: 0,
        name: '',
        symbol: ''
      },
      exchangeAmountOut: 0,
      exchangeAmountIn: 0,
      exchangeAmountOutInUSD: 0,
      exchangeAmountInInUSD: 0,
      exchangePriceImpact: 0,
      exchangeAfterSlippage: 0,
      exchangeNetworkFee: 0,
      exchangeMaxSlippage: 0.5
    };
    this.state.selectedMenuItem = this.state.menuItems[0];

    this.connectWallet = this.connectWallet.bind(this);
    this.setSelectedMenuItem = this.setSelectedMenuItem.bind(this);
    this.performAction = this.performAction.bind(this);

    this.loadTokens = this.loadTokens.bind(this);
    Globals.balancePoller = new BalancePoller(this);
  }

  isWalletConected() {
    return (this.state.address !== '');
  }

  async getAllPairs() {
    let pair = '';
    let contract = Contracts.factory;
    let numberOfPairs = await contract.methods.allPairsLength().call();
    for(let i =  0; i < numberOfPairs; i++) {
      pair = await contract.methods.allPairs(i).call();
      let pairContract = Contracts.pair(pair);
      let token0 = await pairContract.methods.token0().call();
      let token1 = await  pairContract.methods.token1().call();
      console.log(`Pair: ${pair} has token0: ${token0} , token1: ${token1}`);
      if (i > 50) {
        break;
      }
    }
  }

  setSelectedMenuItem(id) {
    this.state.menuItems.forEach((m) => { m.selected = false })
    this.setState({ menuItems: this.state.menuItems }, ()=> {
      this.state.menuItems.forEach((m) => { m.selected = m.id === id })
      this.setState({
        menuItems: this.state.menuItems,
        selectedMenuItem: this.state.menuItems.find((m) => m.id === id)
      })
    })
  }

  performAction(key, event = undefined) {
    console.log("Perform action:", key);
    if (key === 'disconnect-wallet')
      this.disconnectWallet();
    if (key === 'connect-wallet')
      this.connectWallet();
    if (key === 'change-network')
      this.state.wallet.changeNetwork(parseInt(event.target.dataset.chainId), 10);

    if (event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  disconnectWallet() {
    this.state.wallet.disconnect();
  }

  connectWallet() {
    this.state.wallet.connect(true);
  }

  componentDidMount() {
    // this.state.wallet.tryConnectProvider();
    this.state.wallet.connect();
    Globals.state = this.state;
  }

  componentDidUpdate(prevProps, prevState) {
    Globals.state = this.state;
  }

  async getTokenBalance(tokenAddress) {
    return await Contracts.erc20(tokenAddress).methods.balanceOf(this.state.address[0]).call();
  }

  async resetApp() {
    this.setState({
      address: []
    });
    let web3 = this.state.web3;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    this.web3Modal.clearCachedProvider();
  }

  async loadTokens(chainId, address = undefined) {
    return this.token_list.loadTokens(chainId, address || this.state.address)
      .then((data) => {
        console.log("App token list:", this.token_list.tokenList);
        this.setState({
          balances: this.token_list.tokensWithBalance,
          tokens: this.token_list.tokenList
        });
      });
  }

  token(address) {
    return this.state.tokens.find((x) => x.address.toLowerCase() === address?.toLowerCase());
  }

  render() {
    // we also set this in componentDidUpdate but that method is called
    // after rendering happening so components will use an outdated state
    Globals.state = this.state;

    if (window.location.hash.indexOf("#/embed/") === 0)
      return (<Embeds status={this.state.walletStatus} wallet={this.state.wallet} tokens={this.state.tokens} actionCallback={this.performAction}/>);

    document.body.classList.remove('loading');

    if (this.state.walletStatus === undefined)
      return (<Loading className="w-50 mx-auto mt-5"/>);

    return (
      <Router>
        <DexMenu
          menuItems={this.state.menuItems}
          menuCallback={this.setSelectedMenuItem}
          actionCallback={this.performAction}
          address={this.state.address}
          balance={formattedAmount(this.state.balance)}
          currency={this.state.native_currency}
          wallet={this.state.wallet}

        />
        <Container className="pb-5 mt-5">
          <Routes>
            <Route path="/dashboard" element={
              <Dashboard
                balances={this.state.balances}
                wallet={this.state.wallet}
                address={this.state.address}
                />
              }/>
            <Route path="/earn" element={
              <Earn
                balances={this.state.balances}
                wallet={this.state.wallet}
                address={this.state.address}
                walletStatus={this.state.walletStatus}
              />
            }/>
            <Route path="/charts" element={
              <Charts
              nativeCurrency={this.state.native_currency}
              chainId={this.state.chainId}
              tokens={this.state.tokens}
              wallet={this.state.wallet}
            />
            }/>
            <Route path="*" element={
              <Swap
                tokens={this.state.tokens}
                wallet={this.state.wallet}
                connectWallet={this.connectWallet}
              />}/>
          </Routes>
        </Container>
        <PendingTransactions />
      </Router>
    );
  }
}

export default App;
