import Config from "./Config";

class DexversApi {
  constructor() {
    this.baseUri = Config.backendUrl + "/api/v1/s";
    this.gasslessUri = Config.backendUrl + "/api/v1/g";
  }

  baseUrl() {
    return this.baseUri;
  }

  gasslessUrl() {
    return this.gasslessUri;
  }
}

export default DexversApi;
