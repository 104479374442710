import React from 'react';
import { Col, Row} from 'react-bootstrap';
import TvlChart from '../Graphs/TvlChart';
import PriceChart from '../Graphs/PriceChart';
import WalletCountChart from '../Graphs/WalletCountChart';
import ChartChangeBadge from './ChartChangeBadge';
import VolumeChart from '../Graphs/VolumeChart';

class TopChartInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGraphId: 1,
    };

    this.items = [
      { name: "TVL", value: props.tvlChange },
      { name: "Price", value: props.priceChange },
      { name: "Wallets", value: props.walletChange },
      { name: "Volume", value: props.volumeChange }
    ]

    this.setSelectedGraph = this.setSelectedGraph.bind(this);
  }

  setSelectedGraph(id) {
    this.setState({
      selectedGraphId: id
    });
  }

  isSelected(id) {
    return this.state.selectedGraphId === id;
  }

  render() {
    return (
      <>
        <Row>
          <Col xs={12} lg={4} className="mb-3">
            <div className="rounded-3 overflow-hidden bg-secondary bg-opacity-35 h-100">
              {this.items.map((item, index) =>
                <div key={index} onClick={() => this.setSelectedGraph(index + 1)} role="button" className={"d-flex px-3 py-4 border-secondary " + (index > 0 ? "border-top": "")}>
                  <div>{item.name}</div>
                  <div className="ms-auto">
                  <ChartChangeBadge change={item.value || 0}/>
                  </div>
                </div>
              )}
            </div>
          </Col>

          <Col xs={12} lg={8} className="mb-3">
            <div className="rounded-3 bg-secondary bg-opacity-35 h-100 pt-3">
              <div  style={{display: this.isSelected(1) ? 'block' : 'none'}}>
                <TvlChart chainId={this.props.chainId} tokenAddress={this.props.tokenAddress}/>
              </div>
              <div  style={{display: this.isSelected(2) ? 'block' : 'none'}}>
                <PriceChart chainId={this.props.chainId} tokenAddress={this.props.tokenAddress} style={{visibility: this.isSelected(2) ? 'visible' : 'hidden'}}/>
              </div>
              <div  style={{display: this.isSelected(3) ? 'block' : 'none'}}>
                <WalletCountChart chainId={this.props.chainId} tokenAddress={this.props.tokenAddress} style={{visibility: this.isSelected(3) ? 'visible' : 'hidden'}}/>
              </div>
              <div  style={{display: this.isSelected(4) ? 'block' : 'none'}}>
                <VolumeChart chainId={this.props.chainId} tokenAddress={this.props.tokenAddress} style={{visibility: this.isSelected(4) ? 'visible' : 'hidden'}}/>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default TopChartInfo;
