import React from 'react';
import { Col, Row, Card, Table } from 'react-bootstrap';
import TokenIcon from '../../Components/TokenIcon';
import { formattedAmount, Globals } from '../../utils';
import StakeView from "../Stake/StakeView";

class Farms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stakes: [],
      showsStakeView: false,
      selectedStake: null
    }
    this.showStakeView = this.showStakeView.bind(this);
    this.hideStakeView = this.hideStakeView.bind(this);
    this.updateStakes = this.updateStakes.bind(this);
  }

  componentDidMount() {
    this.loadStakes();
  }

  componentWillUnmount() {
    this.props.wallet.stakes.off("change", this.updateStakes);
  }

  loadStakes() {
    this.updateStakes();
    this.props.wallet.stakes
      .on("change", this.updateStakes)
      .load();
  }


  updateStakes() {
    this.setState({ stakes: this.props.wallet.stakes.list.filter((stake) => stake.pool !== undefined) });
  }

  showStakeView(stake) {
    this.setState({ showsStakeView: true, selectedStake: stake });
  }

  hideStakeView() {
    this.setState({ showsStakeView: false, selectedStake: null });
  }

  render() { return(
    <>
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={12} lg={"auto"}>
              <Card.Title>Your Farms</Card.Title>
              <Card.Subtitle className="text-secondary">Track and manage your farm stakes</Card.Subtitle>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <Table className="small mb-0" variant='dark'>
            <thead>
              <tr>
                <th className='fw-normal'>Pairing</th>
                <th className='fw-normal'>Investment</th>
                <th className='fw-normal'>Earned</th>
                <th className='fw-normal'>APY</th>
              </tr>
            </thead>
            <tbody>
              {this.state.stakes.map((stake, index) =>
                <tr className="text-white align-middle" key={index.toString()} role="button" onClick={()=> this.showStakeView(stake)}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-inline-block pair-icons">
                        <TokenIcon token={stake.pool.token0} size="24" />
                        <TokenIcon token={stake.pool.token1} size="24" />
                      </div>
                      <div className="d-inline-block ms-2">
                        {stake.pool.token0.symbol}-{stake.pool.token1.symbol}
                      </div>
                    </div>
                  </td>
                  <td className="text-nowrap text-end">
                    <div className="d-flex align-items-center justify-content-end mb-1">
                      {formattedAmount(stake.token0Balance)}
                      <TokenIcon token={stake.pool.token0} size="18" className="mx-1" />
                      <span className="text-gray-400"> {stake.pool.token0.symbol}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {formattedAmount(stake.token1Balance)}
                      <TokenIcon token={stake.pool.token1} size="18" className="mx-1" />
                      <span className="text-gray-400"> {stake.pool.token1.symbol}</span>
                    </div>
                  </td>

                  <td className="text-center py-3">
                    <span className="text-secondary">EARNED</span><br/>
                    <span className="bg-white badge rounded-pill text-dark">{formattedAmount(stake.totalClaimed)} {this.props.wallet.rewardToken().symbol}</span>
                  </td>
                  <td className="text-center py-3">
                    <span className="text-secondary">APY</span><br/>
                    <span className="bg-white badge rounded-pill text-dark">{0}%</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {this.state.stakes.length === 0 &&
            <div className="text-center py-5">
              {Globals.state.walletStatus === 'connected' ? "No farm stakes found" : "Connect to your wallet to view your stakes"}
            </div>

          }
        </Card.Body>
      </Card>


      { this.state.showsStakeView &&
        <StakeView
          stake={this.state.selectedStake}
          pool={this.state.selectedStake.pool}
          wallet={this.props.wallet}
          balances={this.props.balances}
          closeCallback={this.hideStakeView}
          tab="earnings"
        />
      }

    </>
  )}
}

export default Farms;
