import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { filterTokens } from '../utils';
import WizTokenSelectItem from './WizTokenSelectItem';

class SwapWizPickFromToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      fromToken: '',
      toToken: '',
      tokens: []
    };
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.setFromToken = this.setFromToken.bind(this);
  }

  componentDidMount() {
    this.setState({
      tokens: this.props.tokens.slice(0,5)
    });
  }

  setSearchTerm(event) {
    let search_term = event.target.value;
    this.setState({
      searchTerm: search_term,
      tokens: filterTokens(this.props.tokens, search_term).slice(0,5)
    })
  }

  setFromToken(token) {
    this.setState({ fromToken: token });
    this.props.setFromTokenCallback(token);
  }

  render() {
    return (
      <div className='px-4 py-3'>
        <h2 className='text-center'>Let's pick a token to swap!</h2>
        <p className="text-center text-gray-400">TIP: You'll pick the amount you want to swap on the next screen.</p>

        <InputGroup className='mt-4'>
          <InputGroup.Text className='bg-gray-600 text-gray-400 border-dark'>
            <Search size={16}/>
          </InputGroup.Text>
          <FormControl
            placeholder="Search for a token in your wallet"
            aria-label="Search"
            className='ps-0  bg-gray-600 text-gray-400 border-dark border-start-0'
            onChange={this.setSearchTerm}
          />
        </InputGroup>

        <div className='mt-4'>
          <WizTokenSelectItem tokens={this.state.tokens} setTokenCallback={this.setFromToken}/>
        </div>
      </div>
    );
  }
}

export default SwapWizPickFromToken;
