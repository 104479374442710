import { BigNumber } from "bignumber.js/bignumber.js";
import {toChecksumAddress} from 'web3-utils';

export function shortAddress(address) {
  if (typeof(address) === "undefined") {
    address ='';
  }
  if (typeof(address) === "object") {
    address = address[0];
  }
  return address.substring(0,2) + "..." + address.slice(-4);
}
export function shortTokenName(name) {
  if (typeof(name) === "undefined") {
    name ='';
  }
  if (name === name.substring(0,7)) {
    return name;
  }
  return name.substring(0,7) + "...";
}

export function fromContractDecimals(amount, dec = 18){
  amount = new BigNumber(amount);
  let divisor = BigNumber(Math.pow(10,dec));
  return amount.dividedBy(divisor).toString(10);
}

export function fromContractDecimalsToNumber(amount, dec = 18){
  amount = new BigNumber(amount);
  let divisor = BigNumber(Math.pow(10,dec));
  return amount.dividedBy(divisor).toNumber();
}

export function toContractDecimals(amount, dec = 18){
  amount = new BigNumber(amount);
  let multiplier = new BigNumber(Math.pow(10,dec));
  var r = amount.times(multiplier).integerValue(BigNumber.ROUND_FLOOR);
  return r.toFixed();
}

// export function toTokenBigNumber(amount, token){
//   amount = new BigNumber(amount);
//   let multiplier = new BigNumber(Math.pow(10, token.decimals || 18));
//   return amount.times(multiplier);
// }

export var Globals = {
  native_currency_address: "0x1234123412341234123412341234123412341234",
  wrapped_currency_address: null,
  no_address: "0x0000000000000000000000000000000000000000",
  state: {},
  web3: undefined,
  blockchain: undefined,
  blockchains: [],
  currentBlockchain: undefined,
  balancePoller: undefined,
};

window.$globals = Globals;

export function isNativeCurrency(address) {
  return address?.toLowerCase() === Globals.native_currency_address.toLowerCase();
}

export function isWrappedNativeCurrency(address) {
  return address?.toLowerCase() === Globals.wrapped_currency_address.toLowerCase();
}

export function normalizeTokenAddress(address) {
  if (isNativeCurrency(address)) address = Globals.wrapped_currency_address;
  return toChecksumAddress(address);
}

export function formattedAmount(amount, options = {}) {
  options = { stripInsignificantZeros: true, ...options }
  if (typeof(amount) === "undefined") {
    amount = 0;
  }
  if (typeof(amount) === "string")
    amount = parseFloat(amount, 10);
  let formatOptions = { style: 'decimal', minimumFractionDigits: 2 }
  if (amount < 1)
    formatOptions.maximumSignificantDigits = 4;
  if (amount >= 1 && amount < 100)
    formatOptions.minimumFractionDigits = 4;
  let out = new Intl.NumberFormat('en-US', formatOptions).format(amount);
  if (options.stripInsignificantZeros)
    out = out.replace(/\.0+$/, "");
  return out;
}

export function rounded(number, decimals) {
  let pow = Math.pow(10, decimals);
  return Math.round(number * pow) / pow;
}

export function filterTokens(list, term) {
  term = term.toLowerCase();
  return list.filter(function(token){
    return (token.name && token.name.toLowerCase().includes(term)) || (token.symbol && token.symbol.toLowerCase().includes(term));
  });
}
