import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { formattedAmount, Globals, isWrappedNativeCurrency } from '../../utils';
import AddLiquidity from '../Pools/AddLiquidity';

class PoolInfo extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.pool = props.pool;
    this.state = {
      exists: this.pool.balance > 0,
      receiveNative: false
    }
    this.removeLiquidity = this.removeLiquidity.bind(this);
    this.handleReceiveChange = this.handleReceiveChange.bind(this);
  }

  removeLiquidity() {
    let contract = this.pool.prepareRemove(this.state.receiveNative);
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute();
    if (this.props.closeCallback)
      this.props.closeCallback();
  }

  isNativePool() {
    return isWrappedNativeCurrency(this.pool.token0.address) || isWrappedNativeCurrency(this.pool.token1.address);
  }

  handleReceiveChange(e) {
    this.setState({ receiveNative: e.target.checked }, () => { console.log(this.state);});
  }

  render() {
    return(
      <>
        <div>
          {this.state.exists &&
            <>
              <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
                <div className="d-flex bg-opacity-100">
                  <div className="w-50 my-auto">
                    Amount<br/>
                    <span className="fs-2 fw-bold lh-1 text-gray-400">{formattedAmount(this.pool.token0Balance)}</span>
                  </div>
                  <div className="w-50 my-auto">
                    {this.pool.token0.name}<br/>
                    <span className="text-gray-500">({this.pool.token0.symbol})</span>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-grow my-2">
                <hr className='m-0 flex-grow-1 my-auto' />
                <div className='text-gray-400'>
                  <Plus size="32" color="#9AA4B2" />
                </div>
                <hr className='m-0 flex-grow-1 my-auto' />
              </div>


              <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
                <div className="d-flex bg-opacity-100">
                  <div className="w-50 my-auto">
                    Amount<br/>
                    <span className="fs-2 fw-bold lh-1 text-gray-400">{formattedAmount(this.pool.token1Balance)}</span>
                  </div>
                  <div className="w-50 my-auto">
                    {this.pool.token1.name}<br/>
                    <span className="text-gray-500">({this.pool.token1.symbol})</span>
                  </div>
                </div>
              </div>

              {this.isNativePool() &&
                <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4 mt-3">
                  <div className="d-flex bg-opacity-100">
                    <div className="w-100 my-auto">
                      <Form.Check type="switch" id="switch" label={`Receive ${Globals.state.native_currency}`} onChange={this.handleReceiveChange}/>
                    </div>
                  </div>
                </div>
              }

               <Button size='lg' className='btn btn-primary w-100 mt-3' onClick={this.removeLiquidity}>
                Remove Liquidity
              </Button>
            </>
          }
          {!this.state.exists &&
            <div className="mt-4">
              <AddLiquidity
                balances={this.props.balances}
                wallet={this.props.wallet}
                fromToken={this.pool.token0}
                toToken={this.pool.token1}
                onLiquidityAdded={this.props.closeCallback}
              />
            </div>
          }
        </div>
      </>
    );
  }
}

export default PoolInfo;
