import React from 'react';
import { formattedAmount } from '../../utils';

class AddLiquidityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return(
      <>
        <div className="bg-gray-800 rounded-3 p-3 border border-secondary mt-3">
          <div className="d-flex">
            <div className="text-gray-200 me-auto small">Rate</div>
            <div className="fw-bold small">
              1 {this.props.fromToken?.symbol} = {formattedAmount(this.props.price)} {this.props.toToken?.symbol} ({formattedAmount(1 / this.props.price)} {this.props.toToken?.symbol} per {this.props.fromToken?.symbol})
            </div>
          </div>
          <div className="d-flex">
            <div className="text-gray-200 me-auto small">Share of pool</div>
            <div className="fw-bold small">{this.props.share}%</div>
          </div>
        </div>
      </>
    );
  }
}

export default AddLiquidityInfo;
