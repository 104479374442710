import React, { Component } from 'react';
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons';

class TrendingValue extends Component {
  static defaultProps = {
    className: '',
    value: undefined,
    suffix: ''
  }

  render() {
    return (
      <div className={`${this.props.className} ${this.colorClass()}`}>
        {this.icon()}
        {this.value()}
      </div>
    );
  }

  icon() {
    if (this.props.value === undefined || this.props.value === 0) {
      return null;
    } else if (this.props.value > 0) {
      return (<ArrowUpShort className="mb-1" size={20} />);
    }
    return (<ArrowDownShort className="mb-1" size={20} />);
  }

  colorClass() {
    if (this.props.value === undefined || this.props.value === 0) {
      return 'text-muted';
    } else if (this.props.value > 0) {
      return 'text-success';
    }
    return 'text-danger';
  }

  value() {
    if (this.props.value === undefined) {
      return 'n/a';
    }
    return `${Math.abs(this.props.value)} ${this.props.suffix}`;
  }
}

export default TrendingValue;
