import DexversApi from '../DexversApi';

class WalletApi extends DexversApi {
  balanceUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("address", address);
    searchParams.append("chainId", chainId);
    searchParams.append("since", since);
    return `${this.baseUrl()}/wallet/balances?${searchParams.toString()}`;
  }

  balanceHistoryUrl(chainId, address, tokenAddress, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("since", since);
    return `${this.baseUrl()}/wallets/${chainId}/${address}/${tokenAddress}?${searchParams.toString()}`;
  }


  async balanceData(chainId, address, since) {
    if (since === undefined) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      since = d.toISOString();
    }
    return fetch(this.balanceUrl(chainId, address, since)).then((data) => data.json());
  }

  async balanceHistoryData(chainId, address, tokenAddress, since) {
    if (since === undefined) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      since = d.toISOString();
    }
    return fetch(this.balanceHistoryUrl(chainId, address, tokenAddress, since)).then((data) => data.json());
  }

}

export default WalletApi;