import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Swap from './Swap';
import Loading from '../Components/Loading';
import PendingTransactions from '../Components/PendingTransactions';
import { Button, Row, Col } from 'react-bootstrap';
import { Wallet2 } from 'react-bootstrap-icons';

class Embeds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: Object.fromEntries(new URLSearchParams(window.location.search))
    };
    this.update = this.update.bind(this);

    document.body.classList.add('embeded');
    document.body.classList.remove('loading');

    new ResizeObserver(this.update).observe(document.body);
    this.update();
  }

  update() {
    var payload = {
      message: 'dexvers:update',
      id: this.state.params.id,
      height: document.body.offsetHeight
    };
    window.parent.postMessage(payload, "*");
  }

  componentDidMount() {
    this.update();
  }

  noWallet() {
    return (
      <Row className='justify-content-center'>
        <Col className='col-12'>
          <h2 className="text-center fw-bold mt-5">Let's connect your Wallet!</h2>
          <div className="text-center text-gray-200">Get the exact token amount fast and with the least amount of fees. </div>
          <div className="text-center mt-5">
            <Button variant='primary' size='lg' className='w-50' onClick={() => this.props.actionCallback("connect-wallet")}>
                <Wallet2/> &nbsp;
                Connect your wallet
            </Button>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.props.status === undefined)
      return (<Loading className="w-50 mx-auto mt-5" message={false} />);

    if (!this.props.wallet.isConnected())
      return this.noWallet();

    return (
      <>
        <Router>
            <Routes>
              <Route path="/embed/swap" element={<Swap params={this.state.params} wallet={this.props.wallet} tokens={this.props.token} />}/>
            </Routes>
        </Router>
        <PendingTransactions />
      </>
    );
  }
}

export default Embeds;
