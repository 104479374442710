import DexversApi from './DexversApi';

class Exchanger extends DexversApi {
  constructor(address, web3) {
    super();
    this.address = address;
    this.loadBalances();
  }

  loadBalanceUrl() {
    return `${this.baseUrl()}/balances/${this.address}}`
  }

  loadBalances() {
    return true;
    // fetch(this.loadBalanceUrl())
    //   .then((data) => {
    //     if (data.ok) {
    //       return data.json();
    //     }
    //     return [];
    //   })
    //   .then((data) => {
    //     this.balances = data
    //   })
  }
}

export default Exchanger;
