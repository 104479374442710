import React, { Component } from 'react'
import { Button, Card } from 'react-bootstrap';
import GasslessApi from '../Apis/GasslessApi';
import GasslessTransaction from '../Blockchain/GasslessTransaction';
import TokenSelectForSwap from '../TokenSelectForSwap';
import { fromContractDecimals, toContractDecimals, Globals, formattedAmount } from '../utils';

export default class Gassless extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromToken: this.defaultFromToken(),
      inValue: 0,
      outValue: 0,
      canTrade: false,
      feePercentage: 1,
      result: undefined,
      inputValid: true
    }
    this.setInValue = this.setInValue.bind(this);
    this.setFromToken = this.setFromToken.bind(this);
    this.inputValidityChanged = this.inputValidityChanged.bind(this);
    this.swap = this.swap.bind(this);
    this.api = new GasslessApi();
  }

  async setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve));
  }

  defaultFromToken() {
    return Globals.wallet.dxvsToken();
  }

  setFromToken(address) {
    let token = this.props.wallet.token(address);
    this.setStateAsync({ fromToken: token })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountOut());
  }

  estimateAmountOut() {
    if(!this.state.canTrade) return;
    this.props.wallet.estimateGaslessAmountOut(this.state.fromToken.address, toContractDecimals(this.state.inValue, this.state.fromToken.decimals)).then((result) => {
        const out = fromContractDecimals(result.slice(-1), 18);
        console.log("Result:",result," Out:",out);
        this.setState({ outValue: out  });
    })
    .catch((err) => {
      console.log(err);
      this.setState({ outValue: 0 });
    });
  }

  setInValue(val) {
    this.setStateAsync({ inValue: val })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountOut());
  }

  inputValidityChanged(isValid) {
    this.setStateAsync({ inputValid: isValid })
      .then(() => this.setState({ canTrade: this.canTrade() }))
  }

  canTrade() {
    let valuesOk = this.state.inValue > 0;
    let inTokenOk = this.state.fromToken?.address !== '';
    return valuesOk && inTokenOk && this.state.inputValid && (!this.state.swapSuccess);
  }

  swap() {
    let contract = GasslessTransaction.prepare(
      this.state.fromToken,
      "1",
      this.props.wallet.address,
      this.props.wallet.gasslessAddress,
      this.state.inValue
    );
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute();
    this.setState({ inValue: 0, outValue: 0 });
  }

  render() {
    return (
      <>
        <div>
          <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch">
            <div className="rounded-3 border border-3 border-fuchsia me-3"></div>
            <TokenSelectForSwap
              key="input"
              className="flex-fill"
              balances={this.props.tokens}
              token={this.state.fromToken}
              value={this.state.inValue}
              setTokenCallback={this.setFromToken}
              setValueCallback={this.setInValue}
              validate={true}
              onValidityChanged={this.inputValidityChanged}
            />
          </div>
        </div>
        <div className="mt-3 p-3 rounded-3 bg-gray-800">
          <div className="mb-3">Transaction</div>
          <div className="d-flex">
            <div className="fs-6">Fee percentage</div>
            <div className="ms-auto text-end">{this.state.feePercentage}%</div>
          </div>
          <div className="d-flex">
            <div className="smaller">*) Note that the minimum fee is dependent on gas fee and prices</div>
          </div>
          <hr/>
          <div className="d-flex mt-3">
            <div className="fs-6">Expected Output</div>
            <div className="ms-auto text-end">{formattedAmount(this.state.outValue)} {this.props.wallet.native_currency}</div>
          </div>
        </div>
        <Button variant={this.state.canTrade ? 'primary' : 'secondary'} className='border-0 w-100 mt-4 p-3' onClick={this.swap} disabled={!this.state.canTrade}>SWAP</Button>
      </>
    )
  }
}
