import Transaction from "./Transaction";
import { GasslessSign } from "./GasslessSign";
import { GasslessExchange } from "./GasslessExchange";

class GasslessTransaction  {
  static prepare(token, version, owner, spender, value) {
    return new GasslessTransaction(token, version, owner, spender, value);
  }

  constructor(token, version, owner, spender, value) {
    this.transaction = new Transaction();
    this.transaction.add(new GasslessSign(token, version, owner, spender, value), {});
    this.transaction.add(new GasslessExchange(token), {});
  }

  execute() {
    return this.transaction.execute();
  }
}

export default GasslessTransaction;
