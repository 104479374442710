import { Globals } from '../utils';
import AbstractContract from './AbstractContract';

export class GasslessSign extends AbstractContract {
  static displayName = 'GasslessSign';

  constructor(token, version, owner, spender, value) {
    super(token, version, owner, spender, value)
    this.token = token;
  }

  execute() {
    this.promise.eventEmitter.emit("sending");
    Globals.wallet.EipSign(...this.arguments)
      .then((result) => {
        this.promise.resolve(result);
      })
      .catch((error) => {
        this.promise.reject(error);
      });
  }
}

export default GasslessSign;
