class Boost {
  constructor(data, wallet) {
    this.data = data;
    this.wallet = wallet;
  }

  get target() {
    return 5000000.0;
  }

  get progress() {
    return ((this.reserve1 * 2) / this.target * 100).toFixed(2);
  }

  get name() {
    return this.token0.name + " - " + this.token1.name + " LP";
  }

  get symbol() {
    return this.token0.symbol + "-" + this.token1.symbol + "-LP";
  }

  get token0() {
    return this.data.token0;
  }

  get token1() {
    return this.data.token1;
  }

  get address() {
    return this.data.address;
  }

  get reserve0() {
    return this.wallet.web3.utils.fromWei(this.data.reserve0);
  }

  get reserve1() {
    return this.wallet.web3.utils.fromWei(this.data.reserve1);
  }

  get supply() {
    return this.wallet.web3.utils.fromWei(this.data.supply);
  }

  get balance() {
    return this.wallet.web3.utils.fromWei(this.data.balance);
  }

  get balanceFloat() {
    return parseFloat(this.balance);
  }

  get balanceWei() {
    return this.data.balance;
  }

  get percentage() {
    return (this.balance / this.supply * 100).toFixed(2);
  }

  get token0Balance() {
    return this.reserve0 * this.balance / this.supply;
  }

  get token1Balance() {
    return this.reserve1 * this.balance / this.supply;
  }

  reward(value) { // TODO: compute with Bignumber
    // this is the amount of token1 staked
    let token1StakeAmount = this.reserve1 * value / this.supply;
    // this is the total stake amount in token 1 (multiplied by 2 as stake is 1-to-1)
    let totalStakeAmountInToken1 = token1StakeAmount * 2 * 2;
    // the reward is 100% of the staked amount
    return totalStakeAmountInToken1;
  }

  // async remove() {
  //   return this.getApproval()
  //     .then(() => { return this.getBlock(); })
  //     .then(() => { return this.removeLiquidity(); })
  // }

  // async getApproval() {
  //   let contract = new this.wallet.web3.eth.Contract(ERC20.abi, this.address);
  //   return contract.methods.approve(this.wallet.routerAddress, this.balanceWei).send({ from: this.wallet.address });
  // }

  // async getBlock() {
  //   this.blockNumber = await this.wallet.web3.eth.getBlockNumber();
  //   return this.wallet.web3.eth.getBlock(this.blockNumber).then((block) => {
  //     this.block = block;
  //   });
  // }

  // async removeLiquidity() {
  //   if(this.oneTokenIsETH) {
  //     return this.removeLiquidityETH();
  //   } else {
  //     return this.removeLiquidityByPair();
  //   }
  // }

  // async removeLiquidityByPair() {
  //   let contract = new this.wallet.web3.eth.Contract(Dexvers.dexversRouterAbi, this.wallet.routerAddress);
  //   let currentDate = new Date();
  //   let minutesToAdd = 5;
  //   let futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  //   return contract.methods.removeLiquidity(
  //     this.token0.address, //tokens to remove
  //     this.token1.address,
  //     this.balanceWei, // liquidity amount to remove
  //     toContractDecimals(0), // min token0 to receive
  //     toContractDecimals(0), //min token1 to receive
  //     this.wallet.address, // where tokens should be sent
  //     +futureDate // timeout
  //   ).send({
  //     from: this.wallet.address
  //   })
  // }

  // async removeLiquidityETH() {
  //   let contract = new this.wallet.web3.eth.Contract(Dexvers.dexversRouterAbi, this.wallet.routerAddress);
  //   let currentDate = new Date();
  //   let minutesToAdd = 5;
  //   let futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  //   return contract.methods.removeLiquidity(
  //     this.nonETHToken.address,
  //     this.balanceWei, // liquidity amount to remove
  //     toContractDecimals(0), // min token0 to receive
  //     toContractDecimals(0), //min token1 to receive
  //     this.wallet.address, // where tokens should be sent
  //     +futureDate // timeout
  //   ).send({
  //     from: this.wallet.address
  //   })
  // }
}

export default Boost;
