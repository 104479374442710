import Contracts from "../Blockchain/Contracts";
import Deadline from "../Blockchain/Deadline";
import { Globals, isNativeCurrency, toContractDecimals } from "../utils";

class Liquidator {
  constructor(wallet, token0, token1, amount0, amount1, minAmount0, minAmount1) {
    this.wallet = wallet;
    this.token0 = wallet.token(token0);
    this.token1 = wallet.token(token1);
    this.amount0 = toContractDecimals(amount0, this.token0.decimals);
    this.amount1 = toContractDecimals(amount1, this.token1.decimals);
    this.minAmount0 = toContractDecimals(minAmount0, this.token0.decimals);
    this.minAmount1 = toContractDecimals(minAmount1, this.token1.decimals);
  }

  prepare() {
    this.contract = this.build();
  }

  async execute() {
    return this.contract.execute();
  }

  build() {
    if (this.isToETH()) return this.addLiquidityToETH();
    if (this.isFromETH()) return this.addLiquidityFromETH();
    return this.addLiquidity();
  }

  get transaction() {
    return this.contract.transaction;
  }

  addLiquidityToETH() {
    return Contracts.router.methods
      .addLiquidityETH(
        this.token0.address,
        this.amount0,
        this.minAmount0,
        this.minAmount1,
        Globals.address,
        Deadline.default()
      )
      .requiresApproval(this.token0.address, this.amount0)
      .prepare({ from: this.wallet.address, value: this.amount1 });
  }

  addLiquidityFromETH() {
    return Contracts.router.methods
      .addLiquidityETH(
        this.token1.address,
        this.amount1,
        this.minAmount1,
        this.minAmount0,
        Globals.address,
        Deadline.default()
      )
      .requiresApproval(this.token1.address, this.amount1)
      .prepare({ from: this.wallet.address, value: this.amount0 });
  }

  addLiquidity() {
    return Contracts.router.methods
      .addLiquidity(
        this.token0.address,
        this.token1.address,
        this.amount0,
        this.amount1,
        this.minAmount0,
        this.minAmount1,
        Globals.address,
        Deadline.default()
      )
      .requiresApproval(this.token0.address, this.amount0)
      .requiresApproval(this.token1.address, this.amount1)
      .prepare({ from: this.wallet.address });
  }

  isToETH() {
    return isNativeCurrency(this.token1.address);
  }

  isFromETH() {
    return isNativeCurrency(this.token0.address);
  }
}

export default Liquidator;
