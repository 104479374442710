import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Globals } from '../utils';
import Farms from './Home/Farms';
import Pools from './Home/Pools';
import TopPools from './TopPools';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: [],
      stakes: []
    };
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    return Promise.all([
      this.loadPools(),
      this.loadStakes()
    ]);
  }

  async loadPools() {
    return this.props.wallet.pools.load()
      .then(()=> {
        this.setState({ pools: this.props.wallet.pools.list });
      });
  }

  async loadStakes() {
    return this.props.wallet.stakes.load()
      .then(()=> {
        this.setState({ stakes: this.props.wallet.stakes.list });
      });
  }

  render() { return(
    <>
      <Row className='justify-content-center'>
        <Col className='col-12 col-lg-6 mb-3'>
          <Pools
            balances={this.props.balances}
            wallet={this.props.wallet}
            address={this.props.address}
            pools={this.state.pools}
            refresh={this.refresh}
          />
        </Col>
        <Col className='col-12 col-lg-6 mb-3'>
          <Farms
            balances={this.props.balances}
            wallet={this.props.wallet}
            address={this.props.address}
            stakes={this.state.stakes}
            refresh={this.refresh}
          />
        </Col>
        <Col className='col-12'>
          {Globals.state.walletStatus === 'connected' &&
            <TopPools
              balances={this.props.balances}
              wallet={this.props.wallet}
              address={this.props.address}
            />
          }
        </Col>
      </Row>
    </>
  )}
}

export default Home;
