import React from 'react';
import SimpleTooltip from './SimpleTooltip';

class TokenIcon extends React.Component {
  static defaultProps = {
    className: '',
    size: 32,
    token: undefined
  }

  static url = "https://icons.dexvers.cbix.io/";
  static defaultUrl = "https://icons.dexvers.cbix.io/default.png";


  constructor(props) {
    super(props);
    this.state = {
      url: props.token !== undefined ? this.url() : ""
    }
    this.imageLoadError = this.imageLoadError.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token)
      this.setState({ url: this.url() });
  }

  url() {
    if(!this.props.token?.symbol)
      return TokenIcon.defaultUrl;
    return TokenIcon.url + this.props.token.symbol.toLowerCase() + ".png";
  }

  imageLoadError(e) {
    if (this.state.url !== TokenIcon.defaultUrl) {
      this.setState({ url: TokenIcon.defaultUrl });
    }
  }

  onClick(e) {
    if (e.shiftKey) {
      // copy this.props.token?.address to clipboard
      navigator.clipboard.writeText(this.props.token?.address);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  render() {
    if (this.props.token === undefined)
      return "";
    return (
      <div className={'rounded-pill bg-white shadow lh-1 ' + this.props.className} onClick={this.onClick}>
        <SimpleTooltip tooltip={this.props.token?.address} tooltipClassName="smaller">
          <img src={this.state.url} onError={this.imageLoadError} width={this.props.size} height={this.props.size} alt={this.props.token?.name} />
        </SimpleTooltip>
      </div>
    );
  }
}

export default TokenIcon;
