import React from 'react';
import { Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import StakeInfo from './StakeInfo';
import Earnings from './Earnings';

class StakeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tab: props.tab || 'staking'
    }
    this.tabs = [
      { id: 'stake', label: 'Stake' },
      { id: 'earnings', label: 'Earnings' },
    ];
    this.close = this.close.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  close() {
    this.setState({ show: false });
    if(this.props.closeCallback)
      this.props.closeCallback();
  }

  setTab(tab) {
    this.setState({ tab: tab });
  }

  render() {
    return(
      <>
        <Modal centered show={this.state.show} onHide={this.close}>
          <Modal.Header closeButton>
            {this.props.pool.token0.symbol}-{this.props.pool.token1.symbol}
          </Modal.Header>
          <Modal.Body>
            <ToggleButtonGroup type="radio" name="tab" className='w-100 mb-3' onChange={this.setTab} defaultValue={this.state.tab}>
              {this.tabs.map((tab) =>
                <ToggleButton id={tab.id} key={tab.id} value={tab.id}
                              variant="outline-secondary">
                  {tab.label}
                </ToggleButton>
              )}
            </ToggleButtonGroup>

            <div className="">
              {this.state.tab === 'stake' &&
                <StakeInfo
                  stake={this.props.stake}
                  wallet={this.props.wallet}
                />
            }

              {this.state.tab === 'earnings' &&
                <Earnings
                  stake={this.props.stake}
                  wallet={this.props.wallet}
                />
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default StakeView;
