/*
Generate the data with

require 'json'; require 'open-uri'; data = %w(1 56 137 31338 43114).map { |chain_id| [chain_id, JSON.parse(URI.parse("https://backend.dexvers.cbix.io/api/v1/s/tokens/list/default?chainId=" + chain_id).read).first(25)]; }.to_h; data["31337"] = data["31338"]; puts JSON.pretty_generate(data)

*/
const tokens = {
  "80001": [
    {
      "name": "MATIC",
      "symbol": "MATIC",
      "address": "0x1234123412341234123412341234123412341234",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dexvers",
      "symbol": "DXVS",
      "address": "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "TEST Connext",
      "symbol": "TEST",
      "address": "0xeDb95D8037f769B72AAab41deeC92903A98C9E16",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USDC",
      "symbol": "USDC",
      "address": "0x30D30AEB0f272241acA538Ea1cec60eA0f7F6E6c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Wrapped MATIC",
      "symbol": "WMATIC",
      "address": "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "5": [
    {
      "name": "ETH",
      "symbol": "ETH",
      "address": "0x0000000000000000000000000000000000001010",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dexvers",
      "symbol": "DXVS",
      "address": "0xA65aAb7204A508059164F6ab9D37ab6961A77b2E",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "TEST Connext",
      "symbol": "TEST",
      "address": "0x7ea6eA49B0b0Ae9c5db7907d139D9Cd3439862a1",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USDC",
      "symbol": "USDC",
      "address": "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "WETH",
      "symbol": "WETH",
      "address": "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      "decimals": 18,
      "unit": "ether"
    },
  ],
  "1": [
    {
      "name": "ETH",
      "symbol": "ETH",
      "address": "0x1234123412341234123412341234123412341234",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD//C",
      "symbol": "USDC",
      "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "SHIBA INU",
      "symbol": "SHIB",
      "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "SUKU",
      "symbol": "SUKU",
      "address": "0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "CRPT",
      "symbol": "CRPT",
      "address": "0x08389495d7456e1951ddf7c3a1314a4bfb646d8b",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "MATH Token",
      "symbol": "MATH",
      "address": "0x08d967bb0134f2d07f7cfb6e246680c53927dd30",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Wrapped MIR Token",
      "symbol": "MIR",
      "address": "0x09a3ecafa817268f77be1283176b946c4ff2e608",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "district0x Network Token",
      "symbol": "DNT",
      "address": "0x0abdace70d3790235af448c88547603b945604ea",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "API3",
      "symbol": "API3",
      "address": "0x0b38210ea11411557c13457d4da7dc6ea731b88a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Basic Attention Token",
      "symbol": "BAT",
      "address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Decentraland MANA",
      "symbol": "MANA",
      "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "1INCH Token",
      "symbol": "1INCH",
      "address": "0x111111111117dc0aa78b770fa6a738034120c302",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "DefiPulse Index",
      "symbol": "DPI",
      "address": "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Gala",
      "symbol": "GALA",
      "address": "0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
      "decimals": 8,
      "unit": null
    },
    {
      "name": "Numeraire",
      "symbol": "NMR",
      "address": "0x1776e1f26f98b1a5df9cd347953a26dd3cb46671",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Audius",
      "symbol": "AUDIO",
      "address": "0x18aaa7115705e8be94bffebde57af9bfc265b998",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Reputation",
      "symbol": "REP",
      "address": "0x1985365e9f78359a9b6ad760e32412f4a445e862",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Keep3rV1",
      "symbol": "KP3R",
      "address": "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Bancor Network Token",
      "symbol": "BNT",
      "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Reputation",
      "symbol": "REPv2",
      "address": "0x221657776846890989a759ba2973e427dff5c9bb",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "56": [
    {
      "name": "BNB",
      "symbol": "BNB",
      "address": "0x1234123412341234123412341234123412341234",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BUSD Token",
      "symbol": "BUSD",
      "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Ethereum Token",
      "symbol": "ETH",
      "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Polkadot Token",
      "symbol": "DOT",
      "address": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Cardano Token",
      "symbol": "ADA",
      "address": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dai Token",
      "symbol": "DAI",
      "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BTCB Token",
      "symbol": "BTCB",
      "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Wrapped BNB",
      "symbol": "WBNB",
      "address": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "AlpacaToken",
      "symbol": "ALPACA",
      "address": "0x8f0528ce5ef7b51152a59745befdd91d97091d2f",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Venus",
      "symbol": "XVS",
      "address": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Mu Coin",
      "symbol": "MU",
      "address": "0xd036414fa2bcbb802691491e323bff1348c5f4ba",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "LostToken",
      "symbol": "LOST",
      "address": "0x449674b82f05d498e126dd6615a1057a9c088f2c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "$GREASE",
      "symbol": "$GREASE",
      "address": "0x96d58f4646e988c236a4d2bf57a0468e16e2ddfc",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "TNT",
      "symbol": "TNT",
      "address": "0x838a68e0e6d193a10198984bdeedc356bd82a15d",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Bold Point Token",
      "symbol": "BPT",
      "address": "0x1111111111182587795ef1098ac7da81a108c97a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Grateful Gangsters",
      "symbol": "G$G",
      "address": "0xc2d2570c6359ffe83b1e7fdfcd5328f088c1795a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dragon Gold Token",
      "symbol": "DGold",
      "address": "0x1369dba63f5d4e9734f8d2b4ddf0ea7dc2c712e2",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xba7deebbfc5fa1100fb055a87773e1e99cd3507a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "PenguinToken",
      "symbol": "PEFI",
      "address": "0xe896cdeaac9615145c0ca09c8cd5c25bced6384c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD Coin",
      "symbol": "USDC.e",
      "address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "sled.finance",
      "symbol": "SFI",
      "address": "0x1f1fe1ef06ab30a791d6357fdf0a7361b39b1537",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "YetiSwap",
      "symbol": "YTS",
      "address": "0x488f73cddda1de3664775ffd91623637383d6404",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BillMaher.Finance",
      "symbol": "BMF",
      "address": "0x9e7890b2e2de38d764a29b0de1e2b9b37c90ddb9",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BirdToken",
      "symbol": "BIRD",
      "address": "0x4480b4ddfb15fe6518817ef024d8b493aff2db54",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "137": [
    {
      "name": "USD Coin (PoS)",
      "symbol": "USDC",
      "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "Dexvers",
      "symbol": "DXVS",
      "address": "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Matic Token",
      "symbol": "MATIC",
      "address": "0x0000000000000000000000000000000000001010",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "(PoS) Tether USD",
      "symbol": "USDT",
      "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "(PoS) Dai Stablecoin",
      "symbol": "DAI",
      "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Aave (PoS)",
      "symbol": "AAVE",
      "address": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "ChainLink Token",
      "symbol": "LINK",
      "address": "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "(PoS) Wrapped BTC",
      "symbol": "WBTC",
      "address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
      "decimals": 8,
      "unit": null
    },
    {
      "name": "Decentraland MANA",
      "symbol": "MANA",
      "address": "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "KROWN",
      "symbol": "KRW",
      "address": "0x6c3b2f402cd7d22ae2c319b9d2f16f57927a4a17",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "RoseFinance",
      "symbol": "ROF",
      "address": "0x10a716e0d771356a25dca180f27d2a48dafb80fd",
      "decimals": 10,
      "unit": null
    },
    {
      "name": "PolyBeans",
      "symbol": "BEAN",
      "address": "0x9b5d83ccb007958a69fa786d623a4f0043eabc72",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "AdEx Network",
      "symbol": "ADX",
      "address": "0xc8e36f0a44fbeca89fdd5970439cbe62eb4b5d03",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Grosh",
      "symbol": "GROSH",
      "address": "0xdddca1d1fd4e72b85b7b95f07651ab36b62f69e9",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Apple Finance V2",
      "symbol": "AAPL",
      "address": "0xabd1cbbf2a7f53c54946ce3d1557db625c911126",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Metronotes",
      "symbol": "XMN",
      "address": "0x98d0274895a6187e095859d80b6a1248a7fefb98",
      "decimals": 9,
      "unit": "nano"
    },
    {
      "name": "Algebra",
      "symbol": "ALGB",
      "address": "0x0169ec1f8f639b32eec6d923e24c2a2ff45b9dd6",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "PolyRiver Token",
      "symbol": "RIVER",
      "address": "0x5cb9b05e162701949ac62dad44feb5c0457cf2c0",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Opu Coin (PoS)",
      "symbol": "OPU",
      "address": "0x7ff2fc33e161e3b1c6511b934f0209d304267857",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Global currency",
      "symbol": "GLCY",
      "address": "0x8dde105ede886b2d943f3caef3ea4dbfb6558ab6",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "aaxexchange.org",
      "symbol": "AAX",
      "address": "0x8ae127d224094cb1b27e1b28a472e588cbcc7620",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "FaaS Inu",
      "symbol": "FINU",
      "address": "0x16ea069da231267eb3af9a847d48369f1789e1b0",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "GrafenoCoin",
      "symbol": "GFNC",
      "address": "0x06176ae811d20c404b729dcd48e839b70ba3acfa",
      "decimals": 8,
      "unit": null
    },
    {
      "name": "Airbaltic points",
      "symbol": "AIRP",
      "address": "0x20eafff3265a9806262536ebd47f3b20ab80e889",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "31338": [
    {
      "name": "Token1",
      "symbol": "TK1",
      "address": "0x5FbDB2315678afecb367f032d93F642f64180aa3",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Token2",
      "symbol": "TK2",
      "address": "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "WETH",
      "symbol": "WETH",
      "address": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "Dexvers",
      "symbol": "DXVS",
      "address": "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 1",
      "symbol": "ET1",
      "address": "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 2",
      "symbol": "ET2",
      "address": "0x9A676e781A523b5d0C0e43731313A708CB607508",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 3",
      "symbol": "ET3",
      "address": "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 4",
      "symbol": "ET4",
      "address": "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "43114": [
    {
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "Avalanche Network",
      "symbol": "AVAX",
      "address": "0x1234123412341234123412341234123412341234",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "TetherToken",
      "symbol": "USDt",
      "address": "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Wrapped Ether",
      "symbol": "WETH.e",
      "address": "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dai Stablecoin",
      "symbol": "DAI.e",
      "address": "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Bold Point Token",
      "symbol": "BPT",
      "address": "0x1111111111182587795ef1098ac7da81a108c97a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Grateful Gangsters",
      "symbol": "G$G",
      "address": "0xc2d2570c6359ffe83b1e7fdfcd5328f088c1795a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dragon Gold Token",
      "symbol": "DGold",
      "address": "0x1369dba63f5d4e9734f8d2b4ddf0ea7dc2c712e2",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xba7deebbfc5fa1100fb055a87773e1e99cd3507a",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "PenguinToken",
      "symbol": "PEFI",
      "address": "0xe896cdeaac9615145c0ca09c8cd5c25bced6384c",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD Coin",
      "symbol": "USDC.e",
      "address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "sled.finance",
      "symbol": "SFI",
      "address": "0x1f1fe1ef06ab30a791d6357fdf0a7361b39b1537",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "YetiSwap",
      "symbol": "YTS",
      "address": "0x488f73cddda1de3664775ffd91623637383d6404",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BillMaher.Finance",
      "symbol": "BMF",
      "address": "0x9e7890b2e2de38d764a29b0de1e2b9b37c90ddb9",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BirdToken",
      "symbol": "BIRD",
      "address": "0x4480b4ddfb15fe6518817ef024d8b493aff2db54",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "SNRW.e",
      "symbol": "SNRW",
      "address": "0xe677bdc4f5ac7ef7701f4199f25abad34d41fd7f",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Carbon Fiber Landfill",
      "symbol": "CFL",
      "address": "0xd58c88be5e7bd6fd45a64b6bde61f8027d26f705",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "iDeFiYieldProtocol",
      "symbol": "iDYP",
      "address": "0xbd100d061e120b2c67a24453cf6368e63f1be056",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Rocky Doge",
      "symbol": "ROCKY",
      "address": "0x3960716779870ef8757aeb43f3c4f0c30cb2d557",
      "decimals": 9,
      "unit": "nano"
    },
    {
      "name": "Blood",
      "symbol": "BLOOD",
      "address": "0x54beed78a25f59011437c10d91cf26e736e00523",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "XEN Crypto",
      "symbol": "aXEN",
      "address": "0xc0c5aa69dbe4d6dddfbc89c0957686ec60f24389",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "BUSD Token",
      "symbol": "BUSD",
      "address": "0x9c9e5fd8bbc25984b178fdce6117defa39d2db39",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Tether USD",
      "symbol": "USDT.e",
      "address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Bonfire",
      "symbol": "?",
      "address": "0xa0a924dcb97a597351a5c3787234b706845e7510",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Covenant",
      "symbol": "COVT",
      "address": "0x47afacadc6773dc4b8177bc7ac3dd96383800380",
      "decimals": 18,
      "unit": "ether"
    }
  ],
  "31337": [
    {
      "name": "Token1",
      "symbol": "TK1",
      "address": "0x5FbDB2315678afecb367f032d93F642f64180aa3",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "Token2",
      "symbol": "TK2",
      "address": "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "WETH",
      "symbol": "WETH",
      "address": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
      "decimals": 6,
      "unit": "mwei"
    },
    {
      "name": "Dexvers",
      "symbol": "DXVS",
      "address": "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 1",
      "symbol": "ET1",
      "address": "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 2",
      "symbol": "ET2",
      "address": "0x9A676e781A523b5d0C0e43731313A708CB607508",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 3",
      "symbol": "ET3",
      "address": "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
      "decimals": 18,
      "unit": "ether"
    },
    {
      "name": "External Token 4",
      "symbol": "ET4",
      "address": "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
      "decimals": 18,
      "unit": "ether"
    }
  ]
};

export default tokens;
