import React from 'react';
import {ComposedChart, ResponsiveContainer, XAxis, Tooltip, CartesianGrid, Line, YAxis} from 'recharts';
import Charts from './Charts';

class DoubleLineChart extends React.Component {
  render() {
    return (
      <ResponsiveContainer height={250} >
        <ComposedChart data={this.props.data}>
          <XAxis dataKey="date" tick={Charts.tickStyle} tickFormatter={Charts.shortDateFormatter}/>
          <YAxis  tick={Charts.tickStyle} type="number" tickFormatter={Charts.numberFormatter} domain={[0, dataMax => (dataMax * 1.5)]} />
          <Tooltip labelFormatter={Charts.longDateFormatter} formatter={Charts.numberFormatter} labelClassName="text-gray-600" />
          <CartesianGrid stroke={Charts.gridStrikeColor} />
          <Line  dataKey={this.props.dataKey} stroke={Charts.line2Color} strokeWidth={2}  name={this.props.dataName} domain={[0, dataMax => (dataMax * 1.5)]} dot={false}/>
          <Line  dataKey={this.props.altDataKey} stroke={Charts.lineColor} strokeWidth={2}  name={this.props.altDataName} domain={[0, dataMax => (dataMax * 1.5)]} dot={false}/>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default DoubleLineChart;
