import React from 'react';
import GraphData from '../Apis/GraphData';
import TransactionList from '../Components/TransactionList.jsx';

class TokenTransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transfers: [],
      hidden: false
    }
    this.api = new GraphData();
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokenAddress !== this.props.tokenAddress) {
      this.getData();
    }
  }

  getData() {
    this.api.transferData(this.props.chainId, this.props.tokenAddress).then((data) => {
      this.setState({transfers: data});
    }).catch((e) => console.error(e));
  }

  render() {
    return (
      <>
        <TransactionList data={this.state.transfers} wallet={this.props.wallet} setWalletCallback={this.props.setWalletCallback} explorerUrl={this.props.explorerUrl}/>
      </>
    );
  }
}

export default TokenTransactionHistory;
