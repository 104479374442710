import DexversApi from "../DexversApi";
import ApiPoller from "./ApiPoller";

class GasslessApi extends DexversApi {
  transactionURL(chainId, owner, token, amount,r,s,v, deadline) {
    const searchParams = new URLSearchParams();
    searchParams.append("chainId", chainId);
    searchParams.append("owner", owner);
    searchParams.append("token", token);
    searchParams.append("amount", amount);
    searchParams.append("r", r);
    searchParams.append("s", s);
    searchParams.append("v", v);
    searchParams.append("deadline", deadline);
    return `${this.gasslessUrl()}/exchange_for_native?${searchParams.toString()}`;
  }

  transactionPollingURL(jobId) {
    const searchParams = new URLSearchParams();
    searchParams.append("job_id", jobId);
    return `${this.gasslessUrl()}/exchange_for_native_status?${searchParams.toString()}`;
  }


  async sendTransaction(chainId, wallet, token, amount, r, s, v, deadline) {
    return fetch(this.transactionURL(chainId, wallet, token, amount, r, s, v, deadline),{ method: 'POST'})
    .then(response => response.json())
    .then((response) => {
        console.log(response);
        if (response.status !== "failed") {
          let pooler = new ApiPoller(this.transactionPollingURL(response.job_id));
          return pooler.startPolling();
        } else {
          return Promise.reject(response);
        }
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
}

export default GasslessApi;
