import React from 'react';
import If from '../Components/If';
import { formattedAmount } from '../utils';

class BalanceChangeBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeDirection: this.props.change > 0 ? 'up' : 'down',
    }
  }
  render() {
    return (
      <>
        <If condition={this.props.change > 0}>
          <p className='text-success'>
            {formattedAmount(this.props.changePercentage)}
          </p>
        </If>
        <If condition={this.props.change == 0}>
          <p className='text-secondary'>
            {formattedAmount(this.props.changePercentage)}
          </p>
        </If>
        <If condition={this.props.change < 0}>
          <p className='text-warning'>
            {formattedAmount(this.props.changePercentage)}
          </p>
        </If>
      </>
    );
  }
}

export default BalanceChangeBadge;
