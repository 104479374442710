import { CheckCircleFill } from "react-bootstrap-icons";
import { formattedAmount, fromContractDecimals, Globals } from "../../../utils";

const GasslessSuccess = function({ transaction }) {
  console.log("GasslessSuccess", transaction);
  let params =   transaction.calls.at(0).method.meta.methodParams;
  let result = transaction.calls.at(-1).result;

  return (
    <>

      <div className="d-flex py-3">
        <div className="m-auto position-relative">
          <CheckCircleFill size="40" className="rounded-circle overflow-hidden text-success-light bg-success" />
        </div>
      </div>
      <div className="text-center mb-4">
        <h5 className="text-center">Transaction completed!</h5>
      </div>

      <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3 border border-secondary mt-3">
        <div className="me-auto text-center fw-medium mb-3">{Globals.wallet.native_currency} Received</div>
        <div className="text-center fs-2 fw-bold mb-3">{formattedAmount(fromContractDecimals(result.amount))}</div>
      </div>

      <div className="bg-gray-800 rounded-3 p-3 border border-secondary mt-3">
        <div className="fw-medium fs-5">Swap Details</div>
        <hr className="text-gray-300" />

        <div className="d-flex">
          <div className="text-gray-200 me-auto small">Input amount</div>
          <div className="fw-bold small">
            {formattedAmount(params.value)} {params.token.symbol}
          </div>
        </div>
        <hr className="text-gray-300" />
        <div className="d-flex">
          <div className="text-gray-200 me-auto small">Swapped {Globals.wallet.native_currency}</div>
          <div className="fw-bold small">{formattedAmount(fromContractDecimals(result.total))}</div>
        </div>
        <hr className="text-gray-300" />
        <div className="d-flex">
          <div className="text-gray-200 me-auto small">Fee</div>
          <div className="fw-bold small">{formattedAmount(fromContractDecimals(result.fee))}</div>
        </div>
      </div>
    </>
  );
};

export default GasslessSuccess;
