import { toContractDecimals, Globals } from "../utils";
import { CrosschainWatch } from "../Blockchain/CrosschainWatch";
import Transaction from "../Blockchain/Transaction";
import Approval from "../Blockchain/Approval";
import GasslessTransaction from "../Blockchain/GasslessTransaction";

const addressZero = "0x0000000000000000000000000000000000000000";

class XSwapper {
  constructor(value, sourceBlockchain, sourceToken, proxySourceToken, destinationBlockchain, destinationToken, proxyDestinationToken, estimate, feesPayment) {
    this.value = value;
    this.sourceBlockchain = sourceBlockchain;
    this.sourceToken = sourceToken;
    this.proxySourceToken = proxySourceToken;
    this.destinationBlockchain = destinationBlockchain;
    this.destinationToken = destinationToken;
    this.proxyDestinationToken = proxyDestinationToken;
    this.estimate = estimate;
    this.feesPayment = feesPayment;
  }

  get valueInWei() {
    return toContractDecimals(this.value, this.sourceToken.decimals);
  }

  get relayerFeeInWei() {
    return toContractDecimals(this.estimate.cross.relayerFee, 18);
  }

  prepare() {
    this.transaction = new Transaction();
    this.build();
  }

  async execute() {
    return this.transaction.execute();
  }

  build() {
    let crosschain = this.sourceBlockchain.contracts.crosschain;

    if (this.feesPayment.type === 'token') {
      let gassless = GasslessTransaction.prepare(
        this.feesPayment.token,
        "1",
        Globals.address,
        this.sourceBlockchain.config.contracts.gassless,
        this.feesPayment.amount
      );
      this.transaction.merge(gassless.transaction);
    }

    this.transaction
      .add(
        new Approval(this.sourceToken.address, this.valueInWei, crosschain.options.address),
        { from: Globals.address }
      );

    this.transaction
      .add(
        crosschain.methods.xSwap(
          this.sourceChainParams(),
          this.crosschainParams(),
          this.destinationChainParams()
        ),
        { from: Globals.address, value: this.relayerFeeInWei }
      );

    this.transaction
      .add(
        new CrosschainWatch(this.sourceBlockchain, this.destinationBlockchain),
        {}
      );
  }

  sourceChainParams() {
    if (this.proxySourceToken === undefined) {
      return [[], addressZero, 0, 0];
    } else {
      return [
        this.estimate.src.path,
        this.estimate.src.routerAddress,
        toContractDecimals(this.estimate.src.amountIn, this.sourceToken.decimals),
        toContractDecimals(this.estimate.src.slippage, this.proxySourceToken.decimals)
      ];
    }
  }

  crosschainParams() {
    let token = this.proxySourceToken || this.sourceToken;
    let recipient = this.proxyDestinationToken !== undefined ? this.destinationBlockchain.config.contracts.crosschain : Globals.address;
    return [
      token.address,
      toContractDecimals(this.estimate.cross.amountIn, token.decimals),
      recipient,
      this.destinationBlockchain.config.connextDomainID,
      100, // slippage
      this.relayerFeeInWei
    ];
  }

  destinationChainParams() {
    if (this.proxyDestinationToken === undefined) {
      return [[], addressZero, 0, 0, addressZero];
    } else {
      return [
        this.estimate.dst.path,
        this.estimate.dst.routerAddress,
        toContractDecimals(this.estimate.dst.amountIn, this.proxyDestinationToken.decimals),
        toContractDecimals(this.estimate.dst.slippage, this.destinationToken.decimals),
        Globals.address
      ];
    }
  }
}

export default XSwapper;
