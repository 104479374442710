import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import Home from './Earn/Home';
import Farms from './Earn/Home/Farms';
import Pools from './Earn/Home/Pools';
import Index from './LiquidityBoost/Index';

class Earn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'home'
    };
    this.tabs = [
      { id: "home", label: "Overview" },
      { id: "pools", label: "Liquidity Pools" },
      { id: "farms", label: "Farms" },
      // { id: "liquidity-boost", label: "Liquidity Boost" }
    ];
    this.setTab = this.setTab.bind(this);
  }

  setTab(tab) {
    this.setState({ tab: tab });
  }

  render() {
    return(
      <div>
        <div className='mb-5'>
          <Nav variant='pills' className='justify-content-center'>
            {this.tabs.map((tab, index) =>
              <Nav.Item key={tab.id} className="col-4 col-lg-2 text-center">
                <Nav.Link active={this.state.tab === tab.id} onClick={()=> this.setTab(tab.id)}>{tab.label}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </div>

        {this.state.tab === 'home' &&
          <Home
            balances={this.props.balances}
            wallet={this.props.wallet}
            address={this.props.address}
          />
        }

        {this.state.tab === 'pools' &&
          <Row className='justify-content-center'>
            <Col className='col-12 col-lg-8'>
              <Pools
                balances={this.props.balances}
                wallet={this.props.wallet}
                address={this.props.address}
            />
            </Col>
          </Row>
        }

        {this.state.tab === 'farms' &&
          <Row className='justify-content-center'>
            <Col className='col-12 col-lg-8'>
            <Farms
              balances={this.props.balances}
              wallet={this.props.wallet}
              address={this.props.address}
            />
            </Col>
          </Row>
        }

        {this.state.tab === 'liquidity-boost' &&
          <Index
            actionCallback={this.performAction}
            balances={this.props.balances}
            wallet={this.props.wallet}
            walletStatus={this.props.walletStatus}
            address={this.props.address}
          />
        }
      </div>
    );
  }
}
export default Earn;
