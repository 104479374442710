import React from 'react';
import { Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import PoolInfo from './PoolInfo';
import Staking from './Staking';

class PoolView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tab: props.tab || 'liquidity'
    }
    this.tabs = [
      { id: 'liquidity', label: 'Liquidity' },
      { id: 'staking', label: 'Staking' },
    ];
    this.close = this.close.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  close() {
    this.setState({ show: false });
    if(this.props.closeCallback)
      this.props.closeCallback();
  }

  setTab(tab) {
    this.setState({ tab: tab });
  }

  render() {
    return(
      <>
        <Modal centered show={this.state.show} onHide={this.close}>
          <Modal.Header closeButton>
            {this.props.pool.token0.symbol}-{this.props.pool.token1.symbol}
          </Modal.Header>
          <Modal.Body>

          <ToggleButtonGroup type="radio" name="tab" className='w-100 mb-3' onChange={this.setTab} defaultValue={this.state.tab}>
              {this.tabs.map((tab) =>
                <ToggleButton id={tab.id} key={tab.id} value={tab.id}
                              variant="outline-secondary">
                  {tab.label}
                </ToggleButton>
              )}
            </ToggleButtonGroup>

            <div className="">
              {this.state.tab === 'liquidity' &&
                <PoolInfo
                  pool={this.props.pool}
                  wallet={this.props.wallet}
                  balances={this.props.balances}
                  closeCallback={this.close}
                  />
              }

              {this.state.tab === 'staking' &&
                <Staking
                  pool={this.props.pool}
                  wallet={this.props.wallet}
                  closeCallback={this.close}
                />
              }
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default PoolView;
