let config = {};

config.matic = {
  id: 137,
  name: "Polygon",
  nativeToken: "MATIC",
  nativeTokenAddress: "0x0000000000000000000000000000000000001010",
  wrappedNativeTokenAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  contracts: {
    factory: "0x8Df775934b923C969d4c782c596e7a23257A0F8A",
    router: "0x5488A6Db5E6DE0ffAF58588213580220bD197115",
    // Using QuickSwap contract as external
    // https://docs.quickswap.exchange/reference/smart-contracts/01-factory
    // https://docs.quickswap.exchange/reference/smart-contracts/router02
    externalFactory: "0x5757371414417b8c6caad45baef941abc7d3ab32",
    externalRouter: "0x71fb78196FA0891B7a12EF2352162949f4D2725c", // forwarder
    staking: "0xad6FED17D6458AE0Ab1c97e4A1df1A633A3E49EA",
    bootstrap: "0x12B990a0D4b262D464acCbE3AA10e965E4350C4C",
    stakingLocked: "0x9da18a2e7ea8889a62474E526479e9A7890CFa40",
    gassless: "0x47522579126863Eee4Eba10Adb221c7AA1dd76C9",
    crosschain: "0x467Df8099e3d37bAC1d83123c0b801009cFFbc4D"
  },

  tokens: {
    usdc: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    dxvs: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
    rewards: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
  },
  tokensWithPermit: [
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "0x2347a4a94c57d9e9991fe6029a2b154d7e148526"
  ],
  urls: {
    rpc: "https://polygon-rpc.com/",
    explorer: "https://polygonscan.com",
    wss: "wss://rpc.ankr.com/polygon/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "/chains/matic.svg",
    connextSubgraph: 'https://api.thegraph.com/subgraphs/name/connext/amarok-runtime-v0-polygon'
  },
  defaultChartsTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
  connextDomainID: "1886350457",
  ankrName: 'polygon'
};

config.bsc = {
  id: 56,
  name: "Binance Smart Chain",
  nativeToken: "BNB",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  contracts: {
    factory: "0xc4F01e89e5C13AeDBB786ed7BE56B56ad7b2fD98",
    router: "0x9EE942d5EC400ee0145D43b18a65CDF1C0220550",
    // Used Sushiswap contract as external (UniswapV2 contracts)
    // Factory: 0xc35DADB65012eC5796536bD9864eD8773aBc74C4
    // Router: 0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506
    // Using now Pancakeswap contract as external (UniswapV2 contracts)
    // Factory: 0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73
    // Router: 0x10ED43C718714eb63d5aA57B78B54704E256024E
    externalFactory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    externalRouter: "0xb89431aA3E22F12c1473f7Bf23aD068C6dcdAC77", // forwarder
    staking: undefined,
    bootstrap: undefined,
    stakingLockedContract: undefined,
    gassless: "0xe229DB0c94409007A587Caa982453724246aB4a3",
    crosschain: "0x20FC26F4CcB00d6A30c9E1CD6D56Dad9137c5900"
  },
  tokens: {
    usdc: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    dxvs: undefined,
    rewards: undefined
  },
  tokensWithPermit: ["0x7b56748a3ef9970a5bae99c58ad8bc67b26c525f"],
  urls: {
    rpc: "https://bsc-dataseed1.binance.org",
    explorer: "https://bscscan.com",
    wss: "wss://rpc.ankr.com/bsc/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "/chains/bsc.svg",
    connextSubgraph: 'https://api.thegraph.com/subgraphs/name/connext/amarok-runtime-v0-bnb'
  },
  connextDomainID: "6450786",
  defaultChartsTokenAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  ankrName: 'bsc'
}

config.avalanche = {
  id: 43114,
  name: "Avalanche",
  nativeToken: "AVAX",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  contracts: {
    factory: "0xc4F01e89e5C13AeDBB786ed7BE56B56ad7b2fD98",
    router: "0x9EE942d5EC400ee0145D43b18a65CDF1C0220550",
    // Using Pangolin contract as external (UniswapV2 contracts)
    // Factory: 0xefa94DE7a4656D787667C749f7E1223D71E9FD88
    // Router: 0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106
    externalFactory: "0xefa94DE7a4656D787667C749f7E1223D71E9FD88",
    externalRouter: "0x61b1af88f303511578f812ac66af2a697Fa4CDD9", // forwarder
    staking: undefined,
    bootstrap: undefined,
    stakingLockedContract: undefined,
    gassless: "0x92B26Ef5998C73236d8157A437CA09ABE6df77f4",
    crosschain: undefined
  },
  tokens: {
    usdc: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    dxvs: undefined,
    rewards: undefined
  },
  tokensWithPermit: [],
  urls: {
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    explorer: "https://snowtrace.io",
    wss: "wss://rpc.ankr.com/avalanche/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "/chains/avax.svg"
  },
  defaultChartsTokenAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  ankrName: 'avalanche'
}

config.ethereum = {
  id: 1,
  name: "Ethereum",
  nativeToken: "ETC",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  contracts: {
    factory: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    // Using XXX contract as external (UniswapV2 contracts)
    // Factory:
    // Router:
    externalFactory: undefined,
    externalRouter: undefined, // forwarder
    staking: undefined,
    bootstrap: undefined,
    stakingLockedContract: undefined,
    gassless: undefined,
    crosschain: undefined,
  },
  tokens: {
    usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    dxvs: undefined,
    rewards: undefined
  },
  tokensWithPermit: [],
  urls: {
    rpc: "https://eth.llamarpc.com",
    explorer: "https://etherscan.io",
    wss: "wss://rpc.ankr.com/eth/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    connextSubgraph: '6648936'
  },
  connextDomainID: undefined,
  defaultChartsTokenAddress: "0x1234123412341234123412341234123412341234",
  ankrName: 'eth'
}

config.mumbai = {
  id: 80001,
  name: "Mumbai",
  nativeToken: "MATIC",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889", // 0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6
  contracts: {
    factory: "0xd937Af127F56919b01Bf45dBc896f60d9Bb5415B",
    router: "0x697E1615F88F0205Cd011e6386303C8a4f77a9cC",
    // Factory: 0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff
    // Router: 0x03aFC11e5C694Da145a6fb282079d408BfBECFe2
    externalFactory: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    externalRouter: "0x03aFC11e5C694Da145a6fb282079d408BfBECFe2", // forwarder
    staking: "0x218c12FB5d868b3AE1d14199B592279d027D8c88",
    bootstrap: "0x2E1024DF163475351c66Bde8Ac6572cA8D50382e",
    stakingLockedContract: "0x0c1C3C49BfAe22818889e42D2a061686300fAdB4",
    gassless: undefined,
    crosschain: "0xa280b09DbE86dFb1C33D5e7C8D18b2a5B1D60DF4",
  },
  tokens: {
    usdc: "0x30D30AEB0f272241acA538Ea1cec60eA0f7F6E6c",
    dxvs: "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0",
    rewards: "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0"
  },
  tokensWithPermit: [],
  urls: {
    rpc: "https://matic-mumbai.chainstacklabs.com",
    explorer: "https://mumbai.polygonscan.com",
    wss: "wss://rpc.ankr.com/polygon_mumbai/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "/chains/matic-grey.svg",
    connextSubgraph: "https://api.thegraph.com/subgraphs/name/connext/nxtp-amarok-runtime-v0-mumbai"
  },
  connextDomainID: "9991",
  defaultChartsTokenAddress: "0x0000000000000000000000000000000000001010",
  ankrName: 'polygon_mumbai'
}

config.goerli = {
  id: 5,
  name: "Goerli",
  nativeToken: "GoerliETH",
  nativeTokenAddress: "0x0000000000000000000000000000000000001010",
  wrappedNativeTokenAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
  contracts: {
    factory: "0xf5E53Fd705a583e3BE35001f102079C4F77CA24B",
    router: "0x3Fee79F5E40535cfCC17599382AB646Bec855aB7",
    externalFactory: undefined,
    externalRouter: undefined, // forwarder
    staking: undefined,
    bootstrap: undefined,
    stakingLockedContract: undefined,
    gassless: undefined,
    crosschain: undefined,
  },
  tokens: {
    usdc: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
    dxvs: "0xA65aAb7204A508059164F6ab9D37ab6961A77b2E",
    rewards: "0xA65aAb7204A508059164F6ab9D37ab6961A77b2E"
  },
  tokensWithPermit: [],
  urls: {
    rpc: "https://goerli.blockpi.network/v1/rpc/public",
    explorer: "https://goerli.etherscan.io",
    wss: "wss://rpc.ankr.com/eth_goerli/ws/c314b84f8420f5dc47c9398e84cf44d408ca3016bb99829f33a622c350692a61",
    icon: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    connextSubgraph: "https://api.thegraph.com/subgraphs/name/connext/nxtp-amarok-runtime-v0-goerli"
  },
  connextDomainID: "1735353714",
  defaultChartsTokenAddress: "0x0000000000000000000000000000000000001010",
  ankrName: 'eth_goerli'
}


config.local = {
  id: 31337,
  name: "Local TestNet",
  nativeToken: "ETH",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  contracts: {
    factory: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
    router: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
    externalFactory: "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
    externalRouter: "0x9A676e781A523b5d0C0e43731313A708CB607508", // forwarder
    staking: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
    bootstrap: "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e",
    stakingLockedContract: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
    gassless: "0xc6e7DF5E7b4f2A278906862b61205850344D4e7d",
    crosschain: undefined,
  },
  tokens: {
    usdc: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
    dxvs: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
    rewards: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9"
  },
  tokensWithPermit: [],
  urls: {
    rpc: "http://localhost:8545",
    explorer: "https://etherscan.io",
    wss: "ws://localhost:8545",
    icon: "/chains/matic-grey.svg",
    connextSubgraph: ''
  },
  connextDomainID: undefined,
  defaultChartsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9"
};

config.testnet = {
  id: 31338,
  name: "Dexvers TestNet",
  nativeToken: "ETH",
  nativeTokenAddress: "0x1234123412341234123412341234123412341234",
  wrappedNativeTokenAddress: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  contracts: {
    factory: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
    router: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
    externalFactory: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
    externalRouter: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0", // forwarder
    staking: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
    bootstrap: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
    stakingLockedContract: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
    gassless: "0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE",
    crosschain: undefined,
  },
  tokens: {
    usdc: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
    dxvs: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
    rewards: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9"
  },
  tokensWithPermit: [],
  urls: {
    rpc: "https://localtestnet.dexvers.cbix.io",
    explorer: "https://etherscan.io",
    wss: "wss://localtestnet.dexvers.cbix.io",
    icon: "/chains/matic-grey.svg",
    connextSubgraph: ''
  },
  connextDomainID: undefined,
  defaultChartsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9"
}

export default config;
