import PromiEvent from 'web3-core-promievent';
import ContractMethodDescription from "./ContractMethodDescription";

class AbstractContract {
  static displayName = 'AbstractContract';

  constructor() {
    this.meta = {
      contractType: this.constructor.displayName,
      methodName: 'call',
      methodArgs: Array.prototype.slice.call(arguments),
      methodParams: {}
    }
    this.arguments = this.meta.methodArgs;
  }

  send(params) {
    this.promise = new PromiEvent();
    setTimeout(() => {
      this.execute(params);
    }, 16);
    return this.promise.eventEmitter;
  }

  description() {
    return ContractMethodDescription.build(this.meta);
  }

  inspect() {
    let str = `${this.meta.contractType}.${this.meta.methodName}(`
    str = str + this.arguments.map((arg) => { return JSON.stringify(arg); }).join(', ');
    str = str + ')';
    return str;
  }

  async estimateGas() {
    return Promise.resolve(0);
  }
}

export default AbstractContract;
