import { Alert, Button } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
import Errors from "../../../Config/Errors";
import { Link } from "react-router-dom";

const FailureDefault = function({ transaction, closeModal }) {
  return(
    <>
      <div className="d-flex py-3">
        <div className="m-auto position-relative">
        <XCircleFill size = "40" className="rounded-circle overflow-hidden text-danger-light bg-danger" />
        </div>
      </div>
      <div className="">
        <Alert variant="danger" className="mt-3">
          <h6>Transaction failed!</h6>
          {transaction.error !== undefined && Errors.errorMessage(transaction.error)}
        </Alert>
        <div className="my-3 text-center">
          {transaction.error.recovery !== undefined &&
            <Link to={transaction.error.recovery.path} onClick={() => closeModal() } className="btn btn-sm btn-success me-2 w-25">{transaction.error.recovery.label}</Link>
          }
          <Button variant="primary w-25" size="sm" onClick={() => transaction.retry()}>Retry</Button>
        </div>
      </div>
    </>
  );
};

export default FailureDefault;
