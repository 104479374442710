import React from 'react';
import {ComposedChart, ResponsiveContainer, XAxis,
  YAxis, Tooltip, CartesianGrid, Bar, Line} from 'recharts';
import Charts from './Charts';

class LineBarChart extends React.Component {
  render() {
    return (
      <ResponsiveContainer height={250} >
        <ComposedChart data={this.props.data}>
          <XAxis dataKey="date" tick={Charts.tickStyle} tickFormatter={Charts.shortDateFormatter}/>
          <YAxis tick={Charts.tickStyle} type="number" tickFormatter={Charts.numberFormatter} domain={[0, dataMax => (dataMax * 1.5)]}/>
          <YAxis orientation="right" yAxisId="right" tick={Charts.tickStyle} tickFormatter={Charts.numberFormatter}/>
          <Tooltip labelFormatter={Charts.longDateFormatter} formatter={Charts.numberFormatter} labelClassName="text-gray-600" />
          <CartesianGrid stroke={Charts.gridStrikeColor} />
          <Bar dataKey={this.props.altDataKey} barSize={20} fill={Charts.barColor} yAxisId="right" name={this.props.altDataName}/>
          <Line type="monotone" dataKey={this.props.dataKey} stroke={Charts.lineColor} strokeWidth={2} name={this.props.dataName}/>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default LineBarChart;
