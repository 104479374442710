let config = {
  backendUrl: "https://backend.dexvers.cbix.io",
  blockchains: [],
  wallet: {
    futureNetworks: [
    ],
    networks: [
      {
        id: 31338,
        name: "Dexvers TestNet",
        native_token: "ETH",
        factoryAddress: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        routerAddress: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
        externalFactoryAddress: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
        externalRouterAddress: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
        stakingContractAddress: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
        rewardTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        bootstrapAddress: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        stakingLockedContractAddress: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
        usdcTokenAddress: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
        dxvsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        nativeCurrencyAddress: "0x1234123412341234123412341234123412341234",
        pools: [],
        rpcUrls: ["https://localtestnet.dexvers.cbix.io"],
        blockExplorerUrls: ["https://etherscan.io"],
        wssUrl: "wss://localtestnet.dexvers.cbix.io"
      }
      // {
      //   id: 137,
      //   name: "Polygon",
      //   native_token: "MATIC",
      //   factoryAddress: "0x8Df775934b923C969d4c782c596e7a23257A0F8A",
      //   routerAddress: "0x5488A6Db5E6DE0ffAF58588213580220bD197115",
      //   // Using QuickSwap contract as external
      //   // https://docs.quickswap.exchange/reference/smart-contracts/01-factory
      //   // https://docs.quickswap.exchange/reference/smart-contracts/router02
      //   externalFactoryAddress: "0x5757371414417b8c6caad45baef941abc7d3ab32",
      //   externalRouterAddress: "0x71fb78196FA0891B7a12EF2352162949f4D2725c", // forwarder
      //   stakingContractAddress: "0xad6FED17D6458AE0Ab1c97e4A1df1A633A3E49EA",
      //   rewardTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
      //   bootstrapAddress: "0x12B990a0D4b262D464acCbE3AA10e965E4350C4C",
      //   stakingLockedContractAddress: "0x9da18a2e7ea8889a62474E526479e9A7890CFa40",
      //   gasslessAddress: "0x47522579126863Eee4Eba10Adb221c7AA1dd76C9",
      //   usdcTokenAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      //   dxvsTokenAddress: "0x2347a4a94c57d9e9991fe6029a2b154d7e148526",
      //   nativeCurrencyAddress: "0x0000000000000000000000000000000000001010",
      //   pools: [],
      //   rpcUrls: ["https://rpc-mainnet.maticvigil.com/", "https://polygon-mainnet.infura.io/v3/ae5a82a5b2cb4b9497af352d2a5d1027"],
      //   blockExplorerUrls: ["https://polygonscan.com"],
      //   wssUrl: "wss://matic.getblock.io/d5005e41-6d04-474a-ad04-22ff972d5a06/mainnet/",
      //   iconUrl: "/chains/matic.svg"
      // }
    ]
  }
};

export default config;
