import { Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { Globals } from "../../../utils";

const SuccessDefault = function ({ transaction }) {
  function getExplorerUrl() {
    let txHash = transaction.calls.at(-1).txHash;
    return Globals.wallet.getExplorerUrl(txHash);
  }

  return (
    <div>
      <div className="d-flex py-3">
        <div className="m-auto position-relative">
          <CheckCircleFill size="40" className="rounded-circle overflow-hidden text-success-light bg-success" />
        </div>
      </div>
      <div className="text-center mb-4">
        <h5 className="text-center">Transaction completed!</h5>
        <Button href={getExplorerUrl()} target="_blank" variant="primary" size="sm" className="mt-3">
          View in Explorer
        </Button>
      </div>
    </div>
  );
};

export default SuccessDefault;
