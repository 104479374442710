import DexversApi from '../DexversApi';

class ChartData extends DexversApi {
  async getTokens(chainId, search='') {
    return fetch(this.url(chainId, search)).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response.statusText);
      }
    });
  }

  url(chainId, search='') {
    const searchParams = new URLSearchParams();
    searchParams.append("chainId", chainId);
    searchParams.append("search", search);
    return `${this.baseUrl()}/tokens/all?${searchParams.toString()}`;
  }

  transactionUrl(chainId, transactionHash) {
    
  }
}

export default ChartData;