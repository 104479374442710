import BigNumber from 'bignumber.js';
import React from 'react';
import { Badge, FormControl, Stack } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';


class AmountInput extends React.Component {
  static defaultProps = {
    className: "",
    placeholder: "Amount",
    max: 0,
    readOnly: false
  }
  static re = /^\d+(\.\d*)?$/

  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    }
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  setPercentage(p) {
    const balance = BigNumber(this.props.token.balance).dividedBy(Math.pow(10, this.props.token.decimals));
    const value = balance.multipliedBy(p).dividedBy(100).toFixed(8);
    this.setState({ value: value });

    if (this.props.onChange)
      this.props.onChange(value);
  }

  onChange(e) {
    let value = e.target.value;

    if (value !== "" && !AmountInput.re.test(value))
      return false;

    if (value === "") value = "0";
    if (value.match(/^0\d+/)) value = value.substring(1);

    let floatValue = parseFloat(value);
    if (Number.isNaN(floatValue))
      return false;

    if (this.props.max > 0 && floatValue > this.props.max) {
      floatValue = this.props.max;
      value = floatValue;
    }

    this.setState({ value: value });
    if (this.props.onChange)
      this.props.onChange(floatValue);
    return true;
  }

  render() {
    return (
      <InputGroup>
        <FormControl className={this.props.className}
          value={this.state.value === 0 ? "" : this.state.value }
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          style={this.props.style}
          readOnly={this.props.readOnly}
        />
        { !this.props.readOnly &&
          <>
            <InputGroup.Text id="basic-addon1" className="bg-gray-700 border-0">
              <Stack direction="horizontal" gap={1}>
                <Badge onClick={() => this.setPercentage(25)} bg="secondary">25%</Badge>
                <Badge onClick={() => this.setPercentage(50)} bg="secondary">50%</Badge>
                <Badge onClick={() => this.setPercentage(100)} bg="secondary">max</Badge>
              </Stack>
            </InputGroup.Text>
          </>
        }
      </InputGroup>
    );
  }
}

export default AmountInput;
