import { EventEmitter } from "events";
import Contracts from "../Blockchain/Contracts";
import LiquidityBoost from "./LiquidityBoost";

export class LiquidityBoosts extends EventEmitter {
  constructor(wallet) {
    super();
    this.wallet = wallet;
    this.list = [];
    this.loaded = false;
  }

  get investFromToken() {
    return this.wallet.usdcToken();
  }

  get investToToken() {
    return this.wallet.dxvsToken();
  }

  get rewardToken() {
    return this.wallet.dxvsToken();
  }


  async load(force = false) {
    if (this.loading || (this.loaded && !force)) return Promise.resolve();
    this.list = [];
    this.loading = true;
    let contract = Contracts.bootstrap;
    return this.loadAtIndex(contract, 0)
    .then(() => {
      this.emit("change");
      this.loaded = true;
      this.loading = false;
      return this.list;
    });
}

  async loadAtIndex(contract, index) {
    return contract.methods.stakes(this.wallet.address, index).call()
      .then((stake) => {
        stake.index = index;
        let stakeObject = new LiquidityBoost(stake, this.investFromToken, this.investToToken, this.rewardToken, this.wallet);
        this.list.push(stakeObject);
        return Promise.all([
          stakeObject.init(),
          this.loadAtIndex(contract, index + 1)
        ]);
      })
      .catch((err) => { });
  }

  find(address) {
    return this.list.find((stake) => {
      return stake.lpAddress === address;
    })
  }

  refresh() {
    this.load(true);
  }
}

export default LiquidityBoosts;
