import DexversApi from "../DexversApi";

class PoolsList extends DexversApi {
  async load(chainId, list) {
    return fetch(this.url(chainId, list))
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.statusText);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  url(chainId, list) {
    const searchParams = new URLSearchParams();
    searchParams.append("chainId", chainId);
    return `${this.baseUrl()}/liquidity_pools/list/${list}?${searchParams.toString()}`;
  }
}

export default PoolsList;
