import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import config from './Config';
import App2 from './App2';

Sentry.init({
  dsn: "https://ac557c414534e927c03d75b8cee59a46@sentry.srvr.cloud/5",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: config.env
});


ReactDOM.render(
  <React.StrictMode>
    {config.mode === "dex" && <App/>}
    {config.mode === "bootstrap" && <App2/>}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
