import { formattedAmount, fromContractDecimals, Globals } from "../../../utils";
import TokenIcon from "../../TokenIcon";

const SwapBody = function({ transaction }) {
  console.log(transaction);
  let meta = transaction.calls.at(-1).method.meta;
  let token0, token1, inValue, outValue;

  if (meta.contractType === 'router' && meta.methodName === 'swapExactTokensForTokens') {
    token0 = Globals.wallet.token(meta.methodArgs[2].at(0));
    token1 = Globals.wallet.token(meta.methodArgs[2].at(-1));
    inValue = fromContractDecimals(meta.methodArgs[0], token0.decimals);
    outValue = fromContractDecimals(meta.methodArgs[1], token1.decimals);
  } else if (meta.contractType === 'router' && meta.methodName === 'swapExactETHForTokens') {
    token0 = Globals.wallet.nativeToken();
    token1 = Globals.wallet.token(meta.methodArgs[1].at(-1));
    inValue = fromContractDecimals(meta.methodParams.value, token0.decimals);
    outValue = fromContractDecimals(meta.methodArgs[0], token1.decimals);
  } else if (meta.contractType === 'router' && meta.methodName === 'swapExactTokensForETH') {
    token0 = Globals.wallet.token(meta.methodArgs[2].at(0));
    token1 = Globals.wallet.nativeToken();
    inValue = fromContractDecimals(meta.methodArgs[0], token0.decimals);
    outValue = fromContractDecimals(meta.methodArgs[1], token1.decimals);
  } else if (meta.contractType === 'WETH' && meta.methodName === 'withdraw') {
    token0 = Globals.wallet.wrappedToken();
    token1 = Globals.wallet.nativeToken();
    inValue = fromContractDecimals(meta.methodArgs[0], token0.decimals);
    outValue = fromContractDecimals(meta.methodArgs[0], token0.decimals);
  } else if (meta.contractType === 'WETH' && meta.methodName === 'deposit') {
    token0 = Globals.wallet.nativeToken();
    token1 = Globals.wallet.wrappedToken();
    inValue = fromContractDecimals(meta.methodParams.value, token0.decimals);
    outValue = fromContractDecimals(meta.methodParams.value, token0.decimals);
  }

  return (
    <>
      <div>
        <h4>Summary</h4>
        <div className="d-flex bg-gray-700 bg-opacity-35 rounded-3 p-2">
          <div className="w-40 text-end">{formattedAmount(inValue, { stripInsignificantZeros: false })}</div>
          <div className="ps-2 d-flex gap-2">
            <div><TokenIcon token={token0} size="22" /></div>
            <div>{token0.name}</div>
            <span className="text-gray-400">
              ({token0.symbol})
            </span>
          </div>
        </div>
        <div className="d-flex flex-grow my-2">
          <hr className='m-0 flex-grow-1 my-auto' />
          <div className='text-gray-400 px-3'>
            SWAP FOR
          </div>
          <hr className='m-0 flex-grow-1 my-auto' />
        </div>
        <div className="d-flex bg-gray-700 bg-opacity-35 rounded-3 p-2">
          <div className="w-40 text-end">{formattedAmount(outValue, { stripInsignificantZeros: false })}</div>
          <div className="ps-2 d-flex gap-2">
            <div><TokenIcon token={token1} size="22" /></div>
            <div>{token1.name}</div>
            <span className="text-gray-400">
              ({token1.symbol})
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapBody;
