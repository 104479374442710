import React from 'react';
import { Button } from 'react-bootstrap';
import { formattedAmount } from '../../utils';
import TokenSelectForSwap from '../../TokenSelectForSwap';
import { Plus } from 'react-bootstrap-icons';
import Pool from '../../Stores/Pool';
import TokenIcon from '../../Components/TokenIcon';

class AddExistingLiquidity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromToken: props.fromToken || props.balances[0],
      toToken: props.toToken || props.balances[1],
      pool: undefined,
      valid: false
    };
    this.setFromToken = this.setFromToken.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.AddLiquidity = this.AddLiquidity.bind(this);
  }

  componentDidMount() {
    this.getPair();
    this.setFromToken(this.state.fromToken.address);
    this.setToToken(this.state.toToken.address);
  }

  setFromToken(address) {
    let fToken = this.props.balances.filter((x)=>{ return (x.address === address); })[0];
    this.setState({ fromToken: fToken }, ()=> this.getPair());
  }

  setToToken(address) {
    let tToken = this.props.balances.filter((x)=>{ return (x.address === address); })[0];
    this.setState({ toToken: tToken }, ()=> this.getPair());
  }

  async getPair() {
    if (this.state.fromToken.address === this.state.toToken.address) {
      this.setState({ pool: undefined, valid: false });
    }
    Pool.getByPair(this.state.fromToken.address, this.state.toToken.address, this.props.wallet)
      .then((pool) => {
        console.log("AddExistingLiquidity found pool: ", pool);
        this.setState({ pool: pool, valid: pool.percentage > 0 });
      })
      .catch((e) => {
        console.log("AddExistingLiquidity error: ", e);
        this.setState({ pool: undefined, valid: false });
      });
  }

  AddLiquidity() {
    this.props.wallet.pools.add(this.state.pool.address);
    this.props.onLiquidityAdded();
  }

  render() {
    return(
      <>
        <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
          <TokenSelectForSwap
              key="token0"
              hideAmount={true}
              balances={this.props.balances}
              token={this.state.fromToken}
              setTokenCallback={this.setFromToken}
            />
        </div>

        <div className="d-flex flex-grow my-2">
          <hr className='m-0 flex-grow-1 my-auto' />
          <div className='text-gray-400'>
            <Plus size="32" color="#9AA4B2" />
          </div>
          <hr className='m-0 flex-grow-1 my-auto' />
        </div>

        <div className="bg-gray-700 bg-opacity-35 rounded px-3 py-4">
          <TokenSelectForSwap
              key="token1"
              hideAmount={true}
              balances={this.props.balances}
              token={this.state.toToken}
              setTokenCallback={this.setToToken}
            />
        </div>

        { this.state.pool &&
          <div className="bg-gray-800 rounded-3 p-3 border border-secondary mt-3">
            <div className="d-flex">
              <div className="text-gray-200 me-auto small">Pool supply</div>
              <div className="fw-bold small">
                <div className="d-flex align-items-center justify-content-end mb-1">
                  {formattedAmount(this.state.pool.reserve0)}
                  <TokenIcon token={this.state.pool.token0} size="18" className="mx-1" />
                  <span className="text-gray-400"> {this.state.pool.token0.symbol}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end mb-1">
                  {formattedAmount(this.state.pool.reserve1)}
                  <TokenIcon token={this.state.pool.token1} size="18" className="mx-1" />
                  <span className="text-gray-400"> {this.state.pool.token1.symbol}</span>
                </div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="text-gray-200 me-auto small">Your balance</div>
              <div className="fw-bold small">
                <div className="d-flex align-items-center justify-content-end mb-1">
                  {formattedAmount(this.state.pool.token0Balance)}
                  <TokenIcon token={this.state.pool.token0} size="18" className="mx-1" />
                  <span className="text-gray-400"> {this.state.pool.token0.symbol}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end mb-1">
                  {formattedAmount(this.state.pool.token1Balance)}
                  <TokenIcon token={this.state.pool.token1} size="18" className="mx-1" />
                  <span className="text-gray-400"> {this.state.pool.token1.symbol}</span>
                </div>
              </div>
            </div>
            <div className="d-flex">
            <div className="text-gray-200 me-auto small">Share of Pool</div>
              <div className="text-end ms-auto small">{this.state.pool.percentage}%</div>
            </div>
          </div>
        }

        <Button size='lg' className='btn btn-primary w-100 mt-3' disabled={!this.state.valid} onClick={this.AddLiquidity}>
          Add Liquidity
        </Button>
      </>
    );
  }
}

export default AddExistingLiquidity;
