import React from 'react';
import { ArrowUpShort } from 'react-bootstrap-icons';

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [
        {
          label: "TVL",
          value: "$1.28b",
          trend: 78
        },
        {
          label: "24hr Trading Vol",
          value: "$18.40m",
          trend: 10
        },
        {
          label: "Subscriptions",
          value: "$148.40",
          trend: -11
        },
        {
          label: "24hr Fees Paid",
          value: "$758.32k",
          trend: 12
        }
      ]

    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="d-flex flex-column flex-lg-row rounded-3 bg-secondary bg-opacity-35 px-3 py-2 p-lg-3">
          {this.state.stats.map((stat, index) =>
            <div key={index.toString()} className="flex-fill d-flex d-lg-block my-1">
              <div className="pt-2">{stat.label}</div>
              <div className={"d-flex my-2 ms-auto " + (stat.trend >= 0 ? "text-success" : "text-danger")}>
                <div className="d-inline-block fs-4 fw-bold lh-1 ">{stat.value}</div>
                <div className="d-inline-block bg-white px-2 small rounded-pill ms-2">
                  <div className="fw-bold">
                    <ArrowUpShort size={22} className="me-1" />
                    {Math.abs(stat.trend)}%
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Stats;
