import React from 'react';
import { Container, Row, Col, Badge, Card, CloseButton} from 'react-bootstrap';
import { BoxArrowUpRight, GraphDown, GraphUp, Wallet2 } from 'react-bootstrap-icons';
import WalletApi from '../Apis/WalletApi';
import If from '../Components/If';
import TokenIcon from '../Components/TokenIcon';
import Unless from '../Components/Unless';
import DexLineChart from '../Graphs/DexLineChart';
import { formattedAmount } from '../utils';

class WalletInfo extends React.Component {
  constructor(props) {
    super(props);
    this.api = new WalletApi();
    this.state = {
      balances: [],
      selecteGraphToken: '',
      walletHistoryData: [],
      graphDataLoading: false
    };
    this.doClose = this.doClose.bind(this);
    this.selectGraphToken = this.selectGraphToken.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.walletAddress !== this.props.walletAddress) {
      this.getData();
    }
  }

  componentDidMount() {
    this.getData();
    window.scrollTo(0, 0);
  }

  isTokenSelected(token) {
    return this.state.selecteGraphToken === token;
  }

  getData() {
    this.api.balanceData(this.props.chainId, this.props.walletAddress).then((data) => {
      this.setState({
        balances: data
      })
    }).catch((e) => {
      this.setState({
        balances: []
      });
    })
  }

  getWalletHistoryData() {
    console.log("Selected Token:",this.state.selecteGraphToken);
    this.api.balanceHistoryData(this.props.chainId, this.props.walletAddress, this.state.selecteGraphToken).then((data) => {
      this.setState({
        walletHistoryData: data,
        graphDataLoading: false
      })
    }).catch((e) => {
      console.log("WalletInfo wallet history error:", e);
    })
  }

  selectGraphToken(token) {
    this.setState({
      selecteGraphToken: token,
      graphDataLoading: true
    }, this.getWalletHistoryData);
  }

  selectNewToken(address) {
    this.props.setTokenCallback(address);
    this.props.setWalletCallback('');
  }

  doClose() {
    this.props.setWalletCallback('');
  }

  render() {
    return (
      <>
      <Card>
        <Card.Header>
          <div className="d-flex">
            <Card.Title className='m-0'>
              {this.props.walletAddress}
              <a className='ms-2 small' rel="noreferrer" href={`${this.props.explorerUrl}/address/${this.props.walletAddress}`} target="_blank">
                <BoxArrowUpRight/>
              </a>
            </Card.Title>
            <CloseButton className='ms-auto' onClick={this.doClose} />
          </div>
        </Card.Header>
        <Card.Body className='py-0'>
        <If condition={this.state.balances.length > 0}>
          {this.state.balances.map((balance) =>
            <>
              <Row className='py-3 border-bottom border-secondary' role="button" key={balance.address}>
                <Col className='col-3' title={balance.address} onClick={() => this.selectNewToken(balance.address)}>
                  <div className='d-inline-block' role="button">
                    <TokenIcon token={{address: balance.address, name: balance.name, symbol: balance.symbol}} size={24}/>
                  </div>
                  &nbsp;
                  {balance.name}
                </Col>
                <Col className='col-3'>
                  {balance.symbol}
                </Col>
                <Col className='col-3 text-end'>
                  {formattedAmount(balance.balance)}
                </Col>
                <Col className='col-3 text-end' title={balance.last_change} onClick={() => this.selectGraphToken(balance.address)}>
                  <If condition={balance.last_change >= 0}>
                    <Badge bg="success">
                      <GraphUp/>
                    </Badge>
                  </If>
                  <Unless condition={balance.last_change >= 0}>
                    <Badge bg="warning">
                      <GraphDown/>
                    </Badge>
                  </Unless>
                </Col>
              </Row>
              <If condition={this.isTokenSelected(balance.address)}>
                <Row className='py-3 border-bottom border-secondary' key={`graph${balance.address}`}>
                  <Col className='col-9 offset-1'>
                    <If condition={this.state.graphDataLoading}>
                      Loading...
                    </If>
                    <Unless condition={this.state.graphDataLoading}>
                      <DexLineChart data={this.state.walletHistoryData} dataKey="balance" name="Balance History"/>
                    </Unless>
                  </Col>
                </Row>
              </If>
            </>
          )}
        </If>
        </Card.Body>
      </Card>
      </>
    );
  }
}

export default WalletInfo;
