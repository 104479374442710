let crosschain = {
  tokens: [
    { // USDC
      matic: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      bsc: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      ethereum: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    { // WETH
      matic: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      bsc: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      ethereum: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    },
    { // TEST Token
      mumbai: "0xeDb95D8037f769B72AAab41deeC92903A98C9E16",
      goerli: "0x7ea6eA49B0b0Ae9c5db7907d139D9Cd3439862a1"
    }
  ]
}

export default crosschain;
