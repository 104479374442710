import React from 'react';
import { Button } from 'react-bootstrap';
import TokenIcon from '../../Components/TokenIcon';
import { formattedAmount, Globals } from '../../utils';

class Earnings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "view",
      reward: undefined,
      rewardToken: props.wallet.rewardToken(),
      error: undefined
    }
    this.collect = this.collect.bind(this);
  }

  componentDidMount() {
    this.props.stake.computeReward()
      .then((value) => { this.setState({ reward: value }) })
  }

  collect() {
    this.setState({ state: "collecting" });
    let contract = this.props.stake.prepareCollectReward()
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute()
      .then(() => {
        this.setState({ state: "view" });
      })
      .catch((error) => {
        this.setState({ state: "view", error: error });
      });
  }

  render() {
    return(
      <>
        <p>Your Position</p>

        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3">
          <div className="d-flex bg-opacity-100">
            <div className="px-3 my-auto d-flex align-items-center">
              <div className="pair-icons me-2">
                <TokenIcon token={this.props.stake.pool.token0} size="24" />
                <TokenIcon token={this.props.stake.pool.token1} size="24" />
              </div>
              <div>
                {this.props.stake.pool.token0.symbol} - {this.props.stake.pool.token1.symbol}
              </div>
            </div>
            <div className="text-end flex-fill me-5">
              <div className="d-flex align-items-center justify-content-end mb-1">
                <strong>{formattedAmount(this.props.stake.token0Balance)}</strong>
                <TokenIcon token={this.props.stake.pool.token0} size="22" className="mx-2" />
                <span className="text-gray-400">{this.props.stake.pool.token0.symbol}</span>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <strong>{formattedAmount(this.props.stake.token1Balance)}</strong>&nbsp;
                <TokenIcon token={this.props.stake.pool.token1} size="22" className="mx-2" />
                <span className="text-gray-400">{this.props.stake.pool.token1.symbol}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-700 bg-opacity-35 rounded-3 px-3 py-2 mt-3 text-center">
          <div className="bg-opacity-100">
            {formattedAmount(this.props.stake.isActive ? this.props.stake.amount : 0)} &nbsp; LP Tokens &nbsp;
            <span className="text-gray-500">
              ({this.props.stake.pool.token0.symbol}-{this.props.stake.pool.token1.symbol}-LP)
            </span>
          </div>
        </div>

        <p className="mt-3 fw-medium">Your Earnings to date</p>

        <div className="bg-success bg-opacity-50 rounded-3 px-3 py-2 mt-3 text-center">
          {this.state.reward &&
            <div className='bg-opacity-200'>
              {formattedAmount(this.state.reward)} &nbsp; {this.state.rewardToken.name} &nbsp;
              <span className="text-gray-400">
                ({this.state.rewardToken.symbol})
              </span>
            </div>
          }
        </div>

        <div className="bg-gray-700 mt-3 rounded-3 p-3">
          <div className="d-flex flex-wrap">
            <div className="w-50">Total Pool Value</div>
            <div className="w-50 text-end">
              {formattedAmount(this.props.stake.pool.supply)}&nbsp;
              {this.props.stake.pool.token0.symbol}-{this.props.stake.pool.token1.symbol}-LP
            </div>
          </div>
        </div>

        <Button size='lg' className='btn btn-primary w-100 mt-3' disabled={this.state.reward <= 0} onClick={this.collect}>
          Collect Rewards
        </Button>
      </>
    );
  }
}

export default Earnings;
