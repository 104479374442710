import React, { useState, useEffect } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import Gassless from './Gassless/Gassless';
import SwapPage from './Swap/Swap';
import SwapWiz from './SwapWiz/SwapWiz';
import SwapWizConnectWallet from './SwapWiz/SwapWizConnectWallet';
import { Globals } from './utils';

const Swap = (props) => {
  let location = useLocation();
  let tab = 'swap';
  if (location.pathname.endsWith('guided'))
    tab = 'guided';
  if (location.pathname.endsWith('gassless'))
    tab = 'gassless';

  if (Globals.state.walletStatus !== 'connected') {
    return (
      <SwapWizConnectWallet
        connectWallet={props.connectWallet}
      />
    );
  }

  return (
    <div>
      <div className='mb-5'>
        <Nav className='justify-content-center'>
          <Nav.Item>
            <Nav.Link active={tab === 'swap'} className="px-4">
              <Link to='' className="nav-link">Swap</Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={tab === 'guided'} className="px-4">
              <Link to='guided' className="nav-link">Guided Swap</Link>
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link active={tab === 'gassless'} className="px-4">
              <Link to='gassless' className="nav-link">Get {props.wallet.native_currency}</Link>
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>

      <Routes>
        <Route path={`guided`} element={
          <SwapWiz
            wallet={props.wallet}
            tokens={props.tokens}
          />
        }/>

        <Route path={`gassless`} element={
          <Row className='justify-content-center'>
            <Col className="col-12 col-lg-6">
              <Gassless
                wallet={props.wallet}
                tokens={props.tokens}
              />
            </Col>
          </Row>
        }/>
        <Route path='*' element={
          <SwapPage
            wallet={props.wallet}
            tokens={props.tokens}
          />
        }/>
      </Routes>
    </div>
  );
};

export default Swap;
