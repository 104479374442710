import React from 'react';
import { Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import { QuestionCircle, Wallet2, Plug, Check } from 'react-bootstrap-icons';
import { shortAddress } from './utils';
import logo from './Assets/logo-brand.png'
import If from './Components/If';
import Config from './Config';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

class DexMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.brandClick = this.brandClick.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }



  brandClick() {
    if (this.props.brandCallback)
      this.props.brandCallback();
  }

  selectItem(eventKey, _event) {
    this.props.menuCallback(parseInt(eventKey, 10));
  }

  render() {
    return (
      <>
        <Navbar bg="dark" id="mainnav" variant="dark" expand="lg" className='border-bottom' collapseOnSelect onSelect={this.selectItem}>
          <Container>
            <Navbar.Brand role="button" onClick={() => this.props.actionCallback("home")}>
              <img src={logo} className="d-inline-block align-top" alt="dexvers"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link>
                  <Link to="/swap" className="nav-link">Swap</Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/earn" className="nav-link">Earn</Link>
                </Nav.Link>
                {/* <Nav.Link>
                  <Link to="/charts" className="nav-link">Charts</Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/dashboard" className="nav-link">Dashboard</Link>
                </Nav.Link> */}
              </Nav>
              <Nav>
                { (this.props.address === '') &&
                  <>
                    <Nav.Link onClick={() => this.props.actionCallback("connect-wallet")} className="btn btn-primary">Connect Wallet</Nav.Link>
                  </>
                }
                { (this.props.address !== '') &&
                  <>
                      <Link to="/gassless" className="nav-link small text-white btn btn-sm btn-success m-auto py-0 px-2 me-2">
                        Get more
                      </Link>
                      <Navbar.Text>
                        <span className="fw-bold text-success">{this.props.currency}</span>
                        &nbsp;
                        {this.props.balance}
                    </Navbar.Text>
                    <Dropdown className='nav-item ms-3' id="nav-dropdown" onSelect={this.props.actionCallback}>
                      <Dropdown.Toggle variant="primary" size='' title={this.props.address} data-bs-toggle="tooltip">
                        <Wallet2 size={24} className="me-2 bg-white text-primary rounded p-1" style={{xpadding: "4px" }} />
                        {shortAddress(this.props.address)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant='dark' align="end">
                        <Dropdown.Item eventKey="support" active={false}><QuestionCircle className='me-2' /> Support</Dropdown.Item>
                        <If condition={Config.wallet.networks.length > 1}>
                          <Dropdown.Divider />
                          <Dropdown.Header>Switch network</Dropdown.Header>
                          {Config.wallet.networks.map((n) =>
                            <Dropdown.Item key={n.id} eventKey="change-network" data-chain-id={n.id} active={false}>
                              <If condition={this.props.wallet.chainId === n.id}>
                              <Check className='position-absolute ms-2 mt-1' style={{left: 0}} />
                              </If>
                              {n.name}
                            </Dropdown.Item>
                          )}
                        </If>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="disconnect-wallet" active={false}><Plug className='me-2' /> Disconnect Wallet</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default DexMenu;
