import React from 'react';
import { Globals } from '../utils';
import { Dropdown } from 'react-bootstrap';
import Config from '../Config';
import { Check } from 'react-bootstrap-icons';
import ChainChangeNetworkLabel from './ChainChangeNetworkLabel';

class ChainChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.changeNetwork = this.changeNetwork.bind(this);
  }

  changeNetwork(key, event) {
    if (event.currentTarget.dataset.chainId === undefined || event.currentTarget.dataset.chainId === Globals.blockchain.id)
      return;
    let id = parseInt(event.currentTarget.dataset.chainId, 10);
    Globals.wallet.changeNetwork(id);
  }

  render() {
    return (
      <>
        <div className={`w-100 ${this.props.className}`}>
          <Dropdown className='w-100' onSelect={this.changeNetwork}>
            <Dropdown.Toggle className={`d-flex align-items-center w-100 bg-gray-700 rounded-3 py-2`} variant="dark">
              <ChainChangeNetworkLabel className="me-auto my-1" network={Globals.blockchain} expanded={this.props.expanded} />
            </Dropdown.Toggle>
            <Dropdown.Menu  variant='dark' className={this.props.dropdownClassName}>
            {Config.wallet.networks.map((network) =>
              <Dropdown.Item key={network.id} className="nohover" eventKey="change-network" data-chain-id={network.id} active={false}>
                {Globals.wallet.chainId === network.id &&
                  <Check className='position-absolute ms-2 mt-1' style={{ left: 0 }} />
                }
                <ChainChangeNetworkLabel network={network} key={network.id} expanded={true} />
              </Dropdown.Item>
            )}
            {Config.wallet.futureNetworks?.length > 0 &&
              <>
              <Dropdown.Divider/>
              <Dropdown.Header>Coming soon ...</Dropdown.Header>
              {Config.wallet.futureNetworks.map((network) =>
                <Dropdown.Item key={network.id} className="nohover disabled" active={false}>
                  <ChainChangeNetworkLabel variant="inactive" network={network} expanded={true}/>
                </Dropdown.Item>
              )}
              </>
            }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }
}
export default ChainChange;
