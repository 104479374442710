import DexversTokens from './DexversTokens';

class TokenList {
  constructor() {
    this.tokenList = [];
    this.default_tokens = {
      80001: [
        {
          name: "ETH",
          symbol: "ETH",
          address: "0x1234123412341234123412341234123412341234",
          decimals: 18,
          unit: "ether"
        },
        {
          name: "Dexvers",
          symbol: "DXVS",
          address: "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0",
          decimals: 18,
          unit: "ether"
        },
        {
          name: "TEST Connext",
          symbol: "TEST",
          address: "0xeDb95D8037f769B72AAab41deeC92903A98C9E16",
          decimals: 18,
          unit: "ether"
        },
        {
          name: "USDC",
          symbol: "USDC",
          address: "0x30D30AEB0f272241acA538Ea1cec60eA0f7F6E6c",
          decimals: 18,
          unit: "ether"
        }
      ],
      5: [
        {
          name: "ETH",
          symbol: "ETH",
          address: "0x0000000000000000000000000000000000001010",
          decimals: 18,
          unit: "ether"
        },
        {
          "name": "Dexvers",
          "symbol": "DXVS",
          "address": "0xA65aAb7204A508059164F6ab9D37ab6961A77b2E",
          "decimals": 18,
          "unit": "ether"
        },
        {
          "name": "TEST Connext",
          "symbol": "TEST",
          "address": "0x7ea6eA49B0b0Ae9c5db7907d139D9Cd3439862a1",
          "decimals": 18,
          "unit": "ether"
        },
        {
          "name": "USDC",
          "symbol": "USDC",
          "address": "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
          "decimals": 18,
          "unit": "ether"
        },
        {
          "name": "WETH",
          "symbol": "WETH",
          "address": "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
          "decimals": 18,
          "unit": "ether"
        }
      ],
      31338: [
        {
          address: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
          name: 'Wrapped ETH',
          symbol: 'WETH',
          balance: 0
        },
        {
          address: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
          name: 'Token1',
          symbol: 'TK1',
          balance: 0
        },
        {
          address: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
          name: 'Token2',
          symbol: 'TK2',
          balance: 0
        },
        {
          address: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          unit: 'mwei',
          balance: 0
        },
        {
          address: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
          name: 'Dexvers',
          symbol: 'DXVS',
          balance: 0
        }
      ],
      31337: [
        {
          "symbol": "TK1",
          "name": "Token1",
          "address": "0x5FbDB2315678afecb367f032d93F642f64180aa3"
        },
        {
          "symbol": "TK2",
          "name": "Token2",
          "address": "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512"
        },
        {
          "symbol": "WETH",
          "name": "WETH",
          "address": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0"
        },
        {
          "symbol": "USDC",
          "name": "USD Coin",
          "address": "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9"
        },
        {
          "symbol": "DXVS",
          "name": "Dexvers",
          "address": "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9"
        },
        {
          "symbol": "ET1",
          "name": "External Token 1",
          "address": "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82"
        },
        {
          "symbol": "ET2",
          "name": "External Token 2",
          "address": "0x9A676e781A523b5d0C0e43731313A708CB607508"
        },
        {
          "symbol": "ET3",
          "name": "External Token 3",
          "address": "0x0B306BF915C4d645ff596e518fAf3F9669b97016"
        },
        {
          "symbol": "ET4",
          "name": "External Token 4",
          "address": "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1"
        }
      ],
      1: [
        {
          address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          name: 'Wrapped ETH',
          symbol: 'WETH'
        },
        {
          address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          name: 'USD Coin',
          symbol: 'USDC'
        },
        {
          address: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
          name: 'PAX Dollar',
          symbol: 'USDP'
        },
        {
          address: '0x06AF07097C9Eeb7fD685c692751D5C66dB49c215',
          name: 'CHAI',
          symbol: 'CHAI'
        },
        {
          address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
          name: 'DAI Stablecoin',
          symbol: 'DAI'
        },
        {
          address: '0x408e41876cCCDC0F92210600ef50372656052a38',
          name: 'Republic',
          symbol: 'REN'
        },
        {
          address: '0xfA3E941D1F6B7b10eD84A0C211bfA8aeE907965e',
          name: 'HayCoin',
          symbol: 'HAY'
        },
      ],
      137: [
        {
          address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
          name: 'Wrapped Matic',
          symbol: 'WMATIC'
        },
        {
          address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
          name: 'Wrapped Ether',
          symbol: 'WETH'
        },
        {
          address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
          name: 'USD Coin',
          symbol: 'USDC',
          decimals: 6,
          unit: 'mwei'
        },
        {
          address: '0x2347a4a94c57d9e9991fe6029a2b154d7e148526',
          name: 'Dexvers',
          symbol: 'DXVS'
        },
        {
          address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
          name: '(POS) DAI Stablecoin',
          symbol: 'DAI'
        },
        {
          address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
          name: '(POS) Tether USD',
          symbol: 'USDT'
        },
      ],
      56: [{
        address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        name: "Wrapped BNB",
        symbol: "WBNB"
      },
      {
        address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
        name: "Polkadot: DOT Token ",
        symbol: "DOT",
        decimals: 18
      },
      {
        address:"0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18",
        name:"Binance-Peg Band Protocol Token",
        symbol:"BAND",
        decimals: 18
      },
      {
        address:"0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
        name:"Binance-Peg Cardano Token",
        symbol:"ADA",
        decimals: 18
      }],
      43114: [
        {
          address:"0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
          name:"USD Coin",
          symbol:"USDC",
          decimals: 18
        }
      ]
    }
  }

  find(address) {
    return this.tokenList.find(token => token.address === address);
  }

  async loadTokens(chainId, address) {
    this.default_tokens[chainId].forEach(token => {
      if (token.decimals === undefined || token.unit === undefined) {
        token.decimals = 18;
        token.unit = 'ether';
      }
    });
    this.tokenList = this.default_tokens[chainId];
    this.tokensWithBalance = this.default_tokens[chainId];
    const api = new DexversTokens();
    return api
      .load(chainId, address)
      .then((data) => {
        this.tokenList = data;
        this.tokensWithBalance = data.filter((token) => token.balance > 0);
        if(this.tokensWithBalance.length === 0)
          this.tokensWithBalance = this.tokenList;
        return true;
      })
      .catch((error) => {
        let data = this.default_tokens[chainId];
        this.tokenList = data;
        this.tokensWithBalance = data;//.filter((token) => token.balance > 0);
        return true;
      });
  }
}

export default TokenList;
