import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import If from '../Components/If';
import TokenIcon from '../Components/TokenIcon';
import Unless from '../Components/Unless';
import { formattedAmount, Globals } from '../utils';
import Unstake from './Unstake';

class StakeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showsUnstakeView: false,
      stake: props.stake
    }
    this.close = this.close.bind(this);
    this.collect = this.collect.bind(this);
    this.showUnstakeView = this.showUnstakeView.bind(this);
  }

  close() {
    this.setState({ show: false });
    if(this.props.closeCallback)
      this.props.closeCallback();
  }

  showUnstakeView() {
    this.setState({ showsUnstakeView: true });
  }

  collect() {
    let contract = this.state.stake.prepareCollectReward();
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute();
    this.close();
  }

  refresh() {
    this.state.stake.refresh()
      .then(() => { this.setState({ stake: this.state.stake }); })
  }

  render() {
    return(
      <>
        <Modal centered show={this.state.show} onHide={this.close}>
          <Modal.Header closeButton>Position &amp; Rewards</Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="text-gray-100 mb-3 fw-medium">Your Position</div>
              <Table className="small mb-3 rounded-3 overflow-hidden">
                <thead>
                  <tr className='text-gray-100 bg-gray-600'>
                    <th className="fw-normal text-center">Investment</th>
                    <th className="fw-normal text-center">Stake</th>
                    <th className="fw-normal text-center">Time Left</th>
                  </tr>
                </thead>
                <tbody className='border-top-0 bg-gray-700 bg-opacity-35'>
                  <tr className="align-middle">
                    <td className="text-center fw-medium">
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        {formattedAmount(this.state.stake.investAmount)}
                        <TokenIcon token={this.state.stake.investFromToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {this.state.stake.investFromToken.symbol}</span>
                      </div>
                    </td>
                    <td className="text-center py-3">
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        {formattedAmount(this.state.stake.stakeFromAmount)}
                        <TokenIcon token={this.state.stake.investFromToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {this.state.stake.investFromToken.symbol}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {formattedAmount(this.state.stake.stakeToAmount)}
                        <TokenIcon token={this.state.stake.investToToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {this.state.stake.investToToken.symbol}</span>
                      </div>
                    </td>
                    <td className="text-end">
                      <If condition={this.state.stake.isActive}>
                        <If condition={this.state.stake.timeLeft.days}>
                          <span className="text-gray-100">{this.state.stake.timeLeft.days}</span>
                          <span className="text-gray-400">d</span>
                        </If>
                        <span className="text-gray-100 ms-2">{this.state.stake.timeLeft.hours}</span>
                        <span className="text-gray-400">h</span>
                      </If>
                      <Unless condition={this.state.stake.isActive}>
                        -
                      </Unless>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="text-gray-100 my-3 fw-medium">Your Expected Earnings</div>
              <div className="mb-3 bg-gray-700 bg-opacity-35 p-3 text-center">
                <div className="d-flex align-items-center justify-content-center mb-1">
                  {formattedAmount(this.state.stake.rewardAmount)}
                  <TokenIcon token={this.state.stake.rewardToken} size="18" className="mx-1" />
                  <span className="text-gray-400"> {this.state.stake.rewardToken.symbol}</span>
                </div>
              </div>
              <div className="mb-3 bg-gray-700 rounded-3 p-3 small d-none">
                <div className="d-flex">
                  <div className="text-gray-200">Total Pool Value</div>
                  <div className="text-gray-200 fw-medium ms-auto">
                    {formattedAmount(0)}
                    &nbsp;
                    {this.state.stake.investToToken.symbol}
                  </div>
                </div>
              </div>

              <Button size='lg' variant='primary' className="w-100" disabled={!this.state.stake.isClaimable} onClick={this.collect}>
                Collect Rewards
              </Button>
              <Unless condition={this.state.stake.isClaimable}>
                <div className="text-danger mt-1 smaller text-center">{this.state.stake.unclaimableReason}</div>
              </Unless>

              <Button size='lg' variant='outline-danger' outline className="mt-3 w-100" disabled={!this.state.stake.isUnstakeable} onClick={this.showUnstakeView}>
                Unstake
              </Button>
              <Unless condition={this.state.stake.isUnstakeable}>
                <div className="text-danger mt-1 smaller text-center">You have already unstaked this position.</div>
              </Unless>
            </div>
          </Modal.Body>
        </Modal>

        <If condition={this.state.showsUnstakeView}>
          <Unstake
            stake={this.state.stake}
            wallet={this.props.wallet}
            closeCallback={this.close}
          />
        </If>
      </>
    );
  }
}

export default StakeView;
