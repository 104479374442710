import { Globals } from "../utils";
import GasslessApi from "../Apis/GasslessApi";
import AbstractContract from './AbstractContract';

export class GasslessExchange extends AbstractContract {
  static displayName = 'GasslessExchange';

  constructor(token) {
    super(token);
    this.token = token;
  }

  execute() {
    let signature = this.state.results.at(-1);
    this.promise.eventEmitter.emit("sending");
    let r, s, v, amount, deadline;
    [r, s, v, amount, deadline] = signature;
    let api = new GasslessApi();
    api.sendTransaction(Globals.wallet.chainId, Globals.wallet.address, this.token.address, amount, r, s, v, deadline)
      .then((result) => {
        this.promise.eventEmitter.emit("transactionHash", result.transaction_id);
        this.promise.resolve(result);
      })
      .catch((error) => {
        this.promise.reject(error);
      });
  }
}
