import React from 'react';
import { Button, Card } from 'react-bootstrap';
import TokenSelectForSwap from '../TokenSelectForSwap';
import { formattedAmount, Globals } from '../utils';
import { ChevronExpand, QuestionCircle } from 'react-bootstrap-icons';
import Swapper from '../Wallet/Swapper';
import TrendingValue from '../Components/TrendingValue';

class SwapBox extends React.Component {
  static defaultProps = {
    className: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      fromToken: this.defaultFromToken(),
      toToken: this.defaultToToken(),
      inValue: 0,
      outValue: 0,
      outValueAfterSlippage: 0,
      canTrade: false,
      exchangePath: undefined,
      routerAddress: undefined,
      inputValid: true,
      transaction: undefined,
      priceImpact: undefined,
      error: undefined,
      swapResult: undefined
    }

    this.setInValue = this.setInValue.bind(this);
    this.setOutValue = this.setOutValue.bind(this);
    this.doSwap = this.doSwap.bind(this);
    this.setFromToken = this.setFromToken.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.swapCallback = this.swapCallback.bind(this);
    this.reset = this.reset.bind(this);
    this.switch_swap_direction = this.switch_swap_direction.bind(this);
    this.inputValidityChanged = this.inputValidityChanged.bind(this);
  }

  async setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve));
  }

  componentDidMount() {
    this.setFromToken(this.state.fromToken.address);
    this.setToToken(this.state.toToken.address);
  }

  defaultFromToken() {
    return this.props.tokens[0];
  }

  defaultToToken() {
    if (this.props.tokens.length === 0) {
      return this.props.tokens[0];
    }
    return this.props.tokens.find((t)=> t.address !== this.props.tokens[0].address);
  }

  setInValue(val) {
    this.setStateAsync({ inValue: val })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountOut());
  }

  setOutValue(val) {
    this.setStateAsync({ outValue: val })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountIn());
  }

  switch_swap_direction() {
    let fromToken = this.state.fromToken;
    if (this.props.setFromTokenCallback)
      this.props.setFromTokenCallback(this.state.toToken);
    if (this.props.setToTokenCallback)
      this.props.setToTokenCallback(fromToken);
    this.setState({
      fromToken: this.state.toToken,
      toToken: fromToken,
      inValue: this.state.outValue
    }, this.estimateAmountOut);
  }

  setFromToken(address) {
    let token = this.props.wallet.token(address);
    if (this.props.setFromTokenCallback)
      this.props.setFromTokenCallback(token);
    this.setStateAsync({ fromToken: token })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountOut());

  }

  setToToken(address) {
    let token = this.props.wallet.token(address);
    if (this.props.setToTokenCallback)
      this.props.setToTokenCallback(token);
    this.setStateAsync({ toToken: token })
      .then(() => this.setStateAsync({ canTrade: this.canTrade() }))
      .then(() => this.estimateAmountOut());
  }

  estimateAmountOut() {
    if(!this.state.canTrade) return;
    this.props.wallet.estimateAmountOut(this.state.inValue, this.state.fromToken.address, this.state.toToken.address).then((result) => {
      this.setState({
        outValue: result.amount,
        priceImpact: result.impact,
        outValueAfterSlippage: result.slippage,
        exchangePath: result.path,
        routerAddress: result.routerAddress
      })
    })
    .catch((err) => {
      this.setState({ outValue: 0 });
    });
  }

  estimateAmountIn() {
    if(!this.state.canTrade) return;
    this.props.wallet.estimateAmountIn(this.state.outValue, this.state.fromToken.address, this.state.toToken.address).then((result) => {
      this.setState({
        inValue: result.amount,
        priceImpact: result.impact,
        outValueAfterSlippage: result.slippage,
        exchangePath: result.path,
        routerAddress: result.routerAddress
      })
    })
    .catch((err) => {
      this.setState({ inValue: 0 });
    });
  }

  inputValidityChanged(isValid) {
    this.setStateAsync({ inputValid: isValid })
      .then(() => this.setState({ canTrade: this.canTrade() }))
  }

  canTrade() {
    let valuesOk = this.state.inValue > 0 || this.state.outValue > 0;
    let inTokenOk = !(this.state.fromToken?.address ==='');
    let outTokenOk = !(this.state.toToken?.address ==='');
    let tokensOk = (this.state.fromToken?.address !== this.state.toToken?.address);
    return valuesOk && inTokenOk && outTokenOk && tokensOk && this.state.inputValid && (!this.state.swapSuccess);
  }

  swapCallback(success, result) {
    console.log('swapCallback', success, result);
    if (success) {
      this.setState({ swapResult: result });
    } else {
      this.setState({ error: result });
    }
  }

  doSwap() {
    this.swapper = new Swapper(
      this.props.wallet,
      this.state.inValue,
      this.state.outValueAfterSlippage,
      this.state.fromToken.address,
      this.state.toToken.address,
      this.state.exchangePath,
      this.state.routerAddress
    );
    this.swapper.prepare();
    Globals.pendingTransactions.add(this.swapper.transaction);
    this.swapper.execute();
    this.reset();
  }

  reset() {
    this.setState({
      inValue: 0,
      outValue: 0,
      canTrade: false,
      error: undefined,
      swapResult: undefined,
      transaction: undefined
    })
  }

  render() {
    return (
      <>
        <div className={"position-relative " + this.props.className}>
          <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch">
            <div className="rounded-3 border border-3 border-fuchsia me-3"></div>
            <TokenSelectForSwap
              key="input"
              className="flex-fill"
              balances={this.props.tokens}
              token={this.state.fromToken}
              value={this.state.inValue}
              setTokenCallback={this.setFromToken}
              setValueCallback={this.setInValue}
              validate={true}
              onValidityChanged={this.inputValidityChanged}
            />
          </div>

          <div className="d-flex my-3">
            <hr className="m-0 flex-grow-1 my-auto" />
            <div className="text-gray-400 mx-2" role="button">
              <ChevronExpand onClick={this.switch_swap_direction}/>
            </div>
            <hr className="m-0 flex-grow-1 my-auto" />
          </div>

          <div className="bg-gray-700 rounded-3 px-3 py-4 d-flex align-items-stretch">
            <div className="rounded-3 border border-3 border-primary me-3"></div>
            <TokenSelectForSwap
              key="output"
              className="flex-fill"
              balances={this.props.tokens}
              token={this.state.toToken}
              value={this.state.outValue}
              setTokenCallback={this.setToToken}
              setValueCallback={this.setOutValue}
            />
          </div>

          <div className={`mt-3 p-3 rounded-3 bg-gray-800 ${this.state.canTrade || 'd-none'}`}>
            <div className="d-flex">
              <div className="small text-gray-400 me-2">Output</div>
              <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
            </div>
            <div className="d-flex mt-2 small">
              <div className="flex-fill">Expected Output <QuestionCircle title="Expected output"/></div>
              <div className="ms-3 text-end">
                {formattedAmount(this.state.outValue)} {this.state.toToken.symbol}
              </div>
            </div>
            <div className="d-flex mt-2 small">
              <div className="flex-fill">Minimum after slippage (0.50%) <QuestionCircle/></div>
              <div className="ms-3 text-end">{formattedAmount(this.state.outValueAfterSlippage)}</div>
            </div>

            <div className="d-flex mt-3">
              <div className="small text-gray-400 me-2">Impact</div>
              <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
            </div>
            <div className="d-flex mt-2 small">
              <div className="flex-fill">Price Impact <QuestionCircle/></div>
              <div className="ms-auto text-end"><TrendingValue value={this.state.priceImpact} suffix="%" /></div>
            </div>

            <div className="d-flex mt-3">
              <div className="small text-gray-400 me-2">Transaction Fees</div>
              <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
            </div>
            <div className="d-flex mt-2 small">
              <div className="flex-fill">Network fee <QuestionCircle/> </div>
              <div className="ms-auto text-end">0.30%</div>
            </div>
          </div>

          <Button variant={this.state.canTrade ? 'primary' : 'secondary'} className='border-0 w-100 mt-4 p-3' onClick={this.doSwap} disabled={!this.state.canTrade}>SWAP</Button>
        </div>
      </>
    );
  }
}

export default SwapBox;
