class ApiPoller {
  constructor(url, interval = 1000, timeout = 120000) {
    this.url = url;
    this.interval = interval;
    this.timeout = timeout;
    this.pollingInterval = null;
  }

  startPolling() {
    this.startedAt = new Date().getTime();
    return new Promise((resolve, reject) => {
      this.pollingInterval = setInterval(() => {
        if (new Date().getTime() - this.startedAt > this.timeout) {
          this.stopPolling();
          reject({ status: 'failed', message: 'Timeout' });
        }
        fetch(this.url)
          .then(response => response.json())
          .then(data => {
            if (data.status === 'success') {
              this.stopPolling();
              resolve(data);
            } else if (data.status === 'failed') {
              this.stopPolling();
              reject(data);
            }
          })
          .catch(error => {
            this.stopPolling();
            reject({ status: 'failed', message: error.message });
          });
      }, this.interval);
    });
  }

  stopPolling() {
    clearInterval(this.pollingInterval);
  }
}



export default ApiPoller;
