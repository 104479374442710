import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import GraphData from "../Apis/GraphData";
import { formattedAmount, Globals } from "../utils";

class WalletTransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transfers: []
    }
    this.api = new GraphData();
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.walletAddress !== this.props.walletAddress) {
      this.getData();
    }
  }

  getData() {
    if (Globals.state.walletStatus !== 'connected')
      return;
    this.api.transferDataByWallet(this.props.chainId, this.props.walletAddress).then((data) => {
      console.log("Wallet Transactions","got data",data);
      this.setState({transfers: data});
    }).catch((e) => console.error(e));
  }


  render() {
    return (
      <Card>
        <Card.Header>
          <Row>
            <Col xs={12} lg={"auto"}>
              <Card.Title>Your Recent Transactions</Card.Title>
              <Card.Subtitle className="text-secondary">The most recent transations in your connected wallet</Card.Subtitle>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <Table className="small mb-0" variant='dark'>
            <thead>
              <tr>
                <th className='fw-normal'>From</th>
                <th className='fw-normal'>To</th>
                <th className='fw-normal'>Amount</th>
                <th className='fw-normal'>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.transfers.map((transfer, index) =>
                <tr key={index} className="text-white align-middle">
                  <td>{transfer.from}</td>
                  <td>{transfer.to}</td>
                  <td>{formattedAmount(transfer.amount)} {transfer.token_symbol}</td>
                  <td>{transfer.date}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {this.state.transfers.length === 0 &&
            <div className="text-center py-5">
              {Globals.state.walletStatus == 'connected' ? "No transactions found" : "Connect to a wallet to see your transactions"}
            </div>
          }
        </Card.Body>
      </Card>
    );
  }
}

export default WalletTransactionList;
