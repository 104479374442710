import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Dot, Wallet2 } from 'react-bootstrap-icons';

class SwapWizConnectWallet extends React.Component {
  render() {
    return(

      <Row className='justify-content-center'>
        <Col className='col-12'>
          <h2 className='text-center fw-bold mt-5'>Swapping a token is easy on Dexvers:</h2>

          <div className="d-flex justify-content-between px-5 mt-5 swap-wiz-dots">
            <div className="text-center position-relative">
              <div className="d-inline-block bg-white lh-1 rounded rounded-circle p-2 swap-wiz-dots-dot">
                <div className="bg-primary rounded-circle position-relative" style={{width: 8, height: 8 }}></div>
              </div>
              <div className="">Connect a Wallet</div>
            </div>
            <div className="text-center position-relative flex-fill">
              <div className="d-inline-block bg-white lh-1 rounded rounded-circle p-2 swap-wiz-dots-dot">
                <div className="bg-primary rounded-circle position-relative" style={{width: 8, height: 8 }}></div>
              </div>
              <div className="">Pick a Token to Swap</div>
            </div>
            <div className="text-center position-relative">
              <div className="d-inline-block bg-white lh-1 rounded rounded-circle p-2 swap-wiz-dots-dot">
                <div className="bg-primary rounded-circle position-relative" style={{width: 8, height: 8 }}></div>
              </div>
              <div className="">Select a Token to Swap To</div>
            </div>
          </div>
          <h2 className="text-center fw-bold mt-5">Let's connect your Wallet!</h2>
          <div className="text-center text-gray-200">Get the exact token amount fast and with the least amount of fees. </div>
          <div className="text-center mt-5">
            <Button variant='primary' size='lg' className='w-50' onClick={this.props.connectWallet}>
                <Wallet2/> &nbsp;
                Connect your wallet
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
export default SwapWizConnectWallet;
