import React from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import Contracts from '../../Blockchain/Contracts';
import AmountInput from '../../Components/AmountInput';
import { formattedAmount, Globals, toContractDecimals } from '../../utils';
import { TextCenter } from 'react-bootstrap-icons';

class Staking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      percentValue: 0,
      step: "data",
      allowed: undefined
    }
    this.setValueByPercentage = this.setValueByPercentage.bind(this);
    this.setValue = this.setValue.bind(this);
    this.stake = this.stake.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    Contracts.staking.methods.acceptedPairs(this.props.pool.address).call().then((allowed) => {
      this.setState({ allowed: allowed });
    });
  }

  setValueByPercentage(e) {
    this.setState({
      percentValue: parseInt(e.target.value),
      value: this.props.pool.balance * (e.target.value / 100)
    });
  }

  setValue(value) {
    if (value > this.state.balance) {
      value = this.state.balance;
    }
    let percentage = Math.round(value / this.props.pool.balance * 100);
    this.setState({
      percentValue: percentage,
      value: value
    });
  }

  stake() {
    this.setState({ step: "executing" });
    let amountWei = toContractDecimals(this.state.value, 18);
    let contract = Contracts.staking.methods
      .stake(this.props.pool.address, amountWei)
      .requiresApproval(this.props.pool.address, amountWei)
      .prepare({ from: this.props.wallet.address });
    Globals.pendingTransactions.add(contract.transaction);
    contract.execute();
    if (this.props.closeCallback)
      this.props.closeCallback();
  }

  reset() {
    this.setState({
      step: "data",
      value: 0,
      percentValue: 0
    });
  }

  render() {
    if (this.state.allowed === undefined) {
      return(
        <div className="p-3 text-center">
          <Spinner animation="border" variant="secondary" style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    }

    if (this.state.allowed === false) {
      return(
        <div className="bg-gray-700 rounded-3 p-3">
          <div className="bg-opacity-100">
            <div className='text-center py-3'>
              Staking is not available for this pair.
            </div>
          </div>
        </div>
      );
    }

    return(
      <>
        <div className="bg-gray-700 rounded-3 p-3">
          <div className='mb-3 bg-opacity-100'>Available for Staking</div>
          <div className='bg-opacity-100'>
            <span className="text-gray-400">Liquidity Pool Token</span>
            &nbsp;
            <span className="text-gray-500">
              ({this.props.pool.token0.symbol}-{this.props.pool.token1.symbol}-LP)
            </span>
            <hr />
            <div className="d-flex">
              <div className='mt-auto'>Balance</div>
              <div className="mt-auto ms-auto fs-2 fw-bold">{formattedAmount(this.props.pool.balance)}</div>
            </div>
          </div>
        </div>

        <div className="bg-gray-700 rounded-3 p-3 mt-3">
          <div className="bg-opacity-100">
            <div className='mb-3'>Amount to stake</div>
            <div className=''>
              <Form.Range min='0' max='100' step='1' value={this.state.percentValue} onChange={this.setValueByPercentage} />
              <div className='g-0 range-ruler text-gray-400 mb-4'>
                <div className='step'><div className="vr"></div><div className="percent">0%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">10%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">20%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">30%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">40%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">50%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">60%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">70%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">80%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">90%</div></div>
                <div className='step'><div className="vr"></div><div className="percent">100%</div></div>
              </div>
            </div>
            <div className="d-flex flex-grow mt-5 mb-3">
              <hr className='m-0 flex-grow-1 my-auto' />
              <div className="text-gray-400">👆 SLIDE OR TYPE AMOUNT 👇</div>
              <hr className='m-0 flex-grow-1 my-auto' />
            </div>
            <div>
            <InputGroup>
              <InputGroup.Text className='text-gray-500 bg-white'>
                {this.props.pool.token0.symbol}-{this.props.pool.token1.symbol}-LP
              </InputGroup.Text>
              <AmountInput
                className="text-gray-500 border-start-0"
                value={this.state.value}
                max={this.props.pool.balance}
                onChange={this.setValue}
              />
            </InputGroup>
            </div>
          </div>
        </div>

        <Button size='lg' className='btn btn-primary w-100 mt-3' disabled={this.state.value <= 0} onClick={this.stake}>
          Stake DLP
        </Button>
      </>
    );
  }
}

export default Staking;
