import { formattedAmount, fromContractDecimals, Globals } from "../../../utils";

const BootstrapStakeBody = function({ transaction }) {
  let contract = transaction.calls.at(-1).method;
  let token0 = Globals.wallet.usdcToken();
  let token1 = Globals.wallet.dxvsToken();
  let value = fromContractDecimals(contract.meta.methodArgs[0], token0.decimals);

  return (
    <>
      <div className="fw-medium fs-6 text-grat">Amount to Invest:</div>
      <div className="bg-success bg-opacity-50 rounded-3 p-3 mt-3">
        <div className="d-flex justify-content-center">
          <div className="text-end">
            <span className="text-gray-200 fw-medium">{formattedAmount(value / 2)}</span><br/>
            <span className="text-gray-200 fw-medium">{formattedAmount(value / 2 * 5)}</span>
          </div>
          <div className="text-start ms-1">
            <span className="text-gray-400">{token0.symbol}</span><br/>
            <span className="text-gray-400">{token1.symbol}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BootstrapStakeBody;
