import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { formattedAmount } from '../../utils';

class AddLiquidityConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    }
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  close() {
    if(this.props.closeCallback)
      this.props.closeCallback();
  }

  confirm() {
    if (this.props.confirmCallback)
      this.props.confirmCallback();
  }

  render() {
    return(
      <>
        <Modal centered show={this.state.show} onHide={this.close}>
          <Modal.Header closeButton>
            CONFIRM
          </Modal.Header>
          <Modal.Body>
            <div className="d-none">
              <div className="text-secondary">AMOUNT</div>
              <div className="h2 m-0 lh-1">0</div>
              <div className="fs-5">{this.props.fromToken.symbol}/{this.props.toToken.symbol} Pool Tokens</div>
              <div className="text-secondary mt-2 mb-3">
                Output is estimated. If the price changes by more than 0.5% your transaction will revert.
              </div>
            </div>
            <div className="mb-3 small text-secondary">
              When you add liquidity, you will receive pool tokens representing your position. These Tokens
              automatically earn fees proportional to your share of the pool, and can be redeemed at any time.
            </div>
            <div className="border rounded-3 border-secondary p-3">
              <div className="d-flex">
                <div className='text-secondary'>Rates</div>
                <div className="text-end ms-auto">
                  1 {this.props.fromToken.symbol} = {formattedAmount(this.props.price)} {this.props.toToken.symbol}<br/>
                  1 {this.props.toToken.symbol} = {formattedAmount(1/this.props.price)} {this.props.fromToken.symbol}
                </div>
              </div>
              <hr className="text-dark" />
              <div className="d-flex">
                <div className='text-secondary'>{this.props.fromToken.symbol} Deposited</div>
                <div className="text-end ms-auto">{formattedAmount(this.props.fromAmount)} {this.props.fromToken.symbol}</div>
              </div>
              <div className="d-flex">
              <div className='text-secondary'>{this.props.toToken.symbol} Deposited</div>
                <div className="text-end ms-auto">{formattedAmount(this.props.toAmount)} {this.props.toToken.symbol}</div>
              </div>
              <div className="d-flex">
                <div className='text-secondary'>Share of Pool</div>
                <div className="text-end ms-auto">{this.props.share}%</div>
              </div>
            </div>
            <Button size='lg' className='btn btn-primary w-100 mt-3' onClick={this.confirm}>
              Confirm Pool Supply
            </Button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AddLiquidityConfirmation;
