import React from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import { CheckCircleFill, CircleFill, XCircleFill } from "react-bootstrap-icons";

class TransactionStatus extends React.Component {
  static defaultProps = {
    transaction: undefined,
    title: "Progress"
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.transaction && (
          <>
            {this.props.title !== "" && this.props.title !== undefined &&
              <h4 className="my-3">{this.props.title}</h4>
            }
            <ListGroup>
              {this.props.transaction.calls.map((call, index) => (
                <ListGroup.Item key={index} className="d-flex">
                  {call.description()}
                  <div className="ms-auto">
                    {call.status === "pending" && (
                      <CircleFill className="rounded-circle bg-secondary" />
                    )}
                    {call.status === "executing" && (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        size="sm"
                      />
                    )}
                    {call.status === "success" && (
                      <CheckCircleFill className="rounded-circle overflow-hidden text-success-light bg-success" />
                    )}
                    {call.status === "failed" && (
                      <XCircleFill className="rounded-circle overflow-hidden text-danger-light bg-danger" />
                    )}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </>
    );
  }
}

export default TransactionStatus;
