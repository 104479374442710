import React, { Component } from 'react';
import { Card, ProgressBar, Spinner } from 'react-bootstrap';
import { CheckCircleFill, CircleFill, XCircleFill } from 'react-bootstrap-icons';
import { Globals } from '../utils';
import SimpleTooltip from './SimpleTooltip';
import TransactionModal from './TransactionModal';

class PendingTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: Globals._pendingTransactions || [],
      expanded: false,
      modalTransaction: undefined
    };
    this.transactionChanged = this.transactionChanged.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.modalWillClose = this.modalWillClose.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    Globals.pendingTransactions = this;
  }

  add(transaction) {
    this.state.transactions.push(transaction);
    Globals._pendingTransactions = this.state.transactions;
    this.setState({
      transactions: this.state.transactions,
      modalTransaction: transaction
    });
    transaction.on("change", this.transactionChanged);
  }

  transactionChanged(transaction, status) {
    this.setState({
      transactions: this.state.transactions
    });
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  modalWillClose() {
    this.setState({ modalTransaction: undefined });
  }

  showModal(transaction) {
    this.setState({ modalTransaction: transaction });
  }

  render() {
    return (
      <>
        {this.state.modalTransaction &&
          <TransactionModal transaction={this.state.modalTransaction} onClose={this.modalWillClose} />
        }
        <div className='fixed-bottom' style={{ zIndex: 200 }}>
          <div className={"px-3 " + (this.state.transactions.length > 0 ? "" : "d-none" )}>
            <Card className='mx-auto mb-3 bg-gray-800 rounded-3 border-dark shadow-lg' style={{ maxWidth: '500px' }}>
              <Card.Body className='pt-3 pb-1 px-3'>
                {this.state.expanded &&
                  <div className="mb-4">
                    {this.state.transactions.map((transaction, index) =>
                      <div key={index} className="rounded-3 bg-secondary bg-opacity-35 px-3 py-2 mb-2 d-flex" role="button" onClick={() => this.showModal(transaction)}>
                        <div className="me-auto small">
                          {transaction.calls.at(-1).description()}
                        </div>
                        {transaction.calls.map((call, index) =>
                          <SimpleTooltip key={index} tooltip={call.description()}>
                          <div  className="ms-2">
                            {call.status === "pending" && <CircleFill className="rounded-circle bg-secondary" /> }
                            {call.status === "executing" && <Spinner animation="border" variant="secondary" size="sm" /> }
                            {call.status === "success" && <CheckCircleFill className="rounded-circle overflow-hidden text-success-light bg-success" /> }
                            {call.status === "failed" && <XCircleFill className="rounded-circle overflow-hidden text-danger-light bg-danger" /> }
                          </div>
                          </SimpleTooltip>
                        )}
                      </div>
                    )}
                  </div>
                }
                {this.state.transactions.length > 0 &&
                  <div className="p-1  user-select-none" role="button" onClick={this.toggleExpanded}>
                    <ProgressBar animated={this.state.transactions.at(-1).status === "executing"} now={100} variant="purple" className='flex-fill' style={{ height: '8px' }} />
                    <div className="text-center smaller text-gray-300 mt-2">
                      {!this.state.expanded && "(Tap to exapand)"}
                      {this.state.expanded && "(Tap to collapse)"}
                    </div>
                  </div>
                }
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default PendingTransactions;
