import React from 'react';
import GenericSinceChart from './GenericSinceChart';
import DoubleLineChart from './DoubleLineChart.jsx';

class WalletCountChart extends GenericSinceChart {
  getData() {
    this.api.walletCountData(this.props.chainId, this.props.tokenAddress, this.state.since.toISOString()).then((data) => {
      this.setState({data: data});
    }).catch((e) => console.log("Could not get data"));
  }

  graphName() {
    return 'Wallet Count';
  }

  chartComponent() {
    return (
      <DoubleLineChart data={this.state.data} dataKey="total_count" dataName='Count' altDataKey="overfifty_count" altDataName="50$+ Count" />
    );
  }
}

export default WalletCountChart;