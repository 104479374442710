import React from 'react';
import {Row, Col, Modal, InputGroup, FormControl} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import TokenIcon from './Components/TokenIcon';
import { filterTokens } from './utils';

class TokenSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tokens: props.tokens.slice(0, 100)
    }
    this.select = this.select.bind(this);
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.hide = this.hide.bind(this);
  }

  setSearchTerm(event) {
    this.setState({
      tokens: filterTokens(this.props.tokens, event.target.value).slice(0,100)
    })
  }

  select(token) {
    this.props.setTokenCallback(token);
    this.setState({ show: false });
  }

  hide() {
    if (this.props.closeCallback)
     this.props.closeCallback();
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <Modal size="600" show={this.state.show} onHide={this.hide}>
          <Modal.Header closeButton>
            <Modal.Title>Select a token</Modal.Title>
          </Modal.Header>
          <Modal.Body className='bg-gray-700'>
            <Row className='mb-3'>
              <Col key='search'>
                <InputGroup size='lg'>
                  <InputGroup.Text className='bg-gray-600 text-gray-400 border-dark'>
                    <Search size={16}/>
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search"
                    aria-label="Search"
                    className='ps-0  bg-gray-600 text-gray-400 border-dark border-start-0'
                    onChange={this.setSearchTerm}
                  />
                </InputGroup>
              </Col>
            </Row>
            <div className="rounded-3 overflow-autox inner-scroll" style={ { maxHeight: "350px" } }>
              {this.state.tokens.map((t, index) =>
                <div key={t.address} className={'bg-gray-800 ' + (index === 0 ? "" : "border-top border-secondary")} role="button" onClick={(e) => this.select(t)}>
                  <div className='d-flex p-3'>
                    <div className=''>
                      <TokenIcon token={t} />
                    </div>
                    <div className='ms-2 small'>
                      <div className='ms-2 font-weight-bold lh-1'>{t.name}</div>
                      <div className='text-muted ms-2 mt-1 font-weight-bold lh-1'>{t.symbol}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
     </>
    );
  }
}

export default TokenSelector;
