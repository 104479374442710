import React from 'react';
import {Badge} from 'react-bootstrap';
import { rounded } from '../utils';

class ChartChangeBadge extends React.Component {
  render() {
    return (
      <Badge bg={this.props.change >= 0 ? 'success' : 'warning'} size={20}>{rounded(this.props.change, 2)}%</Badge>
    );
  }
}

export default ChartChangeBadge;
