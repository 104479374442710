import React from 'react';
import { Col, Row, Button, Modal, Card, Table } from 'react-bootstrap';
import TokenIcon from '../../Components/TokenIcon';
import { formattedAmount, Globals } from '../../utils';
import PoolView from "../Pool/PoolView";
import AddExistingLiquidity from '../Pools/AddExistingLiquidity';
import AddLiquidity from '../Pools/AddLiquidity';

class Pools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: [],
      showsAddLiquidityForm: false,
      showsAddExistingLiquidityForm: false,
      showsPoolView: false,
      selectedPool: null
    }
    this.showAddLiquidityForm = this.showAddLiquidityForm.bind(this);
    this.showAddExistingLiquidityForm = this.showAddExistingLiquidityForm.bind(this);
    this.hideAddLiquidityForm = this.hideAddLiquidityForm.bind(this);
    this.showPoolView = this.showPoolView.bind(this);
    this.hidePoolView = this.hidePoolView.bind(this);
    this.updatePools = this.updatePools.bind(this);
  }

  componentDidMount() {
    this.loadPools();
  }

  componentWillUnmount() {
    this.props.wallet.pools.off("change", this.updatePools);
  }

  loadPools() {
    this.updatePools();
    this.props.wallet.pools
      .on("change", this.updatePools)
      .load();
  }


  updatePools() {
    console.log("Pools: updatePools");
    this.setState({ pools: this.props.wallet.pools.list });
  }

  showAddLiquidityForm() {
    this.setState({ showsAddLiquidityForm: true, showsAddExistingLiquidityForm: false });
  }

  showAddExistingLiquidityForm() {
    this.setState({ showsAddLiquidityForm: false, showsAddExistingLiquidityForm: true });
  }

  hideAddLiquidityForm() {
    this.setState({ showsAddLiquidityForm: false, showsAddExistingLiquidityForm: false });
  }

  showPoolView(pool) {
    this.setState({ showsPoolView: true, selectedPool: pool });
  }

  hidePoolView() {
    this.setState({ showsPoolView: false, selectedPool: null });
  }

  render() { return(
    <>
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={12} lg={"auto"}>
              <Card.Title>Your Liquidity Pools</Card.Title>
              <Card.Subtitle className="text-secondary">Pools of paired tokens</Card.Subtitle>
            </Col>
            <Col xs={12} lg={"auto"} className="ms-lg-auto">
              <Button variant='primary' className="mt-3 mt-lg-0" onClick={this.showAddLiquidityForm} disabled={Globals.state.walletStatus !== 'connected'}>Add Liquidity</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <Table className="small mb-0" variant='dark'>
            <thead>
              <tr>
                <th className='fw-normal'>Liquidity Pairing</th>
                <th className='fw-normal'>Investment</th>
                <th className='fw-normal'>Total Rewards</th>
              </tr>
            </thead>
            <tbody>
              {this.state.pools.map((pool, index) =>
                <tr className="text-white align-middle" key={index.toString()} role="button" onClick={()=> this.showPoolView(pool)}>
                  <td width="31%">
                    <div className="d-flex align-items-center">
                      <div className="d-inline-block pair-icons">
                        <TokenIcon token={pool.token0} size="24" />
                        <TokenIcon token={pool.token1} size="24" />
                      </div>
                      <div className="d-inline-block ms-2">
                        {pool.token0.symbol}-{pool.token1.symbol}
                      </div>
                    </div>
                  </td>
                  <td width="23%" className="text-nowrap text-end">
                    <div className="d-flex align-items-center justify-content-end mb-1">
                      {formattedAmount(pool.token0Balance)}
                      <TokenIcon token={pool.token0} size="18" className="mx-1" />
                      <span className="text-gray-400"> {pool.token0.symbol}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {formattedAmount(pool.token1Balance)}
                      <TokenIcon token={pool.token1} size="18" className="mx-1" />
                      <span className="text-gray-400"> {pool.token1.symbol}</span>
                    </div>
                  </td>
                  <td width="23%" className="text-end py-3">
                    <div className="text-gray-100">{formattedAmount(pool.balance)}</div>
                    <div className="text-gray-500">
                      {pool.token0.symbol}-{pool.token1.symbol}-LP
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {this.state.pools.length === 0 &&
            <div className="text-center py-5">
              {Globals.state.walletStatus === 'connected' ? "No liquidity positions found" : "Connect to your wallet to view your liquidity positions"}
            </div>
          }
        </Card.Body>
      </Card>

      { this.state.showsAddLiquidityForm &&
        <Modal centered show={true} onHide={this.hideAddLiquidityForm}>
          <Modal.Header closeButton>
            Add Liquidity
          </Modal.Header>
          <Modal.Body>
            <div className="pb-3 text-center">
              <Button variant='link' className='text-decoration-none' onClick={this.showAddExistingLiquidityForm}>Add a current position</Button>
            </div>
            <AddLiquidity
                balances={this.props.balances}
                wallet={this.props.wallet}
                onLiquidityAdded={this.hideAddLiquidityForm}
                />
          </Modal.Body>
        </Modal>
      }

      { this.state.showsAddExistingLiquidityForm &&
        <Modal centered show={true} onHide={this.hideAddLiquidityForm}>
          <Modal.Header closeButton>
            Add Existing Liquidity
          </Modal.Header>
          <Modal.Body>
            <AddExistingLiquidity
              balances={this.props.balances}
              wallet={this.props.wallet}
              onLiquidityAdded={this.hideAddLiquidityForm}
            />
          </Modal.Body>
        </Modal>
      }

      { this.state.showsPoolView &&
        <PoolView
          pool={this.state.selectedPool}
          wallet={this.props.wallet}
          balances={this.props.balances}
          closeCallback={this.hidePoolView}
        />
      }
    </>
  )}
}

export default Pools;
