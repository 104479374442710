import React from 'react';
import LineBarChart from './LineBarChart';
import GenericSinceChart from './GenericSinceChart';
class PriceVolumeChart extends GenericSinceChart {
  getData() {
    console.log('getData since:', this.state.since);
    this.api.priceVolumeData(this.props.chainId, this.props.tokenAddress, this.state.since.toISOString()).then((data) => {
      this.setState({data: data});
    }).catch((e) => console.log("Could not get data"));
  }

  graphName() {
    return 'Price/Volume';
  }

  chartComponent() {
    return (
      <LineBarChart data={this.state.data} dataKey="price" dataName='Price' altDataKey="volume" altDataName="Volume" dot={false}/>
    );
  }
}

export default PriceVolumeChart;