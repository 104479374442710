import { Globals } from "../utils";
import Approval from "./Approval";
import ContractMethodDescription from "./ContractMethodDescription";
import Transaction from "./Transaction";

class ContractMethodExtension {
  static extend(object) {
    for (let methodName of Object.getOwnPropertyNames(this.prototype)) {
      if (this.prototype.hasOwnProperty(methodName) && methodName !== 'constructor') {
        object[methodName] = this.prototype[methodName].bind(object);
      }
    }
    object.start();
  }

  start() {
    this.meta = {};
    this.transaction = new Transaction();
    this.appendTransactions = [];
  }

  requiresApproval(token, amount, to = undefined) {
    to ||= this.meta.contractAddress;
    this.transaction.add(
      new Approval(token, amount, to),
      { from: Globals.address }
    );
    return this;
  }

  add(method, params = {}) {
    this.transaction.add(method, params);
    return this;
  }

  append(method, params = {}) {
    this.appendTransactions.push({ method, params });
    return this;
  }

  prepare(params) {
    this.transaction.add(this, params);
    for (let { method, params } of this.appendTransactions) {
      this.transaction.add(method, params);
    }
    return this;
  }

  async execute(params = undefined) {
    if (params !== undefined)
      this.prepare(params);
    return this.transaction.execute();
  }

  inspect() {
    let str = `${this._parent._address}.${this._method.name}(`
    str = str + this.arguments.map((arg, index) => { return JSON.stringify(arg) }).join(', ');
    str = str + ')';
    return str;
  }

  description() {
    return ContractMethodDescription.build(this.meta);
  }
}

export default ContractMethodExtension;
