import React from 'react';
import { Col, Row, Button, Badge, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formattedAmount } from "../utils";
import { ArrowUpCircle, Coin } from 'react-bootstrap-icons';
import StakeView from './StakeView';
import If from '../Components/If';
import TokenIcon from '../Components/TokenIcon';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showsStakeView: false,
      selectedStake: null
    }
    this.showStakeView = this.showStakeView.bind(this);
    this.hideStakeView = this.hideStakeView.bind(this);
  }

  showStakeView(stake) {
    this.setState({ showsStakeView: true, selectedStake: stake });
  }

  hideStakeView() {
    this.setState({ showsStakeView: false, selectedStake: null });
    if (this.props.refresh)
    this.props.refresh();
  }

  render() {
    return (
      <>
        <div className="bg-gray-700 bg-opacity-35 rounded-3 p-3 border border-secondary mt-3">
          <div className="d-flex mb-3">
            <div className="me-auto fw-medium">Available to Boost</div>
            <div><Badge bg='success-light' text='success' pill={true}>New</Badge></div>
          </div>
          <div>
            <span className="text-gray-400">USD Coin</span> &nbsp;
            <span className="text-gray-500">(USDC)</span>
          </div>
          <hr className="text-gray-300" />
          <div className="align-baseline mb-3">
            <div className="d-inline-block mt-auto mb-1 fw-medium">Balance</div>
            <div className="d-inline-block fs-2 fw-bold ms-3">{formattedAmount(this.props.usdcBalance)}</div>
          </div>
          <Button onClick={() => this.props.setPage("boost")} className="w-100">
            <ArrowUpCircle size={16} className="me-2" />
            Boost Liquidity
          </Button>
        </div>

        <Card className="mb-4 mt-3 rounded-3 overflow-hidden">
          <Card.Header>
            <Row>
              <Col xs={12} lg={"auto"}>
                <Card.Title>Staked Positions</Card.Title>
                <Card.Subtitle className="text-secondary">Track and manage your farm stakes</Card.Subtitle>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <Table className="small mb-0">
              <thead>
                <tr className='text-gray-100 bg-gray-600'>
                <th className="fw-normal text-center">Investment</th>
                <th className="fw-normal text-center">Stake</th>
                  <th className="fw-normal text-center">Time Left</th>
                  <th className="fw-normal text-end">Exp. Earn</th>
                </tr>
              </thead>
              <tbody className='border-top-0'>
                {this.props.stakes.map((stake, index) =>
                  <tr className="align-middle" key={index.toString()} role="button" onClick={()=> this.showStakeView(stake)}>
                    <td width="" className="text-center">
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        {formattedAmount(stake.investAmount)}
                        <TokenIcon token={stake.investFromToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {stake.investFromToken.symbol}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        {formattedAmount(stake.stakeFromAmount)}
                        <TokenIcon token={stake.investFromToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {stake.investFromToken.symbol}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {formattedAmount(stake.stakeToAmount)}
                        <TokenIcon token={stake.investToToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {stake.investToToken.symbol}</span>
                      </div>
                    </td>
                    <td width="" className="text-center">
                      {stake.timeLeft.days > 0 &&
                        <>
                          <span className="text-gray-100">{stake.timeLeft.days}</span>
                          <span className="text-gray-400">d</span>
                        </>
                      }
                      <span className="text-gray-100 ms-2">{stake.timeLeft.hours}</span>
                      <span className="text-gray-400">h</span>
                    </td>
                    <td width="" className="text-end py-3">
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        <If condition={stake.isClaimable}>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-top">Reward is available to claim!</Tooltip>}>
                            <Coin size={12} className="text-success-light me-1 align-baseline"></Coin>
                          </OverlayTrigger>
                        </If>

                        {formattedAmount(stake.rewardAmount)}
                        <TokenIcon token={stake.rewardToken} size="18" className="mx-1" />
                        <span className="text-gray-400"> {stake.rewardToken.symbol}</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {this.props.stakes.length === 0 &&
              <div className="text-center py-5">No staked positions yet</div>
            }
          </Card.Body>
        </Card>

        <If condition={this.state.showsStakeView}>
          <StakeView
            stake={this.state.selectedStake}
            wallet={this.props.wallet}
            closeCallback={this.hideStakeView}
          />
        </If>
      </>
    );
  }
}

export default Home;
