import config from '../Config';
import { formattedAmount, fromContractDecimals, Globals } from '../utils';

class ContractMethodDescription {
  static registry = {};
  static register(contractType, methodName, methods) {
    this.registry[contractType] = this.registry[contractType] || {};
    this.registry[contractType][methodName] = methods;
  }

  static build(meta) {
    let description;
    try {

      if (!this.registry[meta.contractType] || !this.registry[meta.contractType][meta.methodName]) {
      }
      const methods = this.registry[meta.contractType][meta.methodName];

      const proxy = new Proxy({ meta: meta }, {
        get: function(target, prop) {
          if (methods.hasOwnProperty(prop)) {
            return methods[prop].bind(proxy).call();
          } else {
            return target[prop];
          }
        }
      });
      description = proxy.description;
    } catch (error) {
      console.error("Error building description for: ", meta, error);
      if (config.env === 'development') {
      description = `${meta.contractType}.${meta.methodName}(${meta.methodArgs.join(', ')})`;
      } else {
        description = meta.methodName;
      }
    }
    return description;
  }
}

ContractMethodDescription.register('ERC20', 'approve', {
  token() {
    let token = Globals.wallet.token(this.meta.contractAddress);
    if (!token) {
      token = Globals.wallet.pools.find(this.meta.contractAddress);
    }
    return token;
  },
  amount() { return formattedAmount(fromContractDecimals(this.meta.methodArgs[1], this.token.decimals)); },
  description() { return `Approve spending ${this.amount} ${this.token.symbol}`; }
});

ContractMethodDescription.register('Approval', 'call', {
  token() {
    let token = Globals.wallet.token(this.meta.methodArgs.at(0));
    if (!token) token = Globals.wallet.pools.find(this.meta.methodArgs.at(0));
    return token;
  },
  amount() { return formattedAmount(fromContractDecimals(this.meta.methodArgs[1], this.token.decimals)); },
  description() { return `Approve spending ${this.amount} ${this.token.symbol}`; }
});

ContractMethodDescription.register('GasslessSign', 'call', {
  token() { return this.meta.methodArgs.at(0); },
  amount() { return formattedAmount(this.meta.methodArgs.at(4)); },
  description() { return `Approve spending ${this.amount} ${this.token.symbol}`; }
});

ContractMethodDescription.register('GasslessExchange', 'call', {
  token() { return this.meta.methodArgs.at(0); },
  description() { return `Swap ${this.token.symbol} for ${Globals.wallet.native_currency}`; }
});

ContractMethodDescription.register('router', 'swapExactTokensForTokens', {
  fromToken() { return Globals.wallet.token(this.meta.methodArgs.at(2).at(0)); },
  toToken() { return Globals.wallet.token(this.meta.methodArgs.at(2).at(-1)); },
  amount() { return formattedAmount(fromContractDecimals(this.meta.methodArgs.at(0), this.fromToken.decimals)); },
  description() { return `Swap ${this.amount} ${this.fromToken.symbol} for ${this.toToken.symbol}`; }
});

ContractMethodDescription.register('router', 'swapExactETHForTokens', {
  fromToken() { return Globals.wallet.nativeToken(); },
  toToken() { return Globals.wallet.token(this.meta.methodArgs.at(1).at(-1)); },
  amount() { return formattedAmount(fromContractDecimals(this.meta.methodParams.value, this.fromToken.decimals)); },
  description() { return `Swap ${this.amount} ${this.fromToken.symbol} for ${this.toToken.symbol}`; }
});

ContractMethodDescription.register('router', 'swapExactTokensForETH', {
  fromToken() { return Globals.wallet.token(this.meta.methodArgs.at(2).at(0)); },
  toToken() { return Globals.wallet.nativeToken(); },
  amount() { return formattedAmount(fromContractDecimals(this.meta.methodArgs.at(0), this.fromToken.decimals)); },
  description() { return `Swap ${this.amount} ${this.fromToken.symbol} for ${this.toToken.symbol}`; }
});

ContractMethodDescription.register('WETH', 'withdraw', {
  fromToken() { return Globals.wallet.wrappedToken(); },
  toToken() { return Globals.wallet.nativeToken(); },
  description() { return `Swap ${this.fromToken.symbol} for ${this.toToken.symbol}`; }
});

ContractMethodDescription.register('WETH', 'deposit', {
  fromToken() { return Globals.wallet.nativeToken(); },
  toToken() { return Globals.wallet.wrappedToken(); },
  description() { return `Swap ${this.fromToken.symbol} for ${this.toToken.symbol}`; }
});

ContractMethodDescription.register('router', 'addLiquidity', {
  description() { return `Add liquidity`; }
});

ContractMethodDescription.register('router', 'addLiquidityETH', {
  description() { return `Add liquidity`; }
});

ContractMethodDescription.register('router', 'removeLiquidity', {
  description() { return `Remove liquidity`; }
});

ContractMethodDescription.register('router', 'removeLiquidityETH', {
  description() { return `Remove liquidity native`; }
});

ContractMethodDescription.register('staking', 'stake', {
  description() { return `Stake liquidity`; }
});

ContractMethodDescription.register('staking', 'unStake', {
  description() { return `Withdraw Stake`; }
});

ContractMethodDescription.register('bootstrap', 'stake', {
  description() { return `Boost Liquidity`; }
});

ContractMethodDescription.register('bootstrap', 'unStake', {
  description() { return `Withdraw Liquidity`; }
});

ContractMethodDescription.register('bootstrap', 'claimRewards', {
  description() { return `Collect Reward`; }
});

ContractMethodDescription.register('crosschain', 'xSwap', {
  description() { return `Crosschain Swap (Source Chain)`; }
});

ContractMethodDescription.register('CrosschainWatch', 'call', {
  description() { return `Crosschain Swap (Destination Chain)`; }
});

export default ContractMethodDescription;
