import React from 'react';
import {ComposedChart, ResponsiveContainer, XAxis,
  YAxis, Tooltip, Legend, CartesianGrid, Line, Bar} from 'recharts';
import Charts from './Charts';

class DexBarChart extends React.Component {
  constructor(props) {
    super(props);
    let dataKeyProp;
    if (props.dataKey) {
      dataKeyProp = props.dataKey;
    } else {
      dataKeyProp = 'volume';
    }
    this.state = {
      datakey: dataKeyProp
    }
  }

  formatTick(value) {
    console.log(value);
    if (value >= 2000) {
      if (value > 1000000) {
        return (value / 1000000).toFixed(0) + 'M';
      }
      return (value / 1000).toFixed(0) + 'K';
    }
    return value.toFixed(2);
  }

  formatXTick(value) {
    let x = new Date(value);
    return `${x.getMonth() + 1}/${x.getDate()}`;
  }

  render() {
    return (
      <ResponsiveContainer minHeight={250} >
        <ComposedChart data={this.props.data}>
          <XAxis dataKey="date" tick={Charts.tickStyle} tickFormatter={Charts.shortDateFormatter}/>
          <YAxis  tick={Charts.tickStyle} type="number" tickFormatter={this.formatTick} domain={[0, dataMax => (dataMax * 1.5)]} />
          <Tooltip labelFormatter={Charts.longDateFormatter} labelClassName="text-gray-600" />
          <CartesianGrid stroke={Charts.gridStrikeColor} />
          <Bar dataKey={this.state.datakey} fill={Charts.barColor} name={this.props.name}/>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default DexBarChart;
