import { formattedAmount } from "../utils";

const Charts = {
  shortDateFormatter(value) {
    if (!value.toString().match(/\d+-\d+-\d+/)) return "";
    let date = new Date(value);
    return new Intl.DateTimeFormat(navigator.language, { day: 'numeric', month: 'short' }).format(date);
  },

  longDateFormatter(value) {
    if (!value.toString().match(/\d+-\d+-\d+/)) return "";
    let date = new Date(value);
    return new Intl.DateTimeFormat(navigator.language, { dateStyle: "medium" }).format(date);
  },

  numberFormatter(value) {
    if (value >= 1000) {
      if (value > 1000000) {
        return (value / 1000000).toFixed(2) + 'M';
      }
      return (value / 1000).toFixed(1) + 'K';
    }
    return formattedAmount(value);
  },

  tickStyle: {
    fontSize:  10,
    "font-family": "sens-serif",
    fill: '#EEF2F6'
  },
  gridStrikeColor: '#697586',
  lineColor: '#D444F1',
  line2Color: '#7e60d2',
  barColor: '#7e60d2'
}

export default Charts;
