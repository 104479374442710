import { toContractDecimals, isNativeCurrency, Globals, isWrappedNativeCurrency } from "../utils";
import Contracts from "../Blockchain/Contracts";
import Deadline from "../Blockchain/Deadline";
import Transaction from "../Blockchain/Transaction";
import Approval from "../Blockchain/Approval";
import GasslessTransaction from "../Blockchain/GasslessTransaction";

class Swapper {
  constructor(wallet, amountIn, amountOutMin, fromTokenAddress, toTokenAddress, path, routerAddress = undefined, feesPayment = { type: 'native' }) {
    this.wallet = wallet;
    this.web3 = wallet.web3;
    this.amountIn = amountIn;
    this.amountOutMin = amountOutMin;
    this.fromTokenAddress = fromTokenAddress;
    this.toTokenAddress = toTokenAddress;
    this.path = path;
    this.fromToken = wallet.token(this.fromTokenAddress);
    this.toToken = wallet.token(this.toTokenAddress);
    if (routerAddress === undefined) routerAddress = this.wallet.routerAddress;
    this.routerAddress = routerAddress;
    this.feesPayment = feesPayment;
    this.swapContract = Contracts.build("router", routerAddress);
  }

  get amountInWei() {
    return toContractDecimals(this.amountIn, this.fromToken.decimals);
  }

  get amountOutMinWei() {
    return toContractDecimals(this.amountOutMin, this.toToken.decimals);
  }

  prepare() {
    this.transaction = new Transaction();
    this.build();
    return this.transaction;
  }

  async execute() {
    return this.transaction.execute();
  }

  build() {
    if (this.feesPayment.type === 'token') {
      let gassless = GasslessTransaction.prepare(
        this.feesPayment.token,
        "1",
        Globals.address,
        Globals.currentBlockchain.config.contracts.gassless,
        this.feesPayment.amount
      );
      this.transaction.merge(gassless.transaction);
    }

    if (this.isETHtoWETH()) return this.swapETHtoWETH();
    if (this.isWETHtoETH()) return this.swapWETHtoETH();
    if (isNativeCurrency(this.fromTokenAddress)) return this.swapETHForTokens();
    if (isNativeCurrency(this.toTokenAddress)) return this.swapTokensForETH();
    return this.swapTokensForTokens();
  }

  swapETHtoWETH() {
    this.transaction
      .add(
        Contracts.weth.methods.deposit(),
        { from: this.wallet.address, value: this.amountInWei }
      );
  }

  swapWETHtoETH() {
    this.transaction
      .add(
        new Approval(this.fromTokenAddress, this.amountInWei, this.routerAddress),
        { from: Globals.address }
      );

    this.transaction
      .add(
        Contracts.weth.methods.withdraw(this.amountInWei),
        { from: Globals.address }
      );
  }

  swapETHForTokens() {
    let exchangePath = this.path;
    exchangePath[0] = Globals.wrapped_currency_address;
    this.transaction
      .add(
        this.swapContract.methods
        .swapExactETHForTokens(this.amountOutMinWei, exchangePath, this.wallet.address, Deadline.default()),
        { from: this.wallet.address, value: this.amountInWei }
      );
  }

  swapTokensForETH() {
    let exchangePath = this.path;
    exchangePath[exchangePath.length - 1] = Globals.wrapped_currency_address;

    this.transaction
      .add(
        new Approval(this.fromTokenAddress, this.amountInWei, this.routerAddress),
        { from: Globals.address }
      );

    this.transaction
      .add(
        this.swapContract.methods
        .swapExactTokensForETH(this.amountInWei, this.amountOutMinWei, exchangePath, this.wallet.address, Deadline.default()),
        { from: Globals.address }
      );
  }

  swapTokensForTokens() {
    this.transaction
      .add(
        new Approval(this.fromTokenAddress, this.amountInWei, this.routerAddress),
        { from: Globals.address }
      );

    this.transaction
      .add(
        this.swapContract.methods
        .swapExactTokensForTokens( this.amountInWei, this.amountOutMinWei, this.path, this.wallet.address, Deadline.default()),
        { from: Globals.address }
      );
  }

  isETHtoWETH() {
    return (isNativeCurrency(this.fromTokenAddress) && isWrappedNativeCurrency(this.toTokenAddress));
  }
  isWETHtoETH() {
    return (isWrappedNativeCurrency(this.fromTokenAddress) && isNativeCurrency(this.toTokenAddress));
  }
}

export default Swapper;
