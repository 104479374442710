import React from 'react';
import DexLineChart from './DexLineChart';
import GenericSinceChart from './GenericSinceChart';

class TvlChart extends GenericSinceChart {
  getData() {
    this.api.tvlData(this.props.chainId, this.props.tokenAddress, this.state.since.toISOString()).then((data) => {
      this.setState({
        data: data
      });
    });
  }

  graphName() {
    return 'TVL';
  }

  chartComponent() {
    return (
      <DexLineChart data={this.state.data} name='TVL' dataKey='price' tickFormatter={this.formatTick}/>
    );
  }

}

export default TvlChart;
