import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import { GraphUpArrow } from 'react-bootstrap-icons';
import ChainChange from '../Components/ChainChange';
import SwapBox from './SwapBox';
import SwapChart from './SwapChart';
import UnifiedSwap from './UnifiedSwap';

class Swap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromToken: undefined,
      toToken: undefined,
      showCharts: false
    }
    this.setFromToken = this.setFromToken.bind(this);
    this.setToToken = this.setToToken.bind(this);
    this.toggleShowCharts = this.toggleShowCharts.bind(this);
  }

  setFromToken(token) {
    this.setState({
      fromToken: token
    });
  }

  setToToken(token) {
    this.setState({
      toToken: token
    });
  }

  toggleShowCharts() {
    this.setState({
      showCharts: !this.state.showCharts
    });
  }

  render() {
    return(
      <>
        <Row className='justify-content-center'>
          <Col className={this.state.showCharts ? "col-12 col-lg-6 col-xl-8 mb-3" : "d-none"}>
            {this.state.fromToken !== undefined && this.state.toToken !== undefined &&
              <SwapChart
                graphType={this.state.graph}
                fromToken={this.state.fromToken}
                toToken={this.state.toToken}
                wallet={this.props.wallet}
              />
            }
          </Col>
          <Col className={this.state.showCharts ? "col-12 col-lg-6 col-xl-4" : "col-10 col-lg-6"}>
            <UnifiedSwap
              wallet={this.props.wallet}
              tokens={this.props.tokens}
              setFromTokenCallback={this.setFromToken}
              setToTokenCallback={this.setToToken}
              toggleShowCharts={this.toggleShowCharts}
            />
          </Col>
        </Row>
    </>
    );
  }
}
export default Swap;
