import React from 'react';
import { Spinner } from 'react-bootstrap';
import DexversIcon from '../Assets/dexvers-token.svg'

class Loading extends React.Component {
  static defaultProps = {
    message: 'Please wait ...'
  }

  render() {
    return(
      <>
        <div className={this.props.className + " bg-gray-700 bg-opacity-35 rounded-3 p-3 border border-secondary mt-3"}>
          <div className="d-flex py-4">
            <div className="m-auto position-relative">
              <div className="d-flex position-absolute" style={{ width: "3rem", height: "3rem" }}>
                <img src={DexversIcon} className="m-auto" alt="dexvers"/>
              </div>
              <Spinner
                    animation="border"
                    variant="secondary"
                    style={{ width: "3rem", height: "3rem" }} />
            </div>
          </div>
          {this.props.message && <div className="text-center text-gray-400 mt-2 mb-3">{this.props.message}</div>}
        </div>
      </>
    );
  }
}

export default Loading;
