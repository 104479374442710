import config from "../Config";
import BlockchainConfigs from "../Config/blockchains";
import costsData from "../Config/costs";
import Estimator from "../Wallet/Estimator";
import { ContractsFactory } from "./ContractsFactory";
import Tokens from "./Tokens";
import Web3 from "web3";

class Blockchain {
  static loadAll() {
     return config.blockchains.map((name) => {
      return new Blockchain(name);
    });
  }
  constructor(name) {
    this.config = BlockchainConfigs[name];
    this.label = name;
    this.tokens = new Tokens(this);
    this.contracts = new ContractsFactory(this);
  }

  get name() {
    return this.config.name;
  }

  get id() {
    return this.config.id;
  }

  get iconUrl() {
    return this.config.urls.icon;
  }

  get web3() {
    if (this._web3 === undefined) {
      let provider = new Web3.providers.HttpProvider(
        this.config.urls.rpc,
        {}
      );
      this._web3 = new Web3(provider);
    }
    return this._web3;
  }

  set web3(web3) {
    this._web3 = web3;
  }

  get costs() {
    return costsData[this.id];
  }

  explorerUrl(txHash) {
    return this.config.urls.explorer + '/tx/' + txHash;
  }

  token(address) {
    return this.tokens.find(address);
  }

  isNativeToken(token) {
    let address = token.address || token;
    return address?.toLowerCase() === this.config.nativeTokenAddress.toLowerCase();
  }

  isWrappedNativeToken(token) {
    let address = token.address || token;
    return address?.toLowerCase() === this.config.wrappedNativeTokenAddress.toLowerCase();
  }

  isPermitToken(token) {
    let address = token.address || token;
    return this.config.tokensWithPermit.find((permitAddress) => { return permitAddress.toLowerCase() === address?.toLowerCase() }) !== undefined;
  }

  async estimateAmountOut(amountIn, fromToken, toToken) {
    return Estimator.estimateAmountOut(amountIn, fromToken, toToken, this);
  }

  async estimateAmountIn(amountOut, fromToken, toToken) {
    return Estimator.estimateAmountIn(amountOut, fromToken, toToken, this);
  }
}

export default Blockchain;
