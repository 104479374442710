import React from 'react';
import { Nav } from 'react-bootstrap';
import If from '../Components/If';
import PriceVolumeChart from '../Graphs/PriceVolumeChart';
import TvlChart from '../Graphs/TvlChart';
import WalletCountChart from '../Graphs/WalletCountChart';

class SwapChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graph: 1
    }
    this.setGraph = this.setGraph.bind(this);
  }

  setGraph(graph) {
    this.setState({
      graph: graph
    });
  }

  graphs = [
    {name: 'Price & Volume', id: 1},
    {name: 'TVL', id: 2},
    {name: 'Wallets', id: 3}
  ]

  render() {
    return(
      <>
        <div className="h-100 bg-gray-700 bg-opacity-35 rounded rounded-3 px-3 py-4">
          <h5>{this.props.fromToken.symbol} / {this.props.toToken.symbol} Statistics</h5>
          <Nav variant='pills' className='mt-4'>
            {this.graphs.map((graph) =>
              <Nav.Item key={graph.id} className="small">
                <Nav.Link className='py-1' active={this.state.graph === graph.id} onClick={()=> this.setGraph(graph.id)}>{graph.name}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <div className="mt-5">
            <If condition={this.state.graph === 1}>
              <PriceVolumeChart chainId={this.props.toToken.blockchainId || this.props.wallet.chainId} tokenAddress={this.props.toToken.address} />
            </If>
            <If condition={this.state.graph === 2}>
              <TvlChart chainId={this.props.toToken.blockchainId || this.props.wallet.chainId} tokenAddress={this.props.toToken.address}/>
            </If>
            <If condition={this.state.graph === 3}>
              <WalletCountChart chainId={this.props.toToken.blockchainId || this.props.wallet.chainId} tokenAddress={this.props.toToken.address}/>
            </If>
          </div>
        </div>
      </>
    );
  }
}

export default SwapChart;
