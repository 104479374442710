import defaults from "./Config/defaults";
import development from "./Config/development";
import staging from "./Config/staging";
import production from "./Config/production";
import bootstrap from "./Config/bootstrap";
import local from "./Config/local";

import deepmerge from "deepmerge";

const envs = {
  "beta.dexvers.com": "staging",
  "app.dexvers.com": "production"
}

const mode = process.env.REACT_APP_BOOSTRAP === "true" ? "bootstrap" : "dex";

const configs = {
  development: development,
  staging: staging,
  production: production,
  bootstrap: bootstrap
}

let env = envs[window.location.host] || process.env.REACT_APP_FORCE_ENV || process.env.NODE_ENV;
let config = defaults;

if (mode === "bootstrap") {
  config = deepmerge.all([config, configs.bootstrap]);
} else {
  config = deepmerge.all([config, configs[env]]);
  if (env === "development")
    config = deepmerge.all([config, local], { arrayMerge: (dest, src, options) => src });
}
config = deepmerge.all([config, { env: env, mode: mode }]);

export default config;
