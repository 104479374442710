import { toContractDecimals, Globals, fromContractDecimals, fromContractDecimalsToNumber } from "../utils";
import BigNumber from "bignumber.js";
import ExchangePath from '../Apis/ExchangePath';

class Estimator {
  constructor(blockchain, factoryAddress, routerAddress) {
    this.blockchain = blockchain;
    this.factoryAddress = factoryAddress;
    this.routerAddress = routerAddress;
  }


  static async estimateAmountOut(amountIn, fromToken, toToken, blockchain = undefined) {
    blockchain ||= Globals.currentBlockchain;
    return Estimator.external(blockchain).set(amountIn, 0, fromToken, toToken).estimateAmountOut()
      .catch((e) => {
        console.log("estimateAmountOut error with external defi: ", e)
        return Estimator.dexvers(blockchain).set(amountIn, 0, fromToken, toToken).estimateAmountOut()
          .catch((e) => {
            console.log("estimateAmountOut error with dexvers defi: ", e);
            return {};
          })
      })
  }


  static async estimateAmountIn(amountOut, fromToken, toToken, blockchain = undefined) {
    blockchain ||= Globals.currentBlockchain;
    return Estimator.external(blockchain).set(0, amountOut, fromToken, toToken).estimateAmountIn()
      .catch(() => {
        return Estimator.dexvers(blockchain).set(0, amountOut, fromToken, toToken).estimateAmountIn();
      })
  }
  static dexvers(blockchain) {
    return new Estimator(
      blockchain,
      blockchain.config.contracts.factory,
      blockchain.config.contracts.router
    );
  }

  static external(blockchain) {
    return new Estimator(
      blockchain,
      blockchain.config.contracts.externalFactory,
      blockchain.config.contracts.externalRouter
    );
  }

  set(amountIn, amountOut, fromToken, toToken) {
    this.amountIn = amountIn;
    this.amountOut = amountOut;
    this.fromToken = this.blockchain.token(fromToken);
    this.toToken = this.blockchain.token(toToken);
    if(!this.isNativeExchange()) {
      if (this.fromToken.native)
        this.fromToken = this.blockchain.tokens.wrappedNative;
      if (this.toToken.native)
        this.toToken = this.blockchain.tokens.wrappedNative;
    }
    return this;
  }

  async findPath() {
    return new ExchangePath()
      .load(this.blockchain.id, this.factoryAddress, this.fromToken.address, this.toToken.address)
      .then((path) => {
        this.path = path;
      });
  }

  async getGasPrice() {
    return this.blockchain.web3.eth.getGasPrice()
      .then((gasPrice) => {
        this.gasPrice = gasPrice;
      });
  }

  computeGasCost() {
    let approvalGas = this.blockchain.costs.approval;
    let swapGas = this.blockchain.costs.swap[this.path.length] || this.blockchain.costs.swap[4];
    let gasCost = new BigNumber(approvalGas).plus(swapGas).times(this.gasPrice);
    let divisor = BigNumber(Math.pow(10, 18));
    return gasCost.dividedBy(divisor).toNumber();
  }

  get routerContract() {
    return this.blockchain.contracts.build('router', this.routerAddress);
  }

  get factoryContract() {
    return this.blockchain.contracts.build('factory', this.factoryAddress);
  }

  async estimateAmountOut() {
    if (this.isNativeExchange()) return this.nativeExchangeResult(this.amountIn);
    if (this.amountIn === 0) return Promise.resolve({ amount: 0, gas: 0 });
    if (this.factoryAddress === undefined) return Promise.reject();

    return this.findPath()
      .then(() => { return this.getGasPrice() })
      .then(() => { return this._estimateAmountOut() });
  }

  async _estimateAmountOut() {
    if (this.path.length === 0)
      return Promise.reject();
    let amount = toContractDecimals(this.amountIn, this.fromToken.decimals);
    console.log("EstimateAmountOut", this.blockchain, this.factoryAddress, this.routerAddress, this.path, amount)
    return this.routerContract.methods.getAmountsOut(amount, this.path)
      .call()
      .then(async (result) => {
        console.log("Amounts out result", result);
        let amountOut = fromContractDecimalsToNumber(result[result.length - 1], this.toToken.decimals);
        return {
          amount: amountOut,
          slippage: this.slippage(amountOut),
          impact: await this.impact(this.amountIn, amountOut),
          routerAddress: this.routerAddress,
          path: this.path,
          gas: this.computeGasCost()
        };
      });
  }

  async estimateAmountIn() {
    if (this.isNativeExchange()) return this.nativeExchangeResult(this.amountOut);
    if (this.amountOut === 0) return Promise.resolve({ amount: 0, gas: 0 });
    if (this.factoryAddress === undefined) return Promise.reject();

    return this.findPath()
      .then(() => { return this.getGasPrice() })
      .then(() => { return this._estimateAmountIn() });
  }

  async _estimateAmountIn() {
    if (this.path.length === 0)
      return Promise.reject();
    let amount = toContractDecimals(this.amountOut, this.toToken.decimals);
    return this.routerContract.methods.getAmountsIn(amount, this.path)
      .call()
      .then(async (result) => {
        console.log("Amounts in result", result);
        let amountIn = fromContractDecimalsToNumber(result[0], this.fromToken.decimals);
        return {
          amount: amountIn,
          slippage: this.slippage(this.amountOut),
          impact: await this.impact(amountIn, this.amountOut),
          routerAddress: this.routerAddress,
          path: this.path,
          gas: this.computeGasCost()
        };
      });

  }

  isNativeExchange() {
    return (this.fromToken.native || this.fromToken.wrappedNative)
      &&
      (this.toToken.native || this.toToken.wrappedNative);
  }

  nativeExchangeResult(amount) {
    return Promise.resolve({
      amount: amount,
      slippage: this.slippage(amount),
      impact: 0,
      gas: 0
    })
  }

  async impact(amountIn, amountOut) {
    try {
      if (this.path.length > 2)
        return undefined;
      let pair = await this.factoryContract.methods.getPair(this.fromToken.address, this.toToken.address).call();
      let pairContract = this.blockchain.contracts.build('pair', pair);
      let pair_token0 = await pairContract.methods.token0().call();
      let reserves = await pairContract.methods.getReserves().call();
      let reserve_token0 = new BigNumber(fromContractDecimals(reserves.reserve0, this.fromToken.decimals));
      let reserve_token1 = new BigNumber(fromContractDecimals(reserves.reserve1, this.toToken.decimals));
      if (pair_token0.toLowerCase() !== this.fromToken.address.toLowerCase()) {
        console.log("Swapping reserves");
        reserve_token0 = new BigNumber(fromContractDecimals(reserves.reserve1, this.fromToken.decimals));
        reserve_token1 = new BigNumber(fromContractDecimals(reserves.reserve0, this.toToken.decimals));
      }
      let currentPrice = reserve_token0.dividedBy(reserve_token1);
      let newPrice = reserve_token0
        .plus(amountIn)
        .dividedBy(reserve_token1.minus(amountOut));
      console.log("New Price:", newPrice.toString(), "Old Price:", currentPrice.toString());
      let priceImpact = currentPrice.minus(newPrice).absoluteValue().dividedBy(currentPrice).multipliedBy(100);
      return priceImpact.toFixed(2);
    } catch (error) {
      console.log("Failed to calculate impact", error);
      return undefined;
    }
  }

  slippage(amount, slippage = 0.5) {
    let a = new BigNumber(amount);
    let res = a.multipliedBy((100 - slippage) / 100);
    return res.toNumber();
  }

}

export default Estimator;
