import { EventEmitter } from "events";
import Contracts from "../Blockchain/Contracts";
import Stake from "./Stake";

class Stakes extends EventEmitter {
  constructor(wallet) {
    super();
    this.wallet = wallet;
    this.list = []
    this.loaded = false;
  }

  async load(force = false) {
    if (this.loaded && !force) return Promise.resolve();
    this.list = [];
    if (this.wallet.web3 === undefined)
      return Promise.reject("No web3 connection");
    let contract = Contracts.staking;
    return this.loadAtIndex(contract, 0)
      .then(() => {
        this.emit("change");
        this.loaded = true;
        return this.list;
      });
  }

  async loadAtIndex(contract, index) {
    return contract.methods.stakes(this.wallet.address, index).call()
      .then((stake) => {
        stake.index = index;
        let stakeObject = new Stake(stake, this.wallet);
        this.list[index] = stakeObject;
        return Promise.all([
          stakeObject.init(),
          this.loadAtIndex(contract, index + 1)
        ]);
      })
      .catch((_) => {});
  }

  find(address) {
    return this.list.find((stake) => {
      return stake.lpAddress === address;
    })
  }

  refresh() {
    this.load(true);
  }
}

export default Stakes;
