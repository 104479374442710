import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Stats from './Dashboard/Stats';
import WalletTransactionList from './Dashboard/WalletTransactionList';
import Farms from './Earn/Home/Farms';
import Pools from './Earn/Home/Pools';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div>
          <div>The Dashboard</div>
          <div className="small text-muted">
            Your wallet is your account. See what's new, check earn performance,
            and keep track of  your recent transaction.
          </div>
        </div>
        <Stats className="mt-4" wallet={this.props.wallet}></Stats>
        <Row xs={1} lg={2}>
          <Col className='mt-4'>
            <Pools
              balances={this.props.balances}
              wallet={this.props.wallet}
              address={this.props.address}
            />
          </Col>
          <Col className='mt-4'>
            <Farms
              balances={this.props.balances}
              wallet={this.props.wallet}
              address={this.props.address}
            />
          </Col>
        </Row>
        <Row xs={1} lg={1} className='mt-4'>
          <Col className=''>
            <WalletTransactionList walletAddress={this.props.address} chainId={this.props.wallet.chainId}/>
          </Col>
        </Row>
      </>
    );
  }
}

export default Dashboard;
