import { EventEmitter } from "events";
import tokens from "../Config/tokens";
import DexversTokens from "../DexversTokens";
import { Globals } from "../utils";
import crosschain from "../Config/crosschain";

class Tokens extends EventEmitter {
  constructor(blockchain) {
    super();
    this.blockchain = blockchain;
    this.items = tokens[blockchain.id] || [];
    this.postprocess();
    this.withBalance = this.items;
    this.load();
  }

  load() {
    const api = new DexversTokens();
    api
      .load(this.blockchain.id, Globals.address)
      .then((data) => {
        this.items = data;
        this.postprocess();
        this.withBalance = data.filter((token) => token.balance > 0);
        if(this.withBalance.length === 0)
          this.withBalance = this.items;
        this.emit("loaded");
        return this.items;
      })
      .catch((error) => {
        console.log("Tokens.load error", error);
        return true;
      });
  }

  postprocess() {
    this.setCrosschainable();
    this.items.forEach((token) => {
      token.blockchainId = this.blockchain.id;
      token.native = this.blockchain.isNativeToken(token);
      token.wrappedNative = this.blockchain.isWrappedNativeToken(token);
      token.permit = this.blockchain.isPermitToken(token);
    });
  }

  setCrosschainable() {
    this.items.forEach((token) => {
      let pair = crosschain.tokens.find((pair) => {
        return pair[this.blockchain.label]?.toLowerCase() === token.address?.toLowerCase();
      });
      if (pair) {
        token.crosschainable = true;
        token.crosschain = pair;
      } else {
        token.crosschainable = false;
      }
    });
  }

  get crosschainable() {
    return this.items.filter((token) => token.crosschainable);
  }

  get permit() {
    return this.items.filter((token) => token.permit);
  }

  get native() {
    return this.items.find((token) => token.native);
  }

  get wrappedNative() {
    return this.items.find((token) => token.wrappedNative);
  }

  find(address) {
    return this.items.find((token) => token.address.toLowerCase() === address?.toLowerCase());
  }
}

export default Tokens;
