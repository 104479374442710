import React from 'react';
import { Table } from 'react-bootstrap';
import { formattedAmount } from '../utils';
class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      })
    }
  }

  render() {
    return (
        <Table className="small mb-0" variant='dark' responsive>
          <thead>
            <tr>
              <th className='fw-normal'>From</th>
              <th className='fw-normal'>To</th>
              <th className='fw-normal'>Amount</th>
              <th className='fw-normal'>Date</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((tt, index) =>
              <tr key={index} className="text-white align-middle">
                <td onClick={() => this.props.setWalletCallback(tt.from)}>{tt.from}</td>
                <td onClick={() => this.props.setWalletCallback(tt.to)}>{tt.to}</td>
                <td className='text-end'>
                  <a target="_blank" rel="noreferrer" href={`${this.props.explorerUrl}/tx/${tt.transaction_hash}`}>
                    {formattedAmount(tt.amount)} {tt.token_symbol}
                  </a>
                </td>
                <td>{tt.date}</td>
              </tr>
            )}
          </tbody>
        </Table>
    );
  }

}

export default TransactionList;
