import React from 'react';
import DexBarChart from './DexBarChart';
import GenericSinceChart from './GenericSinceChart';


class VolumeChart extends GenericSinceChart {
  getData() {
    this.api.volumeData(this.props.chainId, this.props.tokenAddress, this.state.since.toISOString()).then((data) => {
      console.log("Volume data:", data);
      this.setState({
        data: data
      });
    });
  }

  graphName() {
    return 'Volume';
  }

  chartComponent() {
    return (
      <DexBarChart data={this.state.data} name='Volume'/>
    );
  }
}


export default VolumeChart;
