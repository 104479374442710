let costs = {
  137: {
    approval: 100000,
    swap: {
      2: 200000,
      3: 300000,
      4: 400000
    },
    crosschain: 1000000
  },
  56: {
    approval: 100000,
    swap: {
      2: 200000,
      3: 300000,
      4: 400000
    },
    crosschain: 1000000
  },
  43114: {
    approval: 100000,
    swap: {
      2: 200000,
      3: 300000,
      4: 400000
    },
    crosschain: 1000000
  }
};

export default costs;
