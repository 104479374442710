import React from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import AmountInput from './Components/AmountInput';
import TokenSelector from './TokenSelector';
import If from './Components/If';
import TokenIcon from './Components/TokenIcon';
import TokenBalance from './Components/TokenBalance';
import { toContractDecimals } from './utils';
import BigNumber from 'bignumber.js';

class TokenSelectForSwap extends React.Component {
  static defaultProps = {
    readOnly: false,
    hideAmount: false,
    canChangeTokens: true,
    validate: false,
    showBalance: true
  }

  constructor(props) {
    super(props);
    this.state = {
      showsTokenSelector: false,
      selectedToken: props.token || this.props.balances[0],
      tokenValue: 0,
      valid: true
    }
    this.showTokenSelector = this.showTokenSelector.bind(this);
    this.hideTokenSelector = this.hideTokenSelector.bind(this);
    this.setselectedToken = this.setselectedToken.bind(this);
    this.setTokenValue = this.setTokenValue.bind(this);
    this.balanceChanged = this.balanceChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ tokenValue: this.props.value }, this.validate);
    }
    if (this.props.token !== prevProps.token) {
      this.setState({ selectedToken: this.props.token }, this.validate);
    }
  }

  setTokenValue(val) {
    this.setState({ tokenValue: val }, this.validate);
    this.props.setValueCallback(val);
  }

  setselectedToken(token) {
    this.props.setTokenCallback(token.address);
    this.setState({ selectedToken: token }, this.validate);
    this.hideTokenSelector()
  }

  showTokenSelector() {
    if (!this.props.canChangeTokens)
      return;
    this.setState({ showsTokenSelector: true });
  }

  hideTokenSelector() {
    this.setState({ showsTokenSelector: false });
  }

  balanceChanged() {
    this.validate();
  }

  validate() {
    let valid  = !this.props.validate || this.state.tokenValue === 0 || !this.isOverBalance();
    if (this.props.onValidityChanged && valid !== this.state.valid)
      this.props.onValidityChanged(valid);
    this.setState({ valid: valid });
  }

  isOverBalance() {
    let value = new BigNumber(toContractDecimals(this.state.tokenValue, this.state.selectedToken.decimals));
    let balance = new BigNumber(this.state.selectedToken.balance);
    return value.gt(balance);
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className=''>
          <div className='flex-fill py-2 text-nowrap d-flex' role="button" onClick={this.showTokenSelector}>
            {this.props.token !== undefined &&
              <>
                <div>
                  <TokenIcon token={this.props.token} size={24}/>
                </div>
                <div className=' ms-1'>{this.props.token?.name}</div>
                <div className=' text-muted ms-1'>({this.props.token?.symbol})</div>
              </>
            }
            {this.props.token === undefined &&
              <div className='text-muted'>Select a token ...</div>
            }
            <If condition={this.props.canChangeTokens}>
              <ChevronDown className=' ms-auto mt-1'/>
            </If>
          </div>
          <div className={'w-100 ' + (this.props.hideAmount ? "d-none" : "")}>
            <AmountInput
              className="fs-4 fw-bold border-0 px-0 py-2 no-outline bg-transparent"
              value={this.props.value}
              placeholder="0"
              max={0}
              onChange={this.setTokenValue}
              readOnly={this.props.readOnly || this.props.token === undefined}
              token={this.state.selectedToken}
            />
          </div>
        </div>
        {this.props.showBalance &&
          <>
            <hr className="my-2" />
            <div className='d-flex'>
              {this.props.price > 0 &&
                <div className='text-muted'>
                  <span className=''>Price: </span>
                  { this.props.price.toFixed(4) }
                </div>
              }
              <div className='ms-auto text-end'>
                <span className=''>
                  Balance:&nbsp;
                  <TokenBalance token={this.props.token} onBalanceChanged={this.balanceChanged}></TokenBalance>
                  &nbsp;{this.props.token?.symbol}
                </span>
              </div>
            </div>
            <div className=''>
                {!this.state.valid &&
                  <div className='text-danger'>Insufficient wallet balance</div>
                }
            </div>
          </>
        }
        <If condition={this.state.showsTokenSelector}>
          <TokenSelector
            tokens={this.props.balances}
            setTokenCallback={this.setselectedToken}
            closeCallback={this.hideTokenSelector}
          />
        </If>
     </div>
    );
  }
}

export default TokenSelectForSwap;
