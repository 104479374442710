import { BigNumber } from "bignumber.js/bignumber.js";
import { formattedAmount, fromContractDecimals, Globals } from '../utils';
import AbstractContract from "./AbstractContract";
import Contracts from './Contracts';

class Approval extends AbstractContract {
  static displayName = 'Approval';

  constructor(address, amount, to) {
    super(address, amount, to);
    this.token = this.findToken(address);
    this.amount = amount;
    this.to = to;
    this.contract = Contracts.erc20(address)
  }

  estimateGas(params) {
    return this.contract.methods.approve(this.to, this.amount).estimateGas(params);
  }

  execute(params) {
    this.promise.eventEmitter.emit("sending");
    this.checkAllowance(params)
      .catch(() => { this.approve(params) });
    }

  checkAllowance(params) {
    return this.contract.methods.allowance(params.from, this.to).call(params)
      .then((allowance) => {
        this.promise.eventEmitter.emit("sent");
        console.log("Allowance", allowance);
        if (this.enoughAllowance(allowance)) {
          this.promise.resolve({ allowanceExists: true });
          return Promise.resolve(true);
        } else {
          return Promise.reject();
        }
      })
  }

  approve(params) {
    return this.contract.methods.approve(this.to, this.amount).send(params)
      .once("sending",         ()        => { this.promise.eventEmitter.emit("sending"); })
      .once("sent",            ()        => { this.promise.eventEmitter.emit("sent"); })
      .once("transactionHash", (hash)    => { this.promise.eventEmitter.emit("transactionHash", hash); })
      .once("receipt",         (receipt) => { this.promise.eventEmitter.emit("receipt", receipt); })
      .once("error",           (error)   => { this.promise.eventEmitter.emit("error", error); })
      .then((result) => { this.promise.resolve(result); })
      .catch((error) => { this.promise.reject(error); });
  }

  enoughAllowance(allowance) {
    let allowanceBN = new BigNumber(allowance);
    let amountBN = new BigNumber(this.amount);
    let enough = allowanceBN.isGreaterThanOrEqualTo(amountBN);
    console.log("Enough allowance?", enough, allowance, this.amount);
    return enough;
  }

  findToken(address) {
    let token = Globals.wallet.token(address);
    if (token === undefined)
      token = Globals.wallet.pools.find(address);
    return token;
  }

  get formattedAmount() {
    return formattedAmount(fromContractDecimals(this.amount, this.token.decimals));
  }
}

export default Approval;
