import React from 'react';
import { Card } from 'react-bootstrap';
import { formattedAmount } from '../utils';

class LiquidityPoolTokenInfo extends React.Component {
  constructor(props) {
    super(props);
    this.setToken = this.setToken.bind(this)
  }

  setToken() {
    this.props.setTokenCallback(this.props.token?.address);
    this.props.setLPCallback('');
  }

  render() {
    return (
      <Card id='token0'>
      <Card.Header>
        <Card.Title>
          {this.props.title}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <span style={{display: 'block', cursor: 'pointer'}} onClick={this.setToken}>
          Name: {this.props.token?.name}
        </span>
        <span style={{display: 'block'}}>
          Address: {this.props.token?.address}
        </span>
        <span style={{display: 'block'}}>
          Symbol: {this.props.token?.symbol}
        </span>
        <span style={{display: 'block'}}>
          Balance: {formattedAmount(this.props.balance)}
        </span>
      </Card.Body>
    </Card>

    );
  }
}

export default LiquidityPoolTokenInfo;