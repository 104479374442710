// component that displays a TrendingValue component with different colors depending on the value

import React from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import SimpleTooltip from '../Components/SimpleTooltip';

function PriceImpact(props) {
  let colorClass = () => {
    if (props.value === undefined || props.value === 0) {
      return 'text-muted';
    }
    if (props.value > 100) {
      return 'btn-danger btn py-0'
    }
    if (props.value >= 10) {
      return 'btn-warning btn py-0'
    }
    if (props.value >= 0) {
      return 'text-success';
    }
    return 'text-danger';
  }

  let value = () => {
    if (props.value === undefined) {
      return 'n/a';
    }
    return `${Math.abs(props.value)} %`;
  }

  return (
    <div className="d-flex mt-2 small">
      <div className="flex-fill">
        Price Impact
        &nbsp;
        <SimpleTooltip tooltip="How much the price changes">
          <QuestionCircle/>
        </SimpleTooltip>
      </div>
      <div className="ms-auto text-end">
        <div className={`${props.className} ${colorClass()}`}>
          {value()}
        </div>
      </div>
    </div>
  );


}

export default PriceImpact;
