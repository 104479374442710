import AbstractContract from './AbstractContract';

export class CrosschainWatch extends AbstractContract {
  static displayName = 'CrosschainWatch';

  constructor(sourceBlockchain, destinationBlockchain) {
    super();
    this.sourceBlockchain = sourceBlockchain;
    this.destinationBlockchain = destinationBlockchain;
  }

  static query = `query DestinationTransfer($hash: String!) {
    destinationTransfers(
      where: {
        transferId: $hash
      }
    ) {
      # Meta Data
      chainId
      nonce
      transferId
      to
      callData
      originDomain
      destinationDomain
      delegate
      # Asset Data
      asset {
        id
      }
      bridgedAmt
      # Executed event Data
      status
      routers {
        id
      }
      originSender
      # Executed Transaction
      executedCaller
      executedTransactionHash
      executedTimestamp
      executedGasPrice
      executedGasLimit
      executedBlockNumber
      # Reconciled Transaction
      reconciledCaller
      reconciledTransactionHash
      reconciledTimestamp
      reconciledGasPrice
      reconciledGasLimit
      reconciledBlockNumber
      routersFee
      slippage
    }
  }`;


  execute() {
    this.promise.eventEmitter.emit("sending");
    this.xid = this.state.results.at(-1).events.XTransferSent.returnValues.xid;
    console.log("CrosschainWatch: XID=", this.xid);
    console.log("CrosschainWatch: Result", this.state.results.at(-1));
    this.refresh();
  }

  refresh() {
    fetch(this.destinationBlockchain.config.urls.connextSubgraph, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: this.queryBody
    })
      .then(r => r.json())
      .then(data => this.process(data))
      .catch((error) => {
        console.error('CrosschainWatch Error:', error);
        setTimeout(() => this.refresh(), 1000);
      });
  }

  process(data) {
    console.log("CrosschainWatch:", data);
    let status = data?.data?.destinationTransfers?.at(0)?.status;
    console.log("CrosschainWatch: Status", status);
    // success states: Executed, CompletedSlow, CompletedFast
    if (status === "Executed" || status === "CompletedSlow" || status === "CompletedFast") {
      data.sourceBlockchain = this.sourceBlockchain;
      data.destinationBlockchain = this.destinationBlockchain;
      this.promise.resolve(data);
      return data;
    }
    // error states: LowSlippage:, LowRelayerFee, ExecutionError, NoBidsReceived
    if (status === "LowSlippage" || status === "LowRelayerFee" || status === "ExecutionError" || status === "NoBidsReceived") {
      data.sourceBlockchain = this.sourceBlockchain;
      data.destinationBlockchain = this.destinationBlockchain;
      this.promise.reject(data);
      return data;
    }
    setTimeout(() => this.refresh(), 1000);
  }

  get queryBody() {
    if (this._queryBody) return this._queryBody;
    this._queryBody = JSON.stringify({
      query: this.constructor.query,
      variables: { hash: this.xid }
    });
    return this._queryBody;
  }
}
