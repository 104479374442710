import { Globals, isNativeCurrency, toContractDecimals } from "../utils";
import Contracts from "../Blockchain/Contracts";
import Deadline from "../Blockchain/Deadline";

class Pool {
  constructor(data, wallet) {
    this.data = data;
    this.wallet = wallet;
  }

  static async getByPair(token0, token1, wallet) {
    if (token0 === Globals.native_currency_address)
      token0 = Globals.wrapped_currency_address;
    if (token1 === Globals.native_currency_address)
      token1 = Globals.wrapped_currency_address;
    let factory = Contracts.factory;
    return factory.methods.getPair(token0, token1).call()
      .then((address) => {
        if (address === Globals.no_address)
          return Promise.reject(new Error("Pair not found"));
        return this.getByAddress(address, wallet);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static async getByAddress(address, wallet) {
    return wallet.getPairInfo(address)
      .then((data) => {
        let pool = new Pool(data, wallet);
        return pool;
      });
  }

  get token0() {
    return this.data.token0;
  }

  get token1() {
    return this.data.token1;
  }

  get name() {
    return this.token0.name + " - " + this.token1.name + " LP";
  }

  get symbol() {
    return this.token0.symbol + "-" + this.token1.symbol + "-LP";
  }

  get decimals() {
    return 18;
  }

  get nonETHToken() {
    return isNativeCurrency(this.token0.address) ? this.token1 : this.token0;
  }

  get address() {
    return this.data.address;
  }

  get reserve0() {
    return this.wallet.web3.utils.fromWei(this.data.reserve0, this.token0.unit);
  }

  get reserve1() {
    return this.wallet.web3.utils.fromWei(this.data.reserve1, this.token1.unit);
  }

  get supply() {
    return this.wallet.web3.utils.fromWei(this.data.supply);
  }

  get balance() {
    return this.wallet.web3.utils.fromWei(this.data.balance);
  }

  get balanceWei() {
    return this.data.balance;
  }

  get percentage() {
    if (this.supply === "0")
      return 0;
    return (this.balance / this.supply * 100).toFixed(2);
  }

  get token0Balance() {
    if (this.supply === "0")
      return 0;
    return this.reserve0 * this.balance / this.supply;
  }

  get token1Balance() {
    if (this.supply === "0")
      return 0;
    return this.reserve1 * this.balance / this.supply;
  }

  get oneTokenIsETH() {
    return isNativeCurrency(this.token0.address) || isNativeCurrency(this.token1.address);
  }

  async refresh() {
    return this.wallet.getPairInfo(this.address)
      .then((data) => {
        this.data = data;
        return this;
      });
  }

  async remove() {
    return this.prepareRemove().execute();
  }

  prepareRemove(receiveETH = false) {
    console.log("prepareRemove", receiveETH);
    return this.removeLiquidity(receiveETH)
      .requiresApproval(this.address, this.balanceWei)
      .prepare({ from: this.wallet.address });
  }

  removeLiquidity(receiveETH = false) {
    if(receiveETH) {
      return this.removeLiquidityETH();
    } else {
      return this.removeLiquidityByPair();
    }
  }

  removeLiquidityByPair() {
    return Contracts.router.methods.removeLiquidity(
      this.token0.address, //tokens to remove
      this.token1.address,
      this.balanceWei, // liquidity amount to remove
      toContractDecimals(0), // min token0 to receive
      toContractDecimals(0), //min token1 to receive
      this.wallet.address, // where tokens should be sent
      Deadline.default() // timeout
    )
  }

  removeLiquidityETH() {
    return Contracts.router.methods.removeLiquidityETH(
      this.nonETHToken.address,
      this.balanceWei, // liquidity amount to remove
      toContractDecimals(0), // min token0 to receive
      toContractDecimals(0), //min token1 to receive
      this.wallet.address, // where tokens should be sent
      Deadline.default() // timeout
    )
  }
}

export default Pool;
