import React from 'react';
import { Col, Row, Button, Modal, Card, Table } from 'react-bootstrap';
import { formattedAmount } from '../utils';
import TokenIcon from '../Components/TokenIcon';
import PoolView from './Pool/PoolView';
import PoolsList from '../Apis/PoolsList';

class TopPools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: [],
      showsAddLiquidityForm: false,
      showsPoolView: false,
      selectedPool: null
    }
    this.showPoolView = this.showPoolView.bind(this);
    this.hidePoolView = this.hidePoolView.bind(this);
  }

  componentDidMount() {
    new PoolsList().load(this.props.wallet.chainId, "top-pools").then((pools) => {
      Promise.all(pools.map((pool) => {
        return this.props.wallet.pools.find_or_add(pool.address);
      })).then((pools) => {
        this.setState({ pools: pools });
      });
    });
  }

  showPoolView(pool) {
    this.setState({ showsPoolView: true, selectedPool: pool });
  }

  hidePoolView() {
    this.setState({ showsPoolView: false, selectedPool: null });
  }

  render() { return(
    <>
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={12} lg={"auto"}>
              <Card.Title>Top Pools</Card.Title>
              <Card.Subtitle className="text-secondary">The best of the best</Card.Subtitle>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <Table className="small mb-0" variant='dark'>
            <thead>
              <tr>
                <th className='fw-normal'>Liquidity Pairing</th>
                <th className='fw-normal text-end'>Investment</th>
                <th className='fw-normal text-end'>Total Rewards</th>
              </tr>
            </thead>
            <tbody>
              {this.state.pools.map((pool, index) =>
                <tr className="text-white align-middle" key={index.toString()} role="button" onClick={()=> this.showPoolView(pool)}>
                  <td width="31%">
                    <div className="d-flex align-items-center">
                      <div className="d-inline-block pair-icons">
                        <TokenIcon token={pool.token0} size="24" />
                        <TokenIcon token={pool.token1} size="24" />
                      </div>
                      <div className="d-inline-block ms-2">
                        {pool.token0.symbol}-{pool.token1.symbol}
                      </div>
                    </div>
                  </td>
                  <td width="23%" className="text-nowrap text-end">
                    <div className="d-flex align-items-center justify-content-end mb-1">
                      {formattedAmount(pool.token0Balance)}
                      <TokenIcon token={pool.token0} size="18" className="mx-1" />
                      <span className="text-gray-400"> {pool.token0.symbol}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {formattedAmount(pool.token1Balance)}
                      <TokenIcon token={pool.token1} size="18" className="mx-1" />
                      <span className="text-gray-400"> {pool.token1.symbol}</span>
                    </div>
                  </td>
                  <td width="23%" className="text-end py-3">
                    <div className="text-gray-100">{formattedAmount(pool.balance)}</div>
                    <div className="text-gray-500">
                      {pool.token0.symbol}-{pool.token1.symbol}-LP
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {this.state.pools.length === 0 &&
            <div className="text-center py-5">No liquidity positions found</div>
          }
        </Card.Body>
      </Card>

      { this.state.showsPoolView &&
        <PoolView
          pool={this.state.selectedPool}
          wallet={this.props.wallet}
          balances={this.props.balances}
          closeCallback={this.hidePoolView}
        />
      }
    </>
  )}
}

export default TopPools;
