let config = {
  backendUrl: "https://backend.dexvers.cbix.io",
  blockchains: ["testnet", "mumbai"],
  wallet: {
    networks: [
      {
        id: 80001,
        name: "Mumbai",
        native_token: "MATIC",
        factoryAddress: "0xd937Af127F56919b01Bf45dBc896f60d9Bb5415B",
        routerAddress: "0x697E1615F88F0205Cd011e6386303C8a4f77a9cC",
        externalFactoryAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
        externalRouterAddress: "0x03aFC11e5C694Da145a6fb282079d408BfBECFe2",
        stakingContractAddress: "0x218c12FB5d868b3AE1d14199B592279d027D8c88",
        rewardTokenAddress: "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0",
        bootstrapAddress: "0x2E1024DF163475351c66Bde8Ac6572cA8D50382e",
        stakingLockedContractAddress: "0x0c1C3C49BfAe22818889e42D2a061686300fAdB4",
        usdcTokenAddress: "0x30D30AEB0f272241acA538Ea1cec60eA0f7F6E6c",
        dxvsTokenAddress: "0x7795D984789AEBA37e6a1Da49f96291e60c37aa0",
        pools: [],
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"],
        wssUrl: "wss://matic.getblock.io/d5005e41-6d04-474a-ad04-22ff972d5a06/testnet/"
      },
      {
        id: 5,
        name: "Goerli",
        native_token: "ETH",
        factoryAddress: "",
        routerAddress: "",
        externalFactoryAddress: "",
        externalRouterAddress: "",
        stakingContractAddress: "",
        rewardTokenAddress: "",
        bootstrapAddress: "",
        stakingLockedContractAddress: "",
        usdcTokenAddress: "",
        dxvsTokenAddress: "",
        pools: [],
        rpcUrls: ["https://goerli.blockpi.network/v1/rpc/public"],
        blockExplorerUrls: ["https://goerli.etherscan.io"],
        wssUrl: "wss://goerli.infura.io/ws/v3/0557044374284458b584261f42a83495"
      },
      {
        id: 31338,
        name: "Dexvers TestNet",
        native_token: "ETH",
        factoryAddress: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        routerAddress: "0x0165878A594ca255338adfa4d48449f69242Eb8F",
        externalFactoryAddress: "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
        externalRouterAddress: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
        stakingContractAddress: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
        rewardTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        bootstrapAddress: "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        stakingLockedContractAddress: "0x8A791620dd6260079BF849Dc5567aDC3F2FdC318",
        usdcTokenAddress: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
        dxvsTokenAddress: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        pools: [],
        rpcUrls: ["https://localtestnet.dexvers.cbix.io"],
        blockExplorerUrls: ["https://etherscan.io"],
        wssUrl: "wss://localtestnet.dexvers.cbix.io"
      }
    ]
  }
};

export default config;
