import { Button } from 'react-bootstrap';
import React from 'react';
import { Wallet } from 'react-bootstrap-icons';
class Connect extends React.Component {

  render() {
    return (
      <>
          <div className="mt-5 text-center">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z" fill="#EFEBF9"/>
              <path d="M29.5455 26.4544L20.3473 35.6526M21.3399 28.977L16.8345 27.9128C16.3388 27.7957 16.1645 27.1803 16.5236 26.8199L20.1066 23.2369C20.3473 22.9962 20.6738 22.8583 21.016 22.8557L25.0557 22.8206M30.144 18.1981C33.1506 20.2485 35.7514 22.8492 37.8018 25.8559M27.0215 34.6599L28.0857 39.1653C28.2028 39.661 28.8182 39.8353 29.1786 39.4763L32.7616 35.8933C33.0023 35.6526 33.1402 35.326 33.1428 34.9838L33.1779 30.9442M38.5733 23.7417L39.6245 18.6834C39.9107 17.3069 38.693 16.0891 37.3165 16.3753L32.2581 17.4266C30.7684 17.7362 29.4024 18.4739 28.3277 19.5498L24.1775 23.6988C22.5941 25.2821 21.5637 27.3351 21.2385 29.5508L21.2242 29.6458C21.0186 31.06 21.4922 32.4872 22.5018 33.4981C23.5114 34.5077 24.9399 34.9812 26.3541 34.7744L26.4491 34.7601C28.6647 34.4361 30.7177 33.4044 32.301 31.8211L36.45 27.6721C37.5259 26.5975 38.2636 25.2314 38.5733 23.7417Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <h1 className="mt-4 text-center">Liquidity Boost™</h1>

          <p className="mt-4 text-center">
            Bootstrap the Zero G Pools!<br/>
            Provide liquidity by staking your Liquidity Pool Tokens<br/>
            and earn up to 200% APY on your USDC.<br/>
            Liquidity Boost is limited to the first $10,000,000.00 USD of TVL.
          </p>

          <div className="bg-gray-700 bg-opacity-35 rounded p-5 mt-3 d-none">
          <div className="bg-opacity-100">
            <div className="d-flex">
              <div className="text-center px-3 flex-fill">
                <h1 className="lh-1 text-white">$500.3k</h1>
                <span className="h6 text-light-purple">Already raised</span>
              </div>
              <div className="text-center px-3 flex-fill">
                <h1 className="lh-1 text-white">$490.7mm</h1>
                <span className="h6 text-light-purple">Left to raise</span>
              </div>
              {/* <div className="text-center px-3 flex-fill">
                <h1 className="lh-1 text-white">23d : 14h : 15m</h1>
                <span className="h6 text-light-purple">Liquidity bootstrap time remaning</span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <Button size="lg" onClick={() => this.props.actionCallback("connect-wallet")} className="w-50">
            <Wallet size={16} className="me-2" />
            Connect your wallet
          </Button>
        </div>
      </>
    );
  }
}

export default Connect;
