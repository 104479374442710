import Web3 from 'web3';
import Web3Modal from "web3modal";
import { providers } from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"
import { BigNumber } from "bignumber.js/bignumber.js";
import { fromContractDecimals, toContractDecimals, isNativeCurrency, Globals } from '../utils';
import Swapper from './Swapper.js';
import Pools from '../Stores/Pools';
import Estimator from './Estimator';
import Stakes from '../Stores/Stakes';
import Config from '../Config';
import Contracts from '../Blockchain/Contracts';
import LiquidityBoosts from '../Stores/LiquidityBoosts';

class Wallet {
  constructor(app) {
    this.crypto_networks = Config.wallet.networks;
    let providerOptions = {
      metamask: {
        id: "injected",
        name: "MetaMask",
        type: "injected",
        check: "isMetaMask"
      },
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: Config.wallet.infuraId, // required
          rpc: this.crypto_networks.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.rpcUrls[0] }), {})
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK, // required
        options: {
          appName: 'Dexverse',
          infuraId: Config.wallet.infuraId, // required
        }
      },
    };

    if (!window.ethereum) {
      providerOptions['custom-metamask'] = {
        display: {
          logo: providers.METAMASK.logo,
          name: 'Install MetaMask',
          description: 'Connect using browser wallet'
        },
        package: {},
        connector: async () => {
          window.open('https://metamask.io')
          throw new Error('MetaMask not installed');
        }
      }
    }
    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    this.changeNetwork = this.changeNetwork.bind(this);
    this.isConnected = this.isConnected.bind(this);
    this.connectProvider = this.connectProvider.bind(this);
    this.address = '';
    this.balance = 0;
    this.native_currency = '';
    this.chainId = '0x1';
    this.app = app;
    this.balances = [];
    this.routerAddress = '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff';
    this.pools = new Pools(this);
    this.stakes = new Stakes(this);
    this.liquidity_boosts = new LiquidityBoosts(this);
    this.status = undefined;
  }

  getExplorerUrl(txHash) {
    let blockExplorerUrl = this.crypto_networks.find(network => network.id === this.chainId).blockExplorerUrls[0];
    return blockExplorerUrl + '/tx/' + txHash;
  }

  priceImpact(token0_reserve, token1_reserve,  amount) {
    let constant_product = token0_reserve * token1_reserve;
    let new_token1_reserve = constant_product / (token0_reserve + amount)
    let token1_out = token1_reserve - new_token1_reserve;
    return token1_out;
  }

  async getPair(token0, token1) {
    if (isNativeCurrency(token0))
      token0 = Globals.wrapped_currency_address;
    if (isNativeCurrency(token1))
      token1 = Globals.wrapped_currency_address;

    console.log("Get pair:", token0, token1);

    let factory = Contracts.factory;
    let pairAddress = await factory.methods.getPair(token0, token1).call();
    console.log("Pair address:", pairAddress);
    return pairAddress;
  }

  async removeLiquidityByPair(pairAddress, amount, minAmount0, minAmount1) {
    let pairContract = Contracts.pair(pairAddress);
    let token0 = await pairContract.methods.token0().call();
    let token1 = await pairContract.methods.token1().call();
    console.log("Remove liquidity Token0:", token0,"Token1:", token1, "Amount", amount);
    await this.removeLiquidity(token0, token1, amount, minAmount0, minAmount1, pairAddress)
  }

  async removeLiquidity(token0, token1, amount, minAmount0, minAmount1, pairAddress) {
    let swapContract = Contracts.router;
    let weiAmount = toContractDecimals(amount);
    let weiAmount0 = toContractDecimals(minAmount0);
    let weiAmount1 = toContractDecimals(minAmount1);
    let currentDate = new Date();
    let minutesToAdd = 5;
    let futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);

    // just in case
    token0 = this.web3.utils.toChecksumAddress(token0);
    token1 = this.web3.utils.toChecksumAddress(token1);

    // token contracts and approve
    let lpContract = Contracts.erc20(pairAddress);
    await lpContract.methods.approve(this.routerAddress, weiAmount).send({
      from: this.address,
    });

    await swapContract.methods.removeLiquidity(
      token0, //tokens to remove
      token1,
      weiAmount, // liquidity amount to remove
      weiAmount0, // min token0 to receive
      weiAmount1, //min token1 to receive
      this.address, // where tokens should be sent
      +futureDate // timeout
    ).send({
      from: this.address
    },(error, hash) => {
      // should display something about the transaction here
      console.log("Error:", error, "Hash:", hash);
    }).on("error", error => console.log("Error", error));
  }


  async getAllowance(token, spender) {
    let tokenContract = Contracts.erc20(token);
    let allowance = await tokenContract.methods.allowance(this.address, spender).call();
    console.log("Allowance:",token," - ",spender, '=' , allowance);
    return allowance;
  }

  async getApproval(token, spender, amount) {
    let tokenContract = Contracts.erc20(token);
    let tokenAllowance = await this.getAllowance(token, spender);
    let bnAllowance = new BigNumber(tokenAllowance);
    let bnAmount = new BigNumber(amount);
    if (bnAllowance.comparedTo(bnAmount) === -1) {
      await tokenContract.methods.approve(spender, amount).send({
        from: this.address,
      });
    }
  }

  async estimateGaslessAmountOut(fromToken, amountIn) {
    console.log("Estimate gasless amount out:", fromToken, amountIn);
    let gasslessContract = Contracts.gassless;
    let result = await gasslessContract.methods.estimateAmounts(fromToken, amountIn).call();
    console.log("Gasless:", result);
    return result;
  }

  async doSwap(amountIn, amountOutMin, fromTokenAddress, toTokenAddress, path, routerAddress = undefined) {
    let swapper = new Swapper(this, amountIn, amountOutMin, fromTokenAddress, toTokenAddress, path, routerAddress);
    return swapper.swap();
  }

  async estimateAmountOut(amountIn, fromToken, toToken) {
    return Estimator.dexvers(this).set(amountIn, 0, fromToken, toToken).estimateAmountOut()
      .catch((e) => {
        console.log("estimateAmountOut error with dexvers defi: ", e)
        return Estimator.external(this).set(amountIn, 0, fromToken, toToken).estimateAmountOut()
          .catch((e) => {
            console.log("estimateAmountOut error with external defi: ", e);
          })
      })
  }


  async estimateAmountIn(amountOut, fromToken, toToken) {
    return Estimator.dexvers(this).set(0, amountOut, fromToken, toToken).estimateAmountIn()
      .catch(() => {
        return Estimator.external(this).set(0, amountOut, fromToken, toToken).estimateAmountIn();
      })
  }

  async getAmountOut(amount, from, to, maxSlippage = 0.5) {
    if (isNativeCurrency(from)) { from = Globals.wrapped_currency_address; }
    if (isNativeCurrency(to))   { to = Globals.wrapped_currency_address; }
    let amountIn = toContractDecimals(amount, 18);
    // just in case
    let from1 = this.web3.utils.toChecksumAddress(from);
    let to1 = this.web3.utils.toChecksumAddress(to);
    if (
        (from === Globals.wrapped_currency_address || from === Globals.native_currency_address)
        &&
        (to === Globals.wrapped_currency_address || to === Globals.native_currency_address)
      ) {
      let amountOut = amount;
      this.app.setState({
        exchangeAmountOut: amountOut,
        exchangeAfterSlippage: this.computeAfterSlippage(amountOut)
      });
      return;
    }

    let swapContract = Contracts.router;
     try {
      let amountsOut = await swapContract.methods.getAmountsOut(amountIn,[from1,to1]).call();
      let amountOut = fromContractDecimals(amountsOut[1], this.token(to).decimals);
      this.app.setState({
        exchangeAmountOut: amountOut,
        exchangeAfterSlippage: this.computeAfterSlippage(amountOut)
      });
      this.computePriceImpact(amount, amountOut, from1, to1);
    } catch (error) {
      console.log(error);
    }
    return
  }

  async computePriceImpact(amountIn, amountOut, from, to) {
    let factoryContract = Contracts.factory;
    let pair = await factoryContract.methods.getPair(from, to).call();
    let pairContract = Contracts.pair(pair);
    let pair_token0 = await pairContract.methods.token0().call();
    let reserves = await pairContract.methods.getReserves().call();
    let reserve_token0 = new BigNumber(reserves.reserve0);
    let reserve_token1 = new BigNumber(reserves.reserve1);
    if (pair_token0 !== from) {
      reserve_token0 = new BigNumber(reserves.reserve1);
      reserve_token1 = new BigNumber(reserves.reserve0);
    }
    let currentPrice = reserve_token0.dividedBy(reserve_token1);
    let newPrice = reserve_token0.plus(amountIn).dividedBy(reserve_token1.minus(amountOut));
    let priceImpact = currentPrice.minus(newPrice).absoluteValue().dividedBy(currentPrice).multipliedBy(100);
    this.app.setState({
      exchangePriceImpact: priceImpact.toFixed(),
    });
    return priceImpact;
  }

  computeAfterSlippage(inAmount, slippage=0.5) {
    let a = new BigNumber(inAmount);
    let res = a.multipliedBy((100-slippage)/100);
    return res.toFixed();
  }

  isConnected() {
    return (this.address !== '');
  }

  async subscribetoProviderevents() {
    if (!this.provider.on) {
      return;
    }
    // this.provider.on("disconnect", () => this.resetApp());
    this.provider.on("accountsChanged", async (accounts) => {
      this.address = accounts[0];
      this.app.setState({
        address: accounts[0],
      });
      this.app.loadTokens();
    });

    this.provider.on("chainChanged", async (chainId) => {
      console.log('Chain changed:',chainId);
      const networkId = await this.web3.eth.net.getId();
      this.setupChain(networkId);
      this.getDexPairs(networkId);
      this.getAllPairs();
    });
  }


  rewardToken() {
    return this.token(this.rewardTokenAddress);
  }

  usdcToken() {
    return this.token(this.usdcTokenAddress);
  }

  dxvsToken() {
    return this.token(this.dxvsTokenAddress);
  }

  nativeToken() {
    return this.token(Globals.native_currency_address);
  }

  wrappedToken() {
    return this.token(Globals.wrapped_currency_address);
  }

  token(address) {
    return this.app.state.tokens.find((x) => x.address.toLowerCase() === address.toLowerCase());
  }

  addTokenToWallet(token) {
    this.provider
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: 18
          }
        }
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  connect(ask = false) {
    // if there's no provider and we're not asking, just return disconnected
    if (!this.web3Modal.cachedProvider && !ask) {
      this.app.setState({ walletStatus: 'disconnected' });
      return;
    }
    this.web3Modal.connect()
    .then((provider) => {
      this.provider = provider;
      this.web3 = new Web3(this.provider);
      Globals.web3 = this.web3;
      Globals.wallet = this;
      this.subscribeToProviderEvents();
      this.setup(ask);
    })
    .catch((error) => {
      this.app.setState({ walletStatus: 'disconnected' });
    })
  }

  async setup(ask = false) {
    let blockchain = this.crypto_networks.find((x) => x.id === this.provider.chainId || x.id === parseInt(this.provider.chainId, 16));
    if (blockchain) {
      this.configure(blockchain);
      this.prepare();
      return;
    }
    if (!ask) {
      this.app.setState({ walletStatus: 'unknownChain' });
      return;
    }
    this.changeNetwork(this.crypto_networks[0]);
  }

  getAddress() {
    let addr = this.provider.selectedAddress;
    if (addr === undefined) {
      addr = this.provider.accounts[0];
    }
    return addr;
  }

  async configure(blockchain) {
    this.chainId = blockchain.id;
    this.address = this.getAddress();
    this.native_currency = blockchain.native_token;
    this.factoryAddress = blockchain.factoryAddress;
    this.routerAddress = blockchain.routerAddress;
    this.externalFactoryAddress = blockchain.externalFactoryAddress;
    this.externalRouterAddress = blockchain.externalRouterAddress;
    this.stakingContractAddress = blockchain.stakingContractAddress;
    this.stakingLockedContractAddress = blockchain.stakingLockedContractAddress;
    this.rewardTokenAddress = blockchain.rewardTokenAddress;
    this.usdcTokenAddress = blockchain.usdcTokenAddress;
    this.dxvsTokenAddress = blockchain.dxvsTokenAddress;
    this.bootstrapAddress = blockchain.bootstrapAddress;
    this.gasslessAddress = blockchain.gasslessAddress;
    this.defaultChartsTokenAddress = blockchain.defaultChartsTokenAddress || blockchain.dxvsTokenAddress;

    Globals.blockchain = blockchain;
    Globals.address = this.address;
    Globals.currentBlockchain = Globals.blockchains.find((x) => x.id === blockchain.id);
    Globals.currentBlockchain.web3 = this.web3;

    let router = Contracts.router;
    Globals.wrapped_currency_address = await router.methods.WETH().call();
    Globals.native_currency_address = blockchain.nativeCurrencyAddress;
    // for wallet connect. Initially the chainId is 0x1, so we need to switch to the correct chain
    if (typeof this.provider.updateRpcUrl === "function") {
      this.provider.updateRpcUrl(this.chainId);
    }
  }

  prepare() {
    this.app.loadTokens(this.chainId, this.address)
      .then(() => {
        this.app.setState({
          chainId: this.chainId,
          native_currency: this.native_currency,
          address: this.address,
          walletStatus: 'connected'
        });
      });
    Globals.balancePoller.startPolling();
    this.getBalance();
  }

  async subscribeToProviderEvents() {
    this.provider.on("disconnect", () => { this.reset(); });

    this.provider.on("accountsChanged", (accounts) => { this.reset(); });

    this.provider.on("chainChanged", (chainId) => { this.reset(); });
  }

  reset() {
    window.location.reload();
  }

  async disconnect() {
    await this.web3Modal.clearCachedProvider();
    window.localStorage.removeItem("walletconnect");
    window.location.reload();
  }


  changeNetwork(blockchain) {
    if (typeof blockchain === 'number')
      blockchain = this.crypto_networks.find((x) => x.id === blockchain);
    if (this.provider.wc !== undefined) {
      this.provider.updateRpcUrl(blockchain.id);
      this.provider.chainId = blockchain.id;
      return this.setup();
    }

    this.provider.request({method: "wallet_switchEthereumChain", params: [{chainId: '0x'+blockchain.id.toString(16)}]})
      .then(() => {
        this.setup();
      })
      .catch((error) => {
        this.addNetwork(blockchain);
      });
  }

  addNetwork(blockchain) {
    this.provider
      .request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x" + blockchain.id.toString(16),
            nativeCurrency: {
              name: blockchain.native_token,
              symbol: blockchain.native_token,
              decimals: 18
            },
            chainName: blockchain.name,
            rpcUrls: blockchain.rpcUrls,
            blockExplorerUrls: blockchain.blockExplorerUrls
          }
        ]
      })
      .then((result) => {
        this.setup();
      });
  }

  // *** end wallet handling ***





  async connectProvider() {
    try {
      this.provider = await this.web3Modal.connect();
    } catch (error) {
      console.log("Connect error",error);
      return;
    }
    this.web3 = new Web3(this.provider);
    this.subscribetoProviderevents();
    await this.getChainId();
    await this.getAccounts();
    // this.getAllPairs();
    let router = Contracts.router;
    Globals.wrapped_currency_address = await router.methods.WETH().call();
    console.log("Wrapped currency address", Globals.wrapped_currency_address);
  }

  tryConnectProvider() {
    if (this.web3Modal.cachedProvider) {
      this.connectProvider();
    }
  }

  async getAccounts() {
    let data = await this.web3.eth.getAccounts().then((data) => {
        console.log("Accounts: ", data);
        this.address = data[0];
        this.app.setState({
          address: this.address
        });
        this.getBalance();
        return data;
      });
    return data;
  }

  async getBalance() {
    return this.web3.eth.getBalance(this.address).then((data) => {
      this.balance = this.web3.utils.fromWei(data)
      if (this.app.state.balance !== this.balance)
        this.app.setState({ balance: this.balance });
      return this.balance;
    });
  }

  async getPairInfo(address) {
    const contract = Contracts.pair(address);
    return Promise.all([
      contract.methods.token0().call(),
      contract.methods.token1().call(),
      contract.methods.getReserves().call(),
      contract.methods.balanceOf(this.address).call(),
      contract.methods.totalSupply().call()
    ]).then((data) => {
      const [token0, token1, { reserve0, reserve1 }, balance, supply] = data;
      return {
        address: address,
        token0: this.token(token0),
        token1: this.token(token1),
        reserve0: reserve0,
        reserve1: reserve1,
        balance: balance,
        supply: supply
      }
    });
  }

  async getChainId() {
    this.web3.eth.getChainId().then((chainId) => {
      this.setupChain(chainId);
    });
  }

  async getTokenBalance(address) {
    let token = this.token(address);
    if (isNativeCurrency(address)) {
      return {
        address: address,
        balance: this.balance,
        name: this.native_currency,
        symbol: this.native_currency,
      };
    }
    if (this.web3 === undefined || token === undefined) {
      if (token === undefined) {
        return {
          address: '0x0000000000000000000000000000000000000000',
          balance: 0,
          name: 'Select Token',
          symbol: '',
        };
      }
      return {
        address: token.address,
        balance: token.balance,
        name: token.name,
        symbol: token.symbol
      };
    }

    let contract = Contracts.erc20(address);
    let balance = await contract.methods.balanceOf(this.address).call();
    console.log("Balance from contract: ", balance);
    token.balance = fromContractDecimals(balance, token.decimals);
    console.log("Balance:", token);
    return token;
  }

  async getDexPairs(networkId) {
    let netIndex = this.crypto_networks.findIndex((x) => x.id===networkId);
    let cryptoNetwork = this.crypto_networks[netIndex];
    // we got this already
    if (cryptoNetwork.pools.length > 0) {
      return
    }

    let contract = Contracts.factory;
    let numberOfPairs = await contract.methods.allPairsLength().call();
    for(let i =  0; i < numberOfPairs; i++) {
      let pair = await contract.methods.allPairs(i).call();
      let pairContract = Contracts.pair(pair);
      let token0 = await pairContract.methods.token0().call();
      let token1 = await  pairContract.methods.token1().call();
      let sPair = {
        address: pair,
        token0: token0,
        token1: token1
      };
      let pools = cryptoNetwork.pools;
      cryptoNetwork.pools = [...pools, sPair];
      if (i > 5) {
        break;
      }
    }
    this.crypto_networks[netIndex] = cryptoNetwork;
  }

  async getAllPairs() {
    let pair = '';
    let contract = Contracts.factory;
    let numberOfPairs = await contract.methods.allPairsLength().call();
    for(let i =  0; i < numberOfPairs; i++) {
      pair = await contract.methods.allPairs(i).call();
      let pairContract = Contracts.pair(pair);
      let token0 = await pairContract.methods.token0().call();
      let token1 = await  pairContract.methods.token1().call();
      this.getTokenBalance(token0).then((t) => {
        if (this.balances.filter((x) => x.address === t.address).length > 0) {
          return;
        }
        this.balances = [...this.balances, t]
        console.log(this.balances);
        return;
      });
      this.getTokenBalance(token1).then((t) => {
        if (this.balances.filter((x) => x.address === t.address).length > 0) {
          return;
        }
        this.balances = [...this.balances, t]
        console.log(this.balances);
        return;
      });
      if (i > 5) {
        break;
      }
    }
  }

  async getTokenOwnerNonce(token_address, address) {
    let contract = Contracts.erc20(token_address);
    return await contract.methods.nonces(address).call();
  }

  // EIP712 - permit
  async EipSign(token, token_EIP712_version, owner, spender, value) {
    console.log("Verifying:", token.address, token.name, token_EIP712_version, owner, spender, value);
    let domain;
    // USD in Polygon
    if (token.address === "0x2791bca1f2de4661ed88a30c99a7a9449aa84174") {
      domain = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "verifyingContract", type: "address" },
        { name: "salt", type: "bytes32" }
      ];
    }
    else {
      domain = [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ];
    }
    const Permit = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ]
    let domainData
    if (token.address === "0x2791bca1f2de4661ed88a30c99a7a9449aa84174") {
      domainData = {
        name: token.name,
        version: token_EIP712_version,
        verifyingContract: token.address,
        salt: this.web3.utils.padLeft(this.web3.utils.numberToHex(this.chainId), 64)
      };
    } else {
      domainData = {
        name: token.name,
        version: token_EIP712_version,
        chainId: this.chainId,
        verifyingContract: token.address,
      };
    }
    const deadline = Math.round(Date.now()/1000)+6000*20;
    var message = {
      owner: owner,
      spender: spender,
      value: toContractDecimals(value, token.decimals),
      nonce: await this.getTokenOwnerNonce(token.address, this.address),
      deadline: deadline
    };
    console.log("MESSAGE",message);
    const data = JSON.stringify({
      types: {
          EIP712Domain: domain,
          Permit: Permit,
      },
      domain: domainData,
      primaryType: "Permit",
      message: message
    });
    console.log("Permit data:", data);
    return this.provider.request({
      method: "eth_signTypedData_v4",
      params: [this.address, data],
      from: this.address
    }).then((result) => {
      console.log(result);
      const signature = result.substring(2);
      const r = "0x" + signature.substring(0, 64);
      const s = "0x" + signature.substring(64, 128);
      const v = parseInt(signature.substring(128, 130), 16);
      console.log("r: " + r);
      console.log("s: " + s);
      console.log("v: " + v);
      return [r, s, v, message.value, deadline];
    });
  }

}

export default Wallet;
