import React from 'react';
import GraphData from '../Apis/GraphData';
import PeriodButton from './PeriodButtons';

class GenericSinceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      since: new Date(new Date() - 7 * 24 * 3600 * 1000)
    };
    this.api = new GraphData();
    this.setSince = this.setSince.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokenAddress !== this.props.tokenAddress) {
      this.getData();
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({
      data: []
    });
  }

  graphName() {
    return 'Generic';
  }

  setSince(buton) {
    console.log(buton?.target?.id);
    if (buton.target.id === '1w') {
      this.setState({
        since: new Date(new Date() - 7 * 24 * 3600 * 1000)
      }, this.getData);
    };
    if (buton.target.id === '1m') {
      this.setState({
        since: new Date(new Date() - 30 * 24 * 3600 * 1000)
      }, this.getData);
    };
    if (buton.target.id === '1y') {
      this.setState({
        since: new Date(new Date() - 365 * 24 * 3600 * 1000)
      }, this.getData);
    };
  }

  chartComponent() {
    return(
      <>
      </>
    );
  }

  render() {
    return (
      <div className='p-3'>
        <div className="d-flex mb-2">
          {this.graphName()}
          <div className="ms-auto">
            <PeriodButton sinceCallback={this.setSince}/>
          </div>
        </div>
        <div>
          {this.chartComponent()}
        </div>
      </div>
    );
  }
}

export default GenericSinceChart;
