import React from "react";
import { Modal } from "react-bootstrap";
import { Globals } from "../utils";
import BootstrapStakeBody from "./TransactionModal/Body/BootstrapStakeBody";
import BodyDefault from "./TransactionModal/Body/Default";
import SwapBody from "./TransactionModal/Body/SwapBody";
import FailureDefault from "./TransactionModal/Failure/Default";
import PendingDefault from "./TransactionModal/Pending/Default";
import BootstrapStakeSuccess from "./TransactionModal/Success/BootstrapStakeSuccess";
import SuccessDefault from "./TransactionModal/Success/Default";
import GasslessSuccess from "./TransactionModal/Success/GasslessSuccess";
import SwapSuccess from "./TransactionModal/Success/SwapSuccess";
import XSwapSuccess from "./TransactionModal/Success/XSwapSuccess";
import TransactionStatus from "./TransactionStatus";

class TransactionModal extends React.Component {
  static transactionStatusMap = {
    pending: "pending",
    executing: "pending",
    success: "success",
    failed: "failure"
  };

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      transaction: props.transaction,
      error: props.transaction.error,
      status: this.computeStatus(props.transaction)
    };
    this.onClose = this.onClose.bind(this);
    this.transactionStatusChanged = this.transactionStatusChanged.bind(this);
    props.transaction.on("change", this.transactionStatusChanged);
  }

  componentWillUnmount() {
    this.props.transaction.off("change", this.transactionStatusChanged);
  }

  computeStatus(transaction) {
    return TransactionModal.transactionStatusMap[transaction.status || 'pending'];
  }

  transactionStatusChanged(transaction, status) {
    this.setState({
      transaction: transaction,
      error: transaction.error,
      status: this.computeStatus(transaction)
    });
  }

  onClose() {
    this.setState({ show: false });
    if (this.props.onClose)
      this.props.onClose();
  }

  getExplorerUrl() {
    let txHash = this.state.transaction.calls.at(-1).txHash;
    return Globals.wallet.getExplorerUrl(txHash);
  }

  transactionStatusBackgroundClass() {
    let status = this.state.status;
    if (status === "success")
      return "bg-success bg-opacity-50";
    if (status === "failure")
      return "bg-danger bg-opacity-50";
    return "";
  }

  render() {
    const componentsMap = {
      pending: {
        default: PendingDefault
      },
      success: {
        default: SuccessDefault,
        "bootstrap.stake": BootstrapStakeSuccess,
        "router.swapExactTokensForTokens": SwapSuccess,
        "router.swapExactETHForTokens": SwapSuccess,
        "router.swapExactTokensForETH": SwapSuccess,
        "WETH.withdraw": SwapSuccess,
        "WETH.deposit": SwapSuccess,
        "Gassless.exchange": GasslessSuccess,
        "CrosschainWatch.call": XSwapSuccess
      },
      failure: {
        default: FailureDefault
      },
      body: {
        "bootstrap.stake": BootstrapStakeBody,
        "router.swapExactTokensForTokens": SwapBody,
        "router.swapExactETHForTokens": SwapBody,
        "router.swapExactTokensForETH": SwapBody,
        "WETH.withdraw": SwapBody,
        "WETH.deposit": SwapBody,
        default: BodyDefault
      }
    }

    let contract = this.props.transaction.calls.at(-1).method;
    let componentKey = `${contract.meta.contractType}.${contract.meta.methodName}`;

    const SuccessComponent = componentsMap.success[componentKey] || componentsMap.success.default;
    const FailureComponent = componentsMap.failure[componentKey] || componentsMap.failure.default;
    const PendingComponent = componentsMap.pending[componentKey] || componentsMap.pending.default;
    const BodyComponent = componentsMap.body[componentKey] || componentsMap.body.default;

    return (
      <>
        <Modal centered show={this.state.show} onHide={this.onClose}>
          <Modal.Header className={this.transactionStatusBackgroundClass()} closeButton={true}>Transaction {this.state.status === 'pending' && "In Progress"}</Modal.Header>
          <Modal.Body>
            {this.state.status === 'pending' && <PendingComponent closeModal={this.onClose} transaction={this.props.transaction} />}
            {this.state.status === 'success' && <SuccessComponent closeModal={this.onClose} transaction={this.props.transaction} />}
            {this.state.status === 'failure' && <FailureComponent closeModal={this.onClose} transaction={this.props.transaction} />}
            <hr />
            {<BodyComponent transaction={this.props.transaction} />}
            <TransactionStatus transaction={this.props.transaction} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TransactionModal;
