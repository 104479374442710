import React from 'react';
import { Container, Row, Col, Card, Spinner, Table } from 'react-bootstrap';
import GraphData from '../Apis/GraphData';
import If from '../Components/If';
import { formattedAmount } from '../utils';
import TokenIcon from '../Components/TokenIcon';
import Unless from '../Components/Unless';

class LiquidityPoolList extends React.Component {
  constructor(props)  {
    super(props);
    this.state = {
      data: [],
      loading: true
    };
    this.api = new GraphData();
  }

  getData() {
    this.api.liquidityPoolData(this.props.chainId, this.props.tokenAddress).then((data) => {
      this.setState({
        data: data,
        loading: false
      });
    }).catch(() => { this.setState({data: []})});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokenAddress !== this.props.tokenAddress) {
      this.setState({
        loading: true
      })
      this.getData();
    }
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return(
      <Card>
        <Card.Header>
          <Card.Title>Liquidity Pools</Card.Title>
        </Card.Header>
        <Card.Body className="p-0">
          <If condition={this.state.loading}>
            <div className="text-center p-3">
              <Spinner
                animation="border"
                variant="secondary"
                style={{ width: "3rem", height: "3rem" }} />
            </div>
          </If>
          <Unless condition={this.state.loading}>
            <Table className="small mb-0" variant='dark'>
              <thead>
                <tr>
                  <th className='fw-normal'>Liquidity Pairing</th>
                  <th className='fw-normal'>Investment</th>
                  <th className='fw-normal'>Exchange</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((pool, index) =>
                  <tr className="text-white align-middle" key={index.toString()} role="button" onClick={() => this.props.setLPCallback(pool.address)}>
                    <td width="31%">
                      <div className="d-flex align-items-center">
                        <div className="d-inline-block pair-icons">
                          <TokenIcon token={pool.token0} size="24" />
                          <TokenIcon token={pool.token1} size="24" />
                        </div>
                        <div className="d-inline-block ms-2">
                          {pool.token0.symbol}-{pool.token1.symbol}
                        </div>
                      </div>
                    </td>
                    <td width="23%" className="text-nowrap text-end">
                      <div className="d-flex align-items-center justify-content-end mb-1">
                        {formattedAmount(pool.balance0)}
                        <TokenIcon token={pool.token0} size="18" className="mx-1" />
                        <span className="text-gray-400"> {pool.token0.symbol}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {formattedAmount(pool.balance1)}
                        <TokenIcon token={pool.token1} size="18" className="mx-1" />
                        <span className="text-gray-400"> {pool.token1.symbol}</span>
                      </div>
                    </td>
                    <td width="23%" className="text-end py-3">
                      <div className="text-gray-100">{pool.defi.full_name}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {this.state.data.length === 0 &&
              <div className="text-center py-5">
                No liquidity positions
              </div>
            }
          </Unless>
          <Container className='d-none'>
            <Row className='bg-secondary fw-normal'>
              <Col className='col-3'>Tokens</Col>
              <Col className='col-3'>Exchange</Col>
              <Col className='col-3 text-end'>Balance0</Col>
              <Col className='col-3 text-end'>Balance1</Col>
            </Row>
            <If condition={!this.state.loading}>
              {this.state.data.map((item, index) =>
                <Row className='fw-normal' key={index} onClick={() => this.props.setLPCallback(item.address)} style={{cursor: 'pointer'}}>
                  <Col className="col-3">
                    <div className="d-inline-block">
                      <TokenIcon token={item.token0} size="24" />
                    </div>
                    <div className="d-inline-block">
                      <TokenIcon token={item.token1} size="24" />
                    </div>
                    &nbsp;{item.token0.symbol} - {item.token1.symbol}
                  </Col>
                  <Col className="col-3">{item.defi.full_name}</Col>
                  <Col className="col-3 text-end">{formattedAmount(item.balance0)}</Col>
                  <Col className="col-3 text-end">{formattedAmount(item.balance1)}</Col>
                </Row>
              )}
            </If>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default LiquidityPoolList;
