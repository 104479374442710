import React from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { formattedAmount } from '../utils';
import PriceImpact from './PriceImpact';

class SwapFees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`mt-3 p-3 rounded-3 bg-gray-800`}>
        <div className="d-flex">
          <div className="small text-gray-400 me-2">Output</div>
          <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
        </div>
        <div className="d-flex mt-2 small">
          <div className="flex-fill">Expected Output <QuestionCircle title="Expected output"/></div>
          <div className="ms-3 text-end">
            {formattedAmount(this.props.fees?.output?.amount)} {this.props.fees?.output?.token?.symbol}
          </div>
        </div>
        <div className="d-flex mt-2 small">
          <div className="flex-fill">Minimum after slippage <QuestionCircle/></div>
          <div className="ms-3 text-end">
            {formattedAmount(this.props.fees?.outputAfterSlippage?.amount)} {this.props.fees?.outputAfterSlippage?.token?.symbol}
          </div>
        </div>
        <PriceImpact value={this.props.fees.impact}/>

        <div className="d-flex mt-3">
          <div className="small text-gray-400 me-2">Fees</div>
          <hr className="m-0 bg-gray-500 flex-grow-1 my-auto" />
        </div>
        <div className="d-flex mt-2 small">
          <div className="flex-fill">Transaction fee <QuestionCircle/> </div>
          <div className="ms-3 text-end">
            {formattedAmount(this.props.fees?.transaction?.amount)} {this.props.fees?.transaction?.token?.symbol}
            {
              this.props.fees?.transaction2 !== undefined &&
              <>
                <br/>
                {formattedAmount(this.props.fees?.transaction2?.amount)} {this.props.fees?.transaction2?.token?.symbol}
              </>
            }
            {
              this.props.fees?.transaction3 !== undefined &&
              <>
                <br/>
                {formattedAmount(this.props.fees?.transaction3?.amount)} {this.props.fees?.transaction3?.token?.symbol}
              </>
            }
          </div>
        </div>
        <div className="d-flex mt-2 small">
          <div className="flex-fill">Network fee <QuestionCircle/> </div>
          <div className="ms-3 text-end">
            {formattedAmount(this.props.fees?.network?.amount)} {this.props.fees?.network?.token?.symbol}
          </div>
        </div>
      </div>
    );
  }
}

export default SwapFees;
