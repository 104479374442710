import { Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";

const XSwapSuccess = function ({ transaction }) {
  let sourceChainCall = transaction.calls.find((call) => call.method.meta.contractType === "crosschain");
  let crosschainWatchCall = transaction.calls.find((call) => call.method.meta.contractType === "CrosschainWatch");
  let sourceBlockchain = crosschainWatchCall.result.sourceBlockchain;
  let sourceTransactionHash = sourceChainCall.txHash;
  let sourceTransactionUrl = sourceBlockchain.explorerUrl(sourceTransactionHash);

  let destinationBlockchain = crosschainWatchCall.result.destinationBlockchain;
  let destinationTransactionHash = crosschainWatchCall.result.data.destinationTransfers.at(0).executedTransactionHash;
  let destinationTransactionUrl = destinationBlockchain.explorerUrl(destinationTransactionHash);

  return (
    <div>
      <div className="d-flex py-3">
        <div className="m-auto position-relative">
          <CheckCircleFill size="40" className="rounded-circle overflow-hidden text-success-light bg-success" />
        </div>
      </div>
      <div className="text-center mb-4">
        <h5 className="text-center">Transaction completed!</h5>
        <Button href={sourceTransactionUrl} target="_blank" variant="primary" size="sm" className="mt-3 w-50 mx-3">
          View on {sourceBlockchain.name}
        </Button>
        <Button href={destinationTransactionUrl} target="_blank" variant="primary" size="sm" className="mt-3 w-50 mx-3">
          View on {destinationBlockchain.name}
        </Button>
      </div>
    </div>
  );
};

export default XSwapSuccess;
