import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import ChainChangeNetworkLabel from './ChainChangeNetworkLabel';

class BlockchainSelector extends React.Component {
  static defaultProps = {
    blockchains: []
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.blockchainChanged = this.blockchainChanged.bind(this);
  }

  blockchainChanged(chainId) {
    chainId = parseInt(chainId);
    if (isNaN(chainId) || this.props.selected.id === chainId)
      return;
    let blockchain = this.props.blockchains.find((blockchain) => blockchain.id === chainId);
    if (this.props.onSelect)
      this.props.onSelect(blockchain);
  }

  render() {
    return (
      <>
        <div className={`${this.props.className}`}>
          <Dropdown className='w-100' onSelect={this.blockchainChanged}>
            <Dropdown.Toggle className={`d-flex align-items-center w-100 bg-gray-700 rounded-3 py-2`} variant="dark">
              {this.props.selected !== undefined &&
               <ChainChangeNetworkLabel className="me-auto my-1" network={this.props.selected} expanded={true} />
              }
            </Dropdown.Toggle>
            <Dropdown.Menu  variant='dark' className="w-100">
            {this.props.blockchains.map((network) =>
              <Dropdown.Item key={network.id} className="nohover" eventKey={network.id} data-chain-id={network.id} active={false}>
                {this.props.selected?.id === network.id &&
                  <Check className='position-absolute ms-2 mt-1' style={{ left: 0 }} />
                }
                <ChainChangeNetworkLabel network={network} key={network.id} expanded={true} />
              </Dropdown.Item>
            )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }
}
export default BlockchainSelector;
