import DexversApi from '../DexversApi';

class GraphData extends DexversApi {
  async load(chainId, address) {
    return fetch(this.url(chainId, address)).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response.statusText);
      }
    });
  }

  defaultSince(since) {
    if (since === undefined) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      since = d.toISOString();
    }
    return since;
  }

  priceUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("address", address);
    searchParams.append("chainId", chainId);
    searchParams.append("since", since);
    return `${this.baseUrl()}/token_quotes?${searchParams.toString()}`;
  }

  volumeUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("address", address);
    searchParams.append("chainId", chainId);
    searchParams.append("since", since);
    return `${this.baseUrl()}/token_volumes?${searchParams.toString()}`;
  }

  tvlUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("since", since);
    return `${this.baseUrl()}/tvl/${chainId}/${address}?${searchParams.toString()}`;
  }

  transferUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("address", address);
    searchParams.append("chainId", chainId);
    return `${this.baseUrl()}/token_transfers?${searchParams.toString()}`;
  }

  liquidityPoolUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("since", since);
    return `${this.baseUrl()}/liquidity_pools/${chainId}/${address}?${searchParams.toString()}`;
  }

  liquidityPoolInfoUrl(address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("since", since);
    return `${this.baseUrl()}/liquidity_pools/${address}?${searchParams.toString()}`;
  }

  tokenInfoUrl(_chainID, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("since", since);
    return `${this.baseUrl()}/tokens/${address}?${searchParams.toString()}`;
  }


  transferUrlByWallet(chainId, address, _since) {
    const searchParams = new URLSearchParams();
    return `${this.baseUrl()}/token_transfers/wallet/${chainId}/${address}?${searchParams.toString()}`;
  }


  whaleUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("chainId", chainId);
    return `${this.baseUrl()}/whale_wallets/${address}?${searchParams.toString()}`;
  }

  tokenCountUrl(chainId, address, since) {
    const searchParams = new URLSearchParams();
    searchParams.append("chainId", chainId);
    searchParams.append("address", address);
    searchParams.append("since", since);
    return `${this.baseUrl()}/token_wallet_counts?${searchParams.toString()}`;
  }


  async priceData(chainId, address, since) {
    return fetch(this.priceUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async volumeData(chainId, address, since) {
    if (since === undefined) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      since = d.toISOString();
    }
    return fetch(this.volumeUrl(chainId, address, since)).then((data) => data.json());
  }

  async walletCountData(chainId, address, since) {
    return fetch(this.tokenCountUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async tokenInfoData(chainId, address, since) {
    return fetch(this.tokenInfoUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async transferData(chainId, address, since) {
    return fetch(this.transferUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async transferDataByWallet(chainId, address, since) {
    return fetch(this.transferUrlByWallet(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }


  async whaleData(chainId, address, since) {
    return fetch(this.whaleUrl(chainId, address, since)).then((data) => data.json());
  }

  async tvlData(chainId,address, since) {
    return fetch(this.tvlUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async liquidityPoolData(chainId,address, since) {
    return fetch(this.liquidityPoolUrl(chainId, address, this.defaultSince(since))).then((data) => data.json());
  }

  async liquidityPoolInfoData(address, since) {
    return fetch(this.liquidityPoolInfoUrl(address, this.defaultSince(since))).then((data) => data.json());
  }


  async priceVolumeData(chainId, address, since) {
    since = this.defaultSince(since);
    let [priceD, volumeD] = await Promise.all([
      this.priceData(chainId, address, since),
      this.volumeData(chainId, address, since)
    ]);
    let finalData = priceD;
    volumeD?.forEach((e) => {
      const foundIndex = finalData.findIndex((f) => f.date === e.date);
      if (foundIndex === -1) {
        finalData.push(e);
      } else {
        finalData[foundIndex].volume = e.volume;
      }
    });
    return finalData;
  }

}

export default GraphData;
